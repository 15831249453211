<template>
    <div class="admin-users-view">   

        <ul class="admin-users-view__content-users">
            <AdminUser 
                v-for="el in filteredUsersData" 
                :name="el.user.name" 
                :imgSrc="el.user.photo1" 
                :phone="el.phone" 
                :mail="el.user.email" 
                :matches="el.matchCount"
                :flag="el.flag"
                :id="el.user._id"
            />
        </ul>
        <router-link class="admin-users-view__btn admin-users-view__btn--red" :to="`/admin/premium-check/${Number($route.params.page) - 1}`">Poprzednia strona</router-link>
        <router-link class="admin-users-view__btn" :to="`/admin/premium-check/${Number($route.params.page) + 1}`">Następna strona</router-link>
         
    </div>
</template>
<script>
import AdminUser from '@/components/atoms/AdminUser.vue';

    export default { 
        data() {
            return {
                inputValue: '',
            }
        },
        props: {
            users: Array,
        },
        components: {
            AdminUser
        },
       computed: {
            filteredUsersData() {
                if (!this.inputValue || !this.users) { return this.users; }
                const searchQuery = this.inputValue;
                return this.users.filter((user) =>
                    user.user.name && user.user.name.includes(searchQuery)
                );
            },
        },
        methods: {
            usersBar() {
                this.$refs.sidebarNav.style.top = '0px'
            },
            packagesBar() {
                this.$refs.sidebarNav.style.top = '51px'
            },
            logout(){
                localStorage.clear();
                this.$router.push('/');
                this.$store.commit('removeUser');
                // location.reload();
            },
        },
    }
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";

    .admin-users-view {
        &__header {
            max-width: 1597px;
            height: 104px;
            background-color: $white;
            padding-left: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__header-logo {
            display: flex;
            align-items: center;
            svg {
                margin-right: 14px;
            }
            p {
                font-family: 'NunitoExtraBold';
                font-weight: 800;
                font-size: 2.0rem;
                line-height: 3.7rem;
                color: $black;
            }
        }
        &__btn{
            background-color: #0fb70d;
            color: #fff;
            text-decoration: none;
            padding: 15px 20px;
            border-radius: 5px;
            margin-top: 30px;
            display: inline-block;
            margin-bottom: 20px;
            &--red{
                background-color: #F2545B;
                margin-left: 95px;
                margin-right: 10px;
            }
        }
        &__header-notification {
            cursor: pointer;
            width: 53px;
            height: 53px;
            background: $gray;
            border-radius: 15px;
            display: grid;
            place-items: center;
            position: relative;
            transition: .3s;
            &:hover {
                svg {
                    animation: myAnim 2s ease 0s 1 normal forwards;
                }
            }
        }
        &__header-notification-circle {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 12px;
            height: 12px;
            background-color: #F2545B;
            border-radius: 50%;
        }
        &__wrapper {
            display: flex;
        }
        &__sidebar {
            max-width: 303px;
            width: 100%;
            height: calc(100vh - 104px);
            background: $gray;
            border-radius: 0 15px 15px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-top: 54px;
            padding-bottom: 150px;
        }
        &__sidebar-pages {
            position: relative;
            display: flex;
            flex-direction: column;
            list-style: none;
            li {
                margin-bottom: 13px;
                a {
                    text-decoration: none;
                    font-family: 'NunitoMedium';
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2.5rem;
                    color: $black1;
                    height: 38px;
                    display: flex;
                    align-items: center;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                svg {
                    margin-right: 13px;
                }
            }
        }
        &__sidebar-nav {
            position: absolute;
            left: -19px;
            top: 0px;
            width: 4px;
            height: 38px;
            background-color: #F2545B;
            box-shadow: -3px 3px 6px rgba(242, 84, 91, 0.35);
            border-radius: 500px;
            transition: .3s;
        }
        &__sidebar-logout {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__sidebar-line {
            margin-bottom: 34px !important;
            max-width: 239px;
            margin: 0 auto;
            width: 100%;
            height: 1px;
            background-color: $bege;
        }
        &__sidebar-logout-wrapper {
            display: flex;
            align-items: center;
                cursor: pointer;
            p {
                font-family: 'NunitoMedium';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.5rem;
                color: $black1;
            }
            &:hover {
                .arrow {
                    left: 12px;
                }
            }
        }
        &__sidebar-logout-svg {
            position: relative;
            width: 17px;
            height: 19px;
            margin-right: 15px;
            .left {
                position: absolute;
                top: 0px;
                left: 0px;
            }
            .arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 6px;
                transition: .3s;
            }
        }
        &__content {
            width: 100%;
            height: calc(100vh - 104px);
            // background-image: url(http://localhost:8080/img/light-bcg-big.93d5f768.png);
            input {
                padding-left: 93px;
                width: 270px;
                padding: 15px;
            }
        }
        &__content-input {
            padding-left: 93px;
            input {
                background: $gray;
                border-radius: 10px;
                outline: none;
                border: none;
                font-family: "NunitoBold";
                font-weight: 700;
                font-size: 1.6rem;
                color: #111111;
            }
        }
        &__content-input-wrapper {
            position: relative;
            width: fit-content;
            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 24px;
            }
        }






        @keyframes myAnim {
            0%,
            100% {
                transform: translateX(0%);
                transform-origin: 50% 50%;
            }

            15% {
                transform: translateX(-5px) rotate(12deg);
            }

            30% {
                transform: translateX(5px) rotate(-12deg);
            }

            45% {
                transform: translateX(0px) rotate(3.6deg);
            }

            60% {
                transform: translateX(0px) rotate(-2.4deg);
            }

            75% {
                transform: translateX(0px) rotate(1.2deg);
            }
        }
    }
</style>