<template>
    <div class="landing-wrapper">
        <HeaderComponent/>
        <HeroComponent/>
        <ImageAndText/>
        <ProcessSteps/>
        <Video />
        <TextOnBlack/>
        <TextAndSlides/>
        <Instargram/>
        <Faq />
        <ProsCons/>
        <CallToAction/>
        <Footer />
        <!-- <HeaderComponent/>
        <LandingHero/>
        <TitleAndTextSlider/>
        <ImageAndText/>
        <TitleAndMovie/>
        <FaqComponent/>
        <NewsletterComponent/>
        <FooterComponent/> -->

    </div>
</template>

<script>
import HeaderComponent from "@/components/landing-03/HeaderComponent.vue";
import HeroComponent from "@/components/landing-03/HeroComponent.vue";
import ImageAndText from "@/components/landing-03/ImageAndText.vue";
import ProcessSteps from "@/components/landing-03/ProcessSteps.vue";
import TextOnBlack from "@/components/landing-03/TextOnBlack.vue";
import TextAndSlides from "@/components/landing-03/TextAndSlides.vue";
import CallToAction from "@/components/landing-03/CallToAction.vue";
import Instargram from "@/components/landing-03/Instagram.vue";
import Faq from "@/components/landing-03/Faq.vue";
import Footer from "@/components/landing-03/footer.vue";
import ProsCons from "@/components/landing-03/ProsCons.vue";
import Video from "@/components/landing-03/Video.vue";

// import FooterComponent from "@/components/landing/FooterComponent.vue";
// import HeaderComponent from "@/components/landing/HeaderComponent.vue";
// import LandingHero from "@/components/landing/LandingHero.vue";
// import TitleAndTextSlider from "@/components/landing/TitleAndTextSlider.vue";
// import ImageAndText from "@/components/landing/ImageAndText.vue";
// import TitleAndMovie from "@/components/landing/TitleAndMovie.vue";
// import FaqComponent from "@/components/landing/FaqComponent.vue";
// import NewsletterComponent from "@/components/landing/NewsletterComponent.vue";

export default {
    components: {
        HeaderComponent,
        HeroComponent,
        ImageAndText,
        ProsCons,
        ProcessSteps,
        TextOnBlack,
        TextAndSlides,
        CallToAction,
        Instargram,
        Faq,
        Footer,
        Video
        // HeaderComponent,
        // FooterComponent,
        // LandingHero,
        // TitleAndTextSlider,
        // ImageAndText,
        // FaqComponent,
        // TitleAndMovie,
        // NewsletterComponent
    },
}

</script>