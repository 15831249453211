import { defineStore } from 'pinia'
import { useUserStore } from './user'
import axios from 'axios'

const API_URL = process.env.VUE_APP_API
const PUBLIC_VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY
const APP_URL = process.env.VUE_APP_URL_GLOBAL

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        permissions: null,
        blockedByUser: null
    }),
    actions: {
        async setNotifications() {
            if (!('Notification' in window)) {
                console.log(
                    'This browser does not support desktop notifications'
                )
                this.permissions = false
                return
            }

            try {
                const permission = await Notification.requestPermission()
                if (permission === 'denied') {
                    this.permissions = false
                } else if (permission === 'granted') {
                    this.permissions = true
                    this.checkSubscription()
                }
            } catch (error) {
                console.error('Permission request failed:', error)
            }
        },
        checkNotificationPermission() {
            console.log('Checking notification permission...')
            console.log(Notification.permission)

            if (!('Notification' in window)) {
                console.log(
                    'This browser does not support desktop notifications'
                )
                this.permissions = false
                return
            }

            if (Notification.permission === 'denied') {
                this.permissions = false
            } else if (Notification.permission === 'granted') {
                this.permissions = true
                this.checkSubscription()
            } else {
                this.setNotifications()
            }
        },
        async subscribeUser(subscription) {
            const userID = window.localStorage.getItem('userId')
            console.log('Subscribing user...')
            console.log(subscription)
            try {
                const response = await axios.post(
                    `${API_URL}api/push/subscribe`,
                    {
                        userId: userID,
                        subscription: subscription
                    }
                )
                console.log(response)
            } catch (error) {
                console.error('Error subscribing user:', error)
            }
        },
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
            const base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/')
            const rawData = window.atob(base64)
            const buffer = new Uint8Array(rawData.length)

            for (let i = 0; i < rawData.length; ++i) {
                buffer[i] = rawData.charCodeAt(i)
            }

            return buffer
        },
        async checkSubscription() {
            const userID = window.localStorage.getItem('userId')

            if (!userID) {
                console.error('User ID not found.')
                return
            }

            try {
                console.log('Checking subscription...')
                const response = await axios.get(
                    `${API_URL}api/push/check-subscription/${userID}`,
                    {
                        userId: userID
                    }
                )

                if (response.data.subscription === 'true') {
                    console.log('User is already subscribed.')
                } else {
                    console.log('User is not subscribed.')
                    navigator.serviceWorker.ready.then(
                        serviceWorkerRegistration => {
                            this.subscribeToPush(serviceWorkerRegistration)
                        }
                    )
                }
            } catch (error) {
                console.error('Error checking subscription:', error)
            }

            // navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
            //     serviceWorkerRegistration.pushManager
            //         .getSubscription()
            //         .then(subscription => {
            //             if (subscription === null) {
            //                 this.subscribeToPush(serviceWorkerRegistration)
            //             } else {
            //                 console.log('User is already subscribed.')
            //                 //(arc browser)
            //                 this.setNotifications()
            //             }
            //         })
            //         .catch(error => {
            //             console.error('Error checking subscription:', error)
            //         })
            // })
        },
        subscribeToPush(serviceWorkerRegistration) {
            serviceWorkerRegistration.pushManager
                .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey:
                        this.urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
                })
                .then(subscription => {
                    console.log(subscription)
                    this.subscribeUser(subscription)
                })
                .catch(error => {
                    console.error(
                        'Error subscribing to push notifications:',
                        error
                    )
                })
        },
        registerServiceWorker() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker
                    .register(`${APP_URL}/sw.js`)
                    .then(registration => {
                        console.log(
                            'Service Worker registered with scope:',
                            registration.scope
                        )
                        console.log(registration)
                        this.checkNotificationPermission()
                    })

                    .catch(error => {
                        console.error(
                            'Service Worker registration failed:',
                            error
                        )
                    })
            }
        }
    }
})
1
