<template>
    <div>
      <h2>Dodaj użytkownika</h2>
      <form @submit.prevent="handleSubmit">
        <div>
          <label for="email">Email:</label>
          <input v-model="formData.email" id="email" type="email" required />
        </div>
        <div>
          <label for="password">Hasło:</label>
          <input v-model="formData.password" id="password" type="password" required />
        </div>
        <div>
          <label for="name">Imię:</label>
          <input v-model="formData.name" id="name" type="text" required />
        </div>
        <div>
          <label for="surname">Nazwisko:</label>
          <input v-model="formData.surname" id="surname" type="text" required />
        </div>
        <div>
          <label for="age">Wiek:</label>
          <input v-model="formData.age" id="age" type="date" required />
        </div>
        <div>
          <label for="profileDescription">Opis:</label>
          <textarea v-model="formData.profileDescription" id="profileDescription" required></textarea>
        </div>
        <div>
            <label for="sex">Płeć:</label>
            <div>
                <input v-model="formData.sex" id="male" type="radio" value="Mężczyzna">
                <label for="male">Mężczyzna</label>
            </div>
            <div>
                <input v-model="formData.sex" id="female" type="radio" value="Kobieta">
                <label for="female">Kobieta</label>
            </div>
        </div>

        <div>
          <label for="city">Miasto:</label>
          <input v-model="formData.city" id="city" type="text" required />
        </div>
        <div>
          <label for="growth">Wzrost (m) (np. 1,82):</label>
          <input v-model="formData.growth" id="growth" type="text" required />
        </div>
        <div>
            <label for="childrenHas">Czy ma dzieci:</label>
            <div>
                <input v-model="formData.childrenHas" id="childrenYes" type="radio" value="Tak">
                <label for="childrenYes">Tak</label>
            </div>
            <div>
                <input v-model="formData.childrenHas" id="childrenNo" type="radio" value="Nie">
                <label for="childrenNo">Nie</label>
            </div>
        </div>

        <div>
          <label for="photo">Zdjęcie:</label>
          <input @change="handlePhotoUpload" id="photo" type="file" accept="image/*" />
          <div v-if="photoPreview">
            <h3>Podgląd zdjęcia:</h3>
            <img :src="photoPreview" alt="Podgląd zdjęcia" width="200" />
          </div>
        </div>
        <div v-for="(question, index) in formData.completeSentences" :key="index">
          <label :for="'question-' + index">{{ question.text }}</label>
          <input v-model="formData.completeSentences[index].answer" :id="'question-' + index" type="text" />
        </div>
        <button type="submit" class="btn">Dodaj użytkownika</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, reactive } from 'vue';
  
  export default {
    setup() {
        const API_URL = process.env.VUE_APP_API;

      const formData = reactive({
        email: '',
        password: '',
        name: '',
        surname: '',
        age: '',
        profileDescription: '',
        sex: 'male',
        city: '',
        growth: null,
        childrenHas: false,
        photo: null,
        completeSentences: [
          { text: 'Uwielbiam...', answer: '' },
          { text: 'Nie przepadam...', answer: '' },
          { text: 'Tracę poczucie czasu, gdy...', answer: '' },
          { text: 'Nie żal mi pieniędzy na...', answer: '' },
          { text: 'Jestem najbardziej dumna/dumny z...', answer: '' },
          { text: 'Nie wyobrażam sobie życia bez...', answer: '' },
          { text: 'Najbardziej lubię spędzać wakacje...', answer: '' },
          { text: 'Nie jestem w stanie zjeść...', answer: '' },
          { text: 'W moich przyjaciołach najbardziej cenię...', answer: '' },
          { text: 'Najlepsza rada usłyszana w życiu to...', answer: '' },
          { text: 'Potrawa, którą przyrządzam po mistrzowsku to...', answer: '' },
          { text: 'Nie potrafię sobie odmówić...', answer: '' },
          { text: 'Cecha, która sprawia, że od razu kogoś lubię to...', answer: '' },
          { text: 'W wolnym czasie...', answer: '' },
          { text: 'Nigdy nie żal mi czasu na...', answer: '' },
          { text: 'Coś, co szybko wyprowadza mnie z równowagi to...', answer: '' },
          { text: 'Najgorszy prezent otrzymany w życiu to...', answer: '' },
          { text: 'Lubię zapach...', answer: '' },
          { text: 'Marzy mi się...', answer: '' },
          { text: 'Podziwiam ludzi za...', answer: '' },
        ],
      });
  
      const photoPreview = ref(null);
  
      const handlePhotoUpload = (event) => {
        const file = event.target.files[0];
        formData.photo = file;
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            photoPreview.value = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      };
  
      const handleSubmit = async () => {
        const data = new FormData();
        const answers = formData.completeSentences.map((question) => question.answer);
        data.append('completeSentences', JSON.stringify(answers));

        data.append('email', formData.email);
        data.append('password', formData.password);
        data.append('name', formData.name);
        data.append('surname', formData.surname);
        data.append('age', formData.age);
        data.append('profileDescription', formData.profileDescription);
        data.append('sex', formData.sex);
        data.append('city', formData.city);
        data.append('growth', formData.growth);
        data.append('childrenHas', formData.childrenHas);
  
        if (formData.photo) {
          data.append('photo', formData.photo);
        }
  
        try {
          const response = await axios.post(`${API_URL}api/users/premium`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('User added successfully:', response.data);
          location.reload();
        } catch (error) {
          console.error('Error adding user:', error);
        }
      };
  
      return {
        formData,
        photoPreview,
        handlePhotoUpload,
        handleSubmit,
      };
    },
  };
  </script>
  
  <style scoped>
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  img {
    margin-top: 1rem;
  }
  .btn {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 300px;
  }
  </style>
  