<template>
  <div>
    <HeaderComponent />
    <section class="password-reset">
      <div class="container">
        <h1 class="password-reset__title">RESETOWANIE HASŁA</h1>
        <div class="password-reset__form">
          <form v-if="tokenIncludes()" @submit.prevent="resetPassword">
            <label for="email">
              <input
                id="email"
                type="email"
                v-model="email"
                class="password-reset__form-password"
              />
              <span class="password-reset__form-error">
                {{ feedbackEmail }}
              </span>
              <p>EMAIL:</p>
            </label>
            <div
              :class="`password-reset__form-${
                error ? 'error' : 'response'
              }-login`"
            >
              <p>{{ feedback }}</p>
            </div>
            <div class="password-reset__wrapper-bottom">
              <button v-if="!loaderAnimation">Resetuj hasło</button>
              <button disabled v-else class="loader">
                <svg viewBox="25 25 50 50">
                  <circle r="20" cy="50" cx="50"></circle>
                </svg>
              </button>
            </div>
          </form>
          <form v-else @submit.prevent="resetPasswordPasswords">
            <label for="password">
              <input
                id="password"
                type="password"
                v-model="password"
                class="password-reset__form-password"
              />
              <svg
                class="eyePassword"
                @click="inputTypeChange($event)"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path
                  d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
                />
              </svg>
              <span class="password-reset__form-error">
                {{ feedbackPassword }}
              </span>
              <p>HASŁO:</p>
            </label>
            <label for="password2">
              <input
                id="password2"
                type="password"
                v-model="password2"
                class="password-reset__form-password2"
              />
              <svg
                class="eyePassword"
                @click="inputTypeChange($event)"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path
                  d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
                />
              </svg>
              <span class="password-reset__form-error">
                {{ feedbackPassword2 }}
              </span>
              <p>POWTÓRZ HASŁO:</p>
            </label>
            <div class="password-reset__form-error-login">
              <p>{{ feedback }}</p>
            </div>
            <div class="password-reset__wrapper-bottom">
              <button v-if="!loaderAnimation">Resetuj hasło</button>
              <button disabled v-else class="loader">
                <svg viewBox="25 25 50 50">
                  <circle r="20" cy="50" cx="50"></circle>
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
  import axios from 'axios'

  import HeaderComponent from '@/components/landing-03/HeaderComponent.vue'
  import Footer from '@/components/landing-03/footer.vue'

  export default {
    data() {
      return {
        email: '',
        password: '',
        password2: '',
        feedback: '',
        feedbackEmail: '',
        feedbackPassword: '',
        feedbackPassword2: '',
        passwordKey: 0,
        error: false,
        loaderAnimation: false
      }
    },
    components: {
      HeaderComponent,
      Footer
    },
    methods: {
      tokenIncludes() {
        let url = new URL(window.location.href)
        let c = url.searchParams.get('token')

        if (c) {
          return false
        }

        return true
      },
      resetPassword() {
        this.feedback = ''
        if (!this.emailValidation()) {
          return
        }
        this.loaderAnimation = true
        this.error = false
        this.feedback = ''

        axios
          .post(
            `${this.url}api/register/passchange`,
            {
              email: this.email
            },
            {
              withCredentials: true,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            }
          )
          .then(res => {
            this.loaderAnimation = false
            this.feedback = res?.data?.message
            this.loading = false
            this.error = false
            console.log(res)
          })
          .catch(err => {
            console.log(err)
            this.loaderAnimation = false
            this.error = true

            this.feedback = err?.response?.data?.message
              ? err?.response?.data?.message
              : err.message
          })
      },
      resetPasswordPasswords() {
        this.feedback = ''
        this.feedbackEmail = ''
        this.feedbackPassword = ''
        this.feedbackPassword2 = ''

        if (!this.passwordValidation()) {
          return
        }

        this.loaderAnimation = true

        let url = new URL(window.location.href)
        let c = url.searchParams.get('token')

        axios
          .post(
            `${this.url}api/register/reset-password`,
            {
              password: this.password,
              token: c
            },
            {
              withCredentials: true,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            }
          )
          .then(res => {
            this.loaderAnimation = false
            this.feedback = 'Twoje hasło zostało zmienione.'
            this.password = ''
            this.password2 = ''

            if (res.data.error) {
              this.feedback = res.data
              this.loading = false
            }
          })
          .catch(err => {
            this.loaderAnimation = false
            this.feedback = err
          })
      },
      inputTypeChange(e) {
        e.target.closest('svg').previousSibling.type == 'text'
          ? (e.target.closest('svg').previousSibling.type = 'password')
          : (e.target.closest('svg').previousSibling.type = 'text')
      },
      emailValidation() {
        if (!this.email) {
          this.feedbackEmail = 'Uzupełnij e-mail'
          return false
        } else {
          return true
        }
      },
      passwordValidation() {
        if (!this.password || !this.password2) {
          this.password == ''
            ? (this.feedbackPassword = 'Uzupełnij hasło.')
            : ''
          this.password2 == ''
            ? (this.feedbackPassword2 = 'Uzupełnij hasło.')
            : ''
          return false
        } else if (this.password != this.password2) {
          this.feedback = 'Hasła nie są identyczne.'
          return false
        } else {
          return true
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/breakpoints.scss';
  @import '@/assets/scss/containers.scss';
  @import '@/assets/scss/global.scss';
  @import '@/assets/scss/Nunito.css';

  .password-reset {
    padding: 170px 0 86px;
    background-color: $bege3;
    min-height: 70vh;
    @include breakpoint-max('mobile') {
      padding-top: 155px;
    }
    &__title {
      width: 100%;
      font-family: 'Oswald', sans-serif;
      font-size: 55px;
      line-height: 6rem;
      font-weight: 700;
      margin-bottom: 77px;
      color: $black;
      text-align: center;
      @include breakpoint-max('xs-tablet') {
        font-size: 55px;
        margin-bottom: 40px;
      }
      @include breakpoint-max('mobile') {
        font-size: 55px;
        line-height: 6rem;
      }
      @include breakpoint-max('s-mobile') {
        font-size: 6rem;
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      max-width: 1075px;
      margin: 0 auto;
      input {
        padding: 27px 25px;
        color: $black;
        font-size: 16px;
        line-height: 30px;
        border-radius: 0;
        font-family: 'Open Sans', sans-serif;
        outline: none;
        border: 1px solid $black;
        background-color: transparent;
        width: 100%;
        @include breakpoint-max('xs-tablet') {
          padding: 17px 15px;
        }
        @include breakpoint-max('mobile') {
          padding-top: 53px;
          padding-left: 25px !important;
        }
      }
      label {
        display: block;
        position: relative;
        p {
          font-weight: 700;
          color: $black;
          font-size: 16px;
          line-height: 30px;
          font-family: 'Open Sans', sans-serif;
          position: absolute;
          top: 51%;
          transform: translateY(-50%);
          left: 27px;
          @include breakpoint-max('mobile') {
            top: 20px;
            transform: translateY(0%);
            left: 28px;
          }
        }
        .eyePassword {
          position: absolute;
          top: 50%;
          right: 25px;
          transform: translateY(-50%);
          cursor: pointer;
          @include breakpoint-max('mobile') {
            top: 23px;
            transform: translate(0);
          }
        }
        #email,
        #password {
          padding-left: 100px;
        }
        #password {
          padding-left: 100px;
          border-bottom: 0;
        }
        #password2 {
          padding-left: 190px;
        }
      }
    }
    &__form-error {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 60px;
      font-weight: 700;
      color: $pink;
      font-size: 1.3rem;
      line-height: 3rem;
      font-family: 'Open Sans', sans-serif;
      @include breakpoint-max('mobile') {
        top: 35%;
      }
    }
    &__form-error-login {
      margin: 30px 0px;
      min-height: 30px;
      p {
        font-weight: 700;
        color: $pink;
        font-size: 1.6rem;
        line-height: 3rem;
        font-family: 'Open Sans', sans-serif;
        text-align: center;
      }
    }

    &__form-response-login {
      margin: 30px 0px;
      min-height: 30px;
      p {
        font-weight: 700;
        color: $black1;
        font-size: 1.6rem;
        line-height: 3rem;
        font-family: 'Open Sans', sans-serif;
        text-align: center;
      }
    }
    &__wrapper-bottom {
      display: flex;
      justify-content: center;
      text-align: center;
      button {
        outline: none;
        font-size: 2.4rem;
        background-color: $pink;
        line-height: 3rem;
        color: $white;
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        padding: 10px 40px;
        cursor: pointer;
      }
    }
    .loader {
      width: 262px;
      height: 50px;
      display: grid;
      place-items: center;
      svg {
        width: 24px;
        transform-origin: center;
        animation: rotate4 2s linear infinite;
      }

      circle {
        fill: none;
        stroke: $white;
        stroke-width: 2;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        animation: dash4 1.5s ease-in-out infinite;
      }

      @keyframes rotate4 {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes dash4 {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }

        50% {
          stroke-dasharray: 90, 200;
          stroke-dashoffset: -35px;
        }

        100% {
          stroke-dashoffset: -125px;
        }
      }
    }
    &__info {
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      margin-bottom: 50px;
      font-size: 2rem;
      line-height: 2.2rem;
      color: $blackRGBA;
    }
  }
</style>
