<template>
    <li class="single-notification" :data-notificaton-id="announcementId">
        <div class="single-notification__wrapper">
            <div v-if="!newNot" class="single-notification__new"></div>
            <div class="single-notification__image">
                <picture>
                    <img src="../../assets/images/BasiaChat.jpeg" alt="" />
                </picture>
            </div>
            <div class="single-notification__text">
                <div>{{ title }}</div>
                <div v-if="date" class="single-notification__date">
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-clock"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                            <path d="M12 7v5l3 3" />
                        </svg>
                    </div>
                    <div>Otrzymano: {{ formattedDate(date) }}</div>
                </div>
            </div>

            <svg
                @click="showContentF"
                v-if="content"
                :class="[
                    { 'eye-visible': showContent },
                    'single-notification__eye'
                ]"
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="27"
                viewBox="0 0 41 27"
                fill="none"
                data-v-637558fb=""
            >
                <g clip-path="url(#clip0_1492_2)" data-v-637558fb="">
                    <mask
                        id="mask0_1492_2"
                        style="mask-type: luminance"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="41"
                        height="27"
                        data-v-637558fb=""
                    >
                        <path
                            d="M40.956 0H0V26.382H40.956V0Z"
                            fill="white"
                            data-v-637558fb=""
                        ></path>
                    </mask>
                    <g mask="url(#mask0_1492_2)" data-v-637558fb="">
                        <path
                            d="M20.514 26.381C7.651 26.381 2.659 18.627 2.453 18.297C2.38558 18.1892 2.34984 18.0647 2.34984 17.9375C2.34984 17.8104 2.38558 17.6858 2.453 17.578C2.659 17.248 7.653 9.49402 20.514 9.49402C33.396 9.49402 37.952 17.272 38.14 17.603C38.1977 17.7051 38.228 17.8203 38.228 17.9375C38.228 18.0547 38.1977 18.17 38.14 18.272C37.953 18.603 33.397 26.381 20.514 26.381ZM3.862 17.939C5.004 19.43 9.995 25.026 20.515 25.026C31.115 25.026 35.73 19.389 36.741 17.94C35.721 16.494 31.075 10.851 20.514 10.851C9.994 10.851 5.003 16.451 3.861 17.938"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M20.289 23.92C19.1061 23.92 17.9497 23.5692 16.9661 22.912C15.9826 22.2548 15.216 21.3207 14.7633 20.2278C14.3106 19.135 14.1921 17.9324 14.4229 16.7722C14.6537 15.612 15.2233 14.5463 16.0598 13.7098C16.8963 12.8733 17.962 12.3037 19.1222 12.0729C20.2824 11.8422 21.4849 11.9606 22.5778 12.4133C23.6707 12.866 24.6048 13.6326 25.262 14.6161C25.9192 15.5997 26.27 16.7561 26.27 17.939C26.2684 19.5248 25.6378 21.0452 24.5165 22.1665C23.3951 23.2878 21.8748 23.9184 20.289 23.92ZM20.289 13.32C19.3745 13.3202 18.4806 13.5916 17.7203 14.0998C16.96 14.608 16.3675 15.3302 16.0177 16.1752C15.6679 17.0201 15.5764 17.9498 15.755 18.8467C15.9335 19.7436 16.374 20.5674 17.0207 21.214C17.6674 21.8606 18.4913 22.3009 19.3883 22.4792C20.2852 22.6576 21.2149 22.5659 22.0598 22.2159C22.9046 21.8659 23.6267 21.2733 24.1348 20.5129C24.6428 19.7525 24.914 18.8585 24.914 17.944C24.9124 16.718 24.4246 15.5426 23.5576 14.6757C22.6905 13.8089 21.515 13.3213 20.289 13.32Z"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M5.037 13.315C4.93837 13.3153 4.84087 13.294 4.75138 13.2525C4.66188 13.211 4.58256 13.1505 4.519 13.075L0.160002 7.91403C0.0438367 7.77612 -0.0127848 7.59771 0.0025932 7.41805C0.0179712 7.23839 0.104089 7.0722 0.242002 6.95603C0.379914 6.83987 0.558324 6.78324 0.737984 6.79862C0.917644 6.814 1.08384 6.90012 1.2 7.03803L5.558 12.198C5.64151 12.2967 5.69506 12.4172 5.71237 12.5453C5.72967 12.6734 5.71 12.8037 5.65566 12.921C5.60133 13.0383 5.5146 13.1376 5.4057 13.2072C5.2968 13.2769 5.17026 13.3139 5.041 13.314"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M35.918 13.315C35.7887 13.3149 35.6622 13.2778 35.5533 13.2082C35.4444 13.1386 35.3577 13.0393 35.3033 12.922C35.249 12.8047 35.2293 12.6743 35.2466 12.5462C35.2639 12.4181 35.3175 12.2977 35.401 12.199L39.76 7.039C39.876 6.90162 40.0419 6.81595 40.2211 6.80086C40.4003 6.78576 40.5781 6.84247 40.7155 6.9585C40.8529 7.07453 40.9385 7.24038 40.9536 7.41957C40.9687 7.59877 40.912 7.77662 40.796 7.914L36.437 13.076C36.3734 13.1514 36.2941 13.212 36.2046 13.2535C36.1151 13.2949 36.0176 13.3163 35.919 13.316"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M9.489 9.98195C9.35824 9.98207 9.23023 9.94437 9.1204 9.8734C9.01058 9.80243 8.92362 9.70121 8.87 9.58195L6.146 3.51495C6.10774 3.43347 6.0861 3.34518 6.08233 3.25524C6.07856 3.16531 6.09273 3.07552 6.12402 2.99112C6.15532 2.90672 6.20311 2.82939 6.2646 2.76366C6.32609 2.69792 6.40007 2.64509 6.48219 2.60824C6.56432 2.57139 6.65296 2.55126 6.74295 2.54904C6.83294 2.54681 6.92247 2.56252 7.00632 2.59526C7.09017 2.628 7.16667 2.67711 7.23134 2.73972C7.29601 2.80234 7.34756 2.8772 7.383 2.95995L10.107 9.02695C10.1534 9.13017 10.1732 9.24337 10.1647 9.35622C10.1561 9.46907 10.1194 9.57798 10.058 9.67303C9.99656 9.76807 9.9123 9.84622 9.81292 9.90036C9.71353 9.95449 9.60217 9.98289 9.489 9.98295"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M31.467 9.981C31.3538 9.98093 31.2425 9.95254 31.1431 9.8984C31.0437 9.84427 30.9594 9.76612 30.898 9.67107C30.8366 9.57603 30.7999 9.46712 30.7913 9.35427C30.7828 9.24142 30.8026 9.12822 30.849 9.025L33.573 2.958C33.6084 2.87525 33.66 2.80038 33.7247 2.73777C33.7893 2.67515 33.8658 2.62605 33.9497 2.59331C34.0335 2.56057 34.1231 2.54485 34.213 2.54708C34.303 2.54931 34.3917 2.56944 34.4738 2.60628C34.5559 2.64313 34.6299 2.69597 34.6914 2.7617C34.7529 2.82744 34.8007 2.90476 34.832 2.98916C34.8633 3.07357 34.8774 3.16335 34.8737 3.25329C34.8699 3.34323 34.8482 3.43151 34.81 3.513L32.086 9.581C32.0324 9.70026 31.9454 9.80148 31.8356 9.87245C31.7258 9.94342 31.5978 9.98111 31.467 9.981Z"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M14.986 8.64499C14.825 8.64491 14.6692 8.58751 14.5466 8.48307C14.4241 8.37863 14.3427 8.23397 14.317 8.07499L13.227 1.31899C13.2128 1.23107 13.2161 1.14122 13.2366 1.05456C13.2572 0.96791 13.2946 0.886149 13.3467 0.813952C13.3989 0.741754 13.4647 0.680533 13.5405 0.633785C13.6163 0.587036 13.7006 0.555675 13.7885 0.541492C13.8764 0.527309 13.9663 0.530582 14.0529 0.551125C14.1396 0.571667 14.2213 0.609076 14.2935 0.661216C14.3657 0.713357 14.427 0.779206 14.4737 0.855006C14.5205 0.930806 14.5518 1.01507 14.566 1.10299L15.656 7.85899C15.6716 7.95597 15.666 8.05518 15.6396 8.14977C15.6131 8.24436 15.5664 8.33207 15.5026 8.40682C15.4389 8.48158 15.3597 8.54159 15.2705 8.58272C15.1813 8.62384 15.0842 8.64509 14.986 8.64499Z"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M25.97 8.64499C25.8718 8.64509 25.7747 8.62384 25.6855 8.58272C25.5963 8.54159 25.5171 8.48158 25.4534 8.40682C25.3896 8.33207 25.3429 8.24436 25.3164 8.14977C25.29 8.05518 25.2844 7.95597 25.3 7.85899L26.389 1.10299C26.4032 1.01507 26.4346 0.930806 26.4813 0.855006C26.5281 0.779206 26.5893 0.713357 26.6615 0.661216C26.7337 0.609076 26.8154 0.571667 26.9021 0.551125C26.9887 0.530582 27.0786 0.527309 27.1665 0.541492C27.2544 0.555675 27.3387 0.587036 27.4145 0.633785C27.4903 0.680533 27.5561 0.741754 27.6083 0.813952C27.6604 0.886149 27.6978 0.967909 27.7184 1.05456C27.7389 1.14122 27.7422 1.23107 27.728 1.31899L26.639 8.07499C26.6134 8.23397 26.532 8.37863 26.4094 8.48307C26.2868 8.58751 26.1311 8.64491 25.97 8.64499Z"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M20.289 8.154C20.1092 8.154 19.9367 8.08257 19.8096 7.95542C19.6824 7.82827 19.611 7.65582 19.611 7.476V0.678C19.611 0.498183 19.6824 0.325731 19.8096 0.198582C19.9367 0.0714319 20.1092 0 20.289 0C20.4688 0 20.6413 0.0714319 20.7684 0.198582C20.8956 0.325731 20.967 0.498183 20.967 0.678V7.478C20.967 7.65782 20.8956 7.83027 20.7684 7.95742C20.6413 8.08457 20.4688 8.156 20.289 8.156"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                    </g>
                </g>
                <defs data-v-637558fb="">
                    <clipPath id="clip0_1492_2" data-v-637558fb="">
                        <rect
                            width="40.956"
                            height="26.382"
                            fill="white"
                            data-v-637558fb=""
                        ></rect>
                    </clipPath>
                </defs>
            </svg>
            <svg
                @click="showContentF"
                v-if="content"
                :class="[
                    { 'eye-visible': !showContent },
                    'single-notification__eye'
                ]"
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="19"
                viewBox="0 0 41 19"
                fill="none"
                data-v-637558fb=""
            >
                <g clip-path="url(#clip0_1493_2)" data-v-637558fb="">
                    <mask
                        id="mask0_1493_2"
                        style="mask-type: luminance"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="41"
                        height="19"
                        data-v-637558fb=""
                    >
                        <path
                            d="M-7.62939e-06 18.549L40.956 18.549V-4.95911e-05L-7.62939e-06 -4.95911e-05V18.549Z"
                            fill="white"
                            data-v-637558fb=""
                        ></path>
                    </mask>
                    <g mask="url(#mask0_1493_2)" data-v-637558fb="">
                        <path
                            d="M35.919 5.23392C36.0176 5.23365 36.1151 5.255 36.2046 5.29647C36.2941 5.33793 36.3734 5.3985 36.437 5.47392L40.796 10.6349C40.8535 10.7032 40.897 10.7822 40.924 10.8673C40.951 10.9524 40.961 11.0419 40.9534 11.1309C40.9458 11.2199 40.9207 11.3065 40.8796 11.3857C40.8386 11.465 40.7823 11.5354 40.714 11.5929C40.6457 11.6504 40.5668 11.6939 40.4817 11.721C40.3965 11.748 40.307 11.7579 40.218 11.7503C40.129 11.7427 40.0425 11.7177 39.9632 11.6766C39.8839 11.6355 39.8135 11.5792 39.756 11.5109L35.398 6.35092C35.3145 6.25226 35.2609 6.13178 35.2436 6.00368C35.2263 5.87558 35.246 5.74521 35.3003 5.62793C35.3547 5.51064 35.4414 5.41133 35.5503 5.3417C35.6592 5.27207 35.7857 5.23502 35.915 5.23492"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M5.03799 5.23395C5.16725 5.23405 5.29381 5.2711 5.40271 5.34073C5.51161 5.41036 5.59832 5.50967 5.65265 5.62696C5.70698 5.74424 5.72665 5.87461 5.70935 6.00271C5.69205 6.1308 5.6385 6.25129 5.55499 6.34995L1.19598 11.51C1.07995 11.6473 0.914116 11.733 0.734925 11.7481C0.555734 11.7632 0.377892 11.7065 0.24051 11.5905C0.103127 11.4744 0.017447 11.3086 0.00235033 11.1294C-0.0127463 10.9502 0.0439719 10.7723 0.160004 10.635L4.51901 5.47495C4.58258 5.39953 4.66188 5.33896 4.75137 5.29749C4.84087 5.25603 4.93835 5.23468 5.03699 5.23495"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M31.467 8.567C31.5977 8.56688 31.7258 8.60458 31.8356 8.67555C31.9454 8.74652 32.0324 8.84774 32.086 8.967L34.81 15.034C34.8482 15.1155 34.8699 15.2038 34.8737 15.2937C34.8774 15.3836 34.8633 15.4734 34.832 15.5578C34.8007 15.6422 34.7529 15.7196 34.6914 15.7853C34.6299 15.851 34.5559 15.9039 34.4738 15.9407C34.3917 15.9776 34.303 15.9977 34.213 15.9999C34.123 16.0021 34.0335 15.9864 33.9497 15.9537C33.8658 15.9209 33.7893 15.8718 33.7246 15.8092C33.66 15.7466 33.6084 15.6717 33.573 15.589L30.849 9.522C30.8026 9.41878 30.7828 9.30558 30.7913 9.19273C30.7999 9.07988 30.8365 8.97097 30.898 8.87592C30.9594 8.78088 31.0437 8.70273 31.1431 8.64859C31.2425 8.59446 31.3538 8.56606 31.467 8.566"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M9.48898 8.56796C9.60215 8.56802 9.71352 8.59641 9.81291 8.65055C9.9123 8.70468 9.99655 8.78283 10.058 8.87788C10.1194 8.97292 10.1561 9.08183 10.1647 9.19468C10.1732 9.30753 10.1534 9.42073 10.107 9.52395L7.38299 15.591C7.34756 15.6737 7.29599 15.7486 7.23132 15.8112C7.16665 15.8738 7.09016 15.9229 7.00631 15.9556C6.92246 15.9884 6.83293 16.0041 6.74295 16.0019C6.65296 15.9996 6.5643 15.9795 6.48217 15.9427C6.40005 15.9058 6.32608 15.853 6.26458 15.7872C6.20309 15.7215 6.1553 15.6442 6.124 15.5598C6.09271 15.4754 6.07854 15.3856 6.08232 15.2957C6.08609 15.2057 6.10774 15.1174 6.14599 15.036L8.86999 8.96795C8.92361 8.84869 9.01056 8.74747 9.12039 8.6765C9.23021 8.60553 9.35822 8.56784 9.48898 8.56796Z"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M25.97 9.90396C26.131 9.90404 26.2868 9.96144 26.4094 10.0659C26.5319 10.1703 26.6133 10.315 26.639 10.474L27.729 17.23C27.7432 17.3179 27.7399 17.4077 27.7194 17.4944C27.6988 17.581 27.6614 17.6628 27.6093 17.735C27.5571 17.8072 27.4913 17.8684 27.4155 17.9152C27.3397 17.9619 27.2554 17.9933 27.1675 18.0075C27.0796 18.0216 26.9897 18.0184 26.9031 17.9978C26.8164 17.9773 26.7347 17.9399 26.6625 17.8877C26.5903 17.8356 26.529 17.7697 26.4823 17.6939C26.4355 17.6181 26.4042 17.5339 26.39 17.446L25.3 10.69C25.2844 10.593 25.29 10.4938 25.3164 10.3992C25.3429 10.3046 25.3896 10.2169 25.4534 10.1421C25.5171 10.0674 25.5963 10.0074 25.6855 9.96624C25.7747 9.92511 25.8718 9.90386 25.97 9.90396Z"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M14.986 9.90396C15.0842 9.90386 15.1813 9.92511 15.2705 9.96623C15.3597 10.0074 15.4389 10.0674 15.5026 10.1421C15.5664 10.2169 15.6131 10.3046 15.6396 10.3992C15.666 10.4938 15.6716 10.593 15.656 10.69L14.567 17.446C14.5384 17.6235 14.4403 17.7824 14.2945 17.8877C14.1487 17.993 13.9671 18.0361 13.7895 18.0075C13.6119 17.9788 13.453 17.8808 13.3477 17.735C13.2424 17.5892 13.1993 17.4075 13.228 17.23L14.317 10.474C14.3426 10.315 14.424 10.1703 14.5466 10.0659C14.6692 9.96144 14.825 9.90404 14.986 9.90396Z"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M20.667 10.3949C20.8468 10.3949 21.0193 10.4664 21.1464 10.5935C21.2736 10.7207 21.345 10.8931 21.345 11.073V17.871C21.345 18.0508 21.2736 18.2232 21.1464 18.3504C21.0193 18.4775 20.8468 18.549 20.667 18.549C20.4872 18.549 20.3147 18.4775 20.1876 18.3504C20.0604 18.2232 19.989 18.0508 19.989 17.871V11.071C19.989 10.8911 20.0604 10.7187 20.1876 10.5915C20.3147 10.4644 20.4872 10.393 20.667 10.393"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                        <path
                            d="M3.26799 -0.0010891C3.36828 -0.00115602 3.46703 0.0235492 3.55548 0.0708256C3.64392 0.118102 3.71933 0.18649 3.77499 0.269915C4.93697 1.82611 6.34315 3.184 7.93899 4.29091C11.645 6.90928 16.1036 8.24825 20.639 8.10491C23.8049 8.11874 26.9382 7.46652 29.8357 6.19061C32.7331 4.91471 35.3297 3.04364 37.457 0.698915C37.5527 0.566948 37.6729 0.45458 37.811 0.367914C37.9443 0.295333 38.0999 0.275067 38.2474 0.311075C38.3948 0.347083 38.5236 0.436774 38.6084 0.562632C38.6933 0.688489 38.7282 0.841471 38.7063 0.991678C38.6844 1.14189 38.6073 1.27853 38.49 1.37491C38.463 1.40291 38.424 1.44891 38.371 1.50891C36.1334 3.97989 33.4014 5.95288 30.3521 7.29989C27.3029 8.6469 24.0045 9.33785 20.671 9.32791C15.8534 9.47796 11.1189 8.0479 7.18999 5.25592C5.48824 4.07606 3.99311 2.62316 2.76499 0.955912C2.70338 0.864137 2.66786 0.757346 2.66224 0.646952C2.65661 0.536557 2.68109 0.426711 2.73306 0.329149C2.78502 0.231588 2.86252 0.149983 2.95726 0.09305C3.05201 0.0361174 3.16045 0.00599771 3.27099 0.00591469"
                            fill="#1D1D1B"
                            data-v-637558fb=""
                        ></path>
                    </g>
                </g>
                <defs data-v-637558fb="">
                    <clipPath id="clip0_1493_2" data-v-637558fb="">
                        <rect
                            width="40.956"
                            height="18.549"
                            fill="white"
                            data-v-637558fb=""
                        ></rect>
                    </clipPath>
                </defs>
            </svg>
            <svg
                v-if="!notDelete"
                @click="$emit('deleteClick', { announcementId, userId })"
                class="single-notification__exit"
                xmlns="http://www.w3.org/2000/svg"
                width="16.714"
                height="16.714"
                viewBox="0 0 16.714 16.714"
            >
                <g
                    id="Group_239"
                    data-name="Group 239"
                    transform="translate(-1088.086 -7139.086)"
                >
                    <line
                        id="Line_68"
                        data-name="Line 68"
                        y1="13.886"
                        x2="13.886"
                        transform="translate(1089.5 7140.5)"
                        fill="none"
                        stroke="#d13724"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                    <line
                        id="Line_69"
                        data-name="Line 69"
                        x2="13.886"
                        y2="13.886"
                        transform="translate(1089.5 7140.5)"
                        fill="none"
                        stroke="#d13724"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                </g>
            </svg>
        </div>

        <div
            :class="[
                { showContent: showContent },
                'single-notification__content'
            ]"
        >
            <component
                v-if="!hasProfileSeen"
                :is="slotComponent?.name"
                v-bind="slotComponent?.props"
                @show-profile-once="() => (this.showUserProfilePopup = true)"
            ></component>
            <div v-if="showUserProfilePopup && !hasProfileSeen">
                <UserProfilePopup
                    @close="
                        () => {
                            this.showUserProfilePopup = false
                            setHasPopupSeen()
                        }
                    "
                    :userID="slotComponent?.props.id"
                />
            </div>
            <div class="single-notification__vhtml" v-html="content"></div>
        </div>
    </li>
</template>
<script>
    import UserProfileNotification from '@/components/notifications/UserProfileNotification.vue'
    import UserProfilePopup from '@/components/notifications/UserProfilePopup.vue'

    export default {
        data() {
            return {
                newNot: false,
                showContent: false,
                showUserProfilePopup: false,
                hasProfileSeen: false
            }
        },
        props: {
            content: String,
            userId: String,
            secondUserId: String,
            title: String,
            date: String,
            announcementId: String,
            notDelete: Boolean,
            slotComponent: Object
        },
        methods: {
            showContentF() {
                this.showContent
                    ? (this.showContent = false)
                    : (this.showContent = true)
            },

            formattedDate(val) {
                const date = new Date(val)
                date.setHours(date.getHours())

                const year = date.getFullYear()
                const month = String(date.getMonth() + 1).padStart(2, '0')
                const day = String(date.getDate()).padStart(2, '0')
                const hours = String(date.getHours()).padStart(2, '0')
                const minutes = String(date.getMinutes()).padStart(2, '0')
                const seconds = String(date.getSeconds()).padStart(2, '0')

                const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
                return formattedDate
            },

            setHasPopupSeen() {
                localStorage.setItem(
                    `${this.announcementId}`,
                    this.announcementId
                )
                this.hasProfileSeen = true
            },

            checkHasProfileSeen() {
                const seenProfile = localStorage.getItem(
                    `${this.announcementId}`
                )
                if (seenProfile === this.announcementId) {
                    this.hasProfileSeen = true
                } else {
                    this.hasProfileSeen = false
                }
            }
        },
        components: {
            UserProfileNotification,
            UserProfilePopup
        },
        mounted() {
            this.checkHasProfileSeen()
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .single-notification {
        position: relative;
        padding: 16px 35px;
        border: 1px solid $black;

        transition: all 0.1s ease-in-out;
        box-sizing: border-box;
        opacity: 1;
        @include breakpoint-max('xs-tablet') {
            padding: 12px 20px;
        }
        &__wrapper {
            display: flex;
            align-items: center;
            column-gap: 35px;
            @include breakpoint-max('xs-tablet') {
                column-gap: 15px;
            }
        }
        &__new {
            position: absolute;
            top: 0px;
            left: -1px;
            width: 10px;
            height: 100%;
            background-color: $pink;
        }
        &__image {
            picture {
                display: block;
                img {
                    border-radius: 50%;
                    object-position: 0 -4px;
                    display: block;
                    width: 57px;
                    height: 57px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    @include breakpoint-max('xs-tablet') {
                        width: 45px;
                        height: 45px;
                    }
                }
            }
        }
        &__text {
            max-width: 82%;
            width: 100%;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            display: flex;
            flex-direction: column;
            @include breakpoint-max('xl-tablet') {
                max-width: 70%;
            }
            @include breakpoint-max('s-tablet') {
                max-width: 65%;
            }
            @include breakpoint-max('xs-tablet') {
                max-width: 76%;
            }
            @include breakpoint-max('mobile') {
                font-size: 1.3rem;
                line-height: 2rem;
                max-width: 57%;
            }
        }
        &__date {
            padding-top: 16px;
            display: flex;
            gap: 8px;
        }

        &__eye {
            height: 27px;
            opacity: 0;
            position: absolute;
            top: 30px;
            right: 65px;
            transition: 0.3s;
            cursor: pointer;
            min-width: 33px;
            @include breakpoint-max('xs-tablet') {
                height: 22px;
                top: 33px;
            }
            @include breakpoint-max('mobile') {
                min-width: 30px;
                max-width: 30px;
            }
        }
        .eye-visible {
            opacity: 1;
        }
        &__exit {
            position: absolute;
            top: 35px;
            right: 24px;
            cursor: pointer;
            min-width: 13px;
        }
        &__content {
            max-height: 0;
            overflow: hidden;
            width: 100%;
            padding-left: 92px;
            transition: 0.3s ease-out;
            a {
                color: $pink;
            }
            p {
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                padding-top: 40px;

                &::selection {
                    background-color: transparent;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 62px;
            }
            @include breakpoint-max('mobile') {
                padding-left: 8px;
                font-size: 1.3rem;
                line-height: 2rem;
            }
        }

        &__vhtml {
            display: flex;
            flex-direction: column;
        }

        &--is-hidden {
            max-height: 0;
            overflow: hidden;
            padding: 0;
            border: none;
            opacity: 0;
        }
        .showContent {
            transition: 0.1s ease-out;
            max-height: 1800px;
        }
    }
</style>
