<template>
    <div>
        <div class="matches-filters__wrapper">
            <div class="matches-filters__title">Ustaw kryteria:</div>
            <div class="matches-filters__filters">
                <div>
                    Miasta:
                    <select
                        v-model="filters['city']"
                        class="select--cities"
                        name="cities"
                        id="cities"
                    >
                        <option
                            v-for="(city, index) in cities"
                            :value="normalizeString(city)"
                            :key="index"
                        >
                            {{ city }}
                        </option>
                        <option value="poznan">Poznań</option>
                    </select>
                    Miasto w którym chcesz iść na randkę:
                    <select
                        v-model="filters['cityPossible']"
                        class="select--cities"
                        name="cities"
                        id="cities"
                    >
                        <option
                            v-for="(city, index) in cities"
                            :value="normalizeString(city)"
                            :key="index"
                        >
                            {{ city }}
                        </option>
                        <option value="poznan">Poznań</option>
                    </select>
                </div>
                <div>
                    wiek: od:
                    <input
                        v-model="filters['ageFrom']"
                        type="number"
                        name="ageFrom"
                        id="ageFrom"
                        min="18"
                    />
                    do
                    <input
                        v-model="filters['ageTo']"
                        type="number"
                        name="age-to"
                        id="age-to"
                        min="18"
                    />
                </div>
                <div>
                    wzrost: od:
                    <input
                        v-model="filters['heightFrom']"
                        type="number"
                        name="height-from"
                        id="height-from"
                        min="150"
                        max="300"
                    />
                    do
                    <input
                        v-model="filters['heightTo']"
                        type="number"
                        name="height-to"
                        id="height-to"
                        min="150"
                        max="300"
                    />
                </div>

                <div>
                    opcja przeprowadzki:
                    <select
                        v-model="filters['possibilityToMove']"
                        name="possibility-to-move"
                        id="possibility-to-move"
                    >
                        <option value="Rozważam taką możliwość">
                            Rozważam taką możliwość
                        </option>
                        <option value="Tak">Tak</option>
                        <option value="Nie">Nie</option>
                    </select>
                </div>
                <div>
                    Po rozwodzie
                    <select
                        v-model="filters['afterDivorce']"
                        name="after-divorce"
                        id="after-divorce"
                    >
                        <option value="po rozwodzie">Tak</option>
                        <option :value="null">Nie</option>
                    </select>
                </div>
                <div>
                    akceptuje rozwód:
                    <select
                        v-model="filters['approveDivorce']"
                        name="approve-divorce"
                        id="approve-divorce"
                    >
                        <option value="Absolutnie nie">Absolutnie nie</option>
                        <option value="Tak">Tak</option>
                    </select>
                </div>
                <div>
                    ma dzieci:
                    <select
                        v-model="filters['haveChilds']"
                        name="have-childs"
                        id="have-childs"
                    >
                        <option value="Tak">tak</option>
                        <option value="Nie">nie</option>
                    </select>
                </div>
                <div>
                    chce dzieci:
                    <select
                        v-model="filters['wantChilds']"
                        name="want-childs"
                        id="want-childs"
                    >
                        <option value="Chcę">Chcę</option>
                        <option value="Nie chcę">Nie chcę</option>
                    </select>
                </div>

                <div>
                    Czy bierzesz pod uwagę związek z osobą, która ma
                    dziecko/dzieci?
                    <select
                        v-model="filters['childrenPartnerHas']"
                        name="children-partner-has"
                        id="children-partner-has"
                    >
                        <option value="Tak">Tak</option>
                        <option value="Nie">Nie</option>
                    </select>
                </div>
                <div>
                    zarobki:
                    <select
                        v-model="filters['income']"
                        name="income"
                        id="income"
                        class="select--income"
                    >
                        <option
                            v-for="(option, index) in incomes"
                            :key="index"
                            :value="option"
                        >
                            {{ option }}
                        </option>
                        <option value="0-50 tysięcy zł brutto">
                            0-50 tysięcy zł brutto
                        </option>
                    </select>
                </div>

                <div>
                    Stosunek do zwierząt:
                    <select
                        v-model="filters['petsAttitude']"
                        class="select--pets--attitude"
                        name="petsAttitude"
                        id="petsAttitude"
                    >
                        <option value="Lubię zwierzaki">Lubię zwierzaki</option>
                        <option value="Nie przepadam">Nie przepadam</option>
                        <option value="Zdecydowanie nie przepadam">
                            Zdecydowanie nie przepadam
                        </option>
                    </select>
                </div>

                <div>
                    Osoby jakiej płci poszukujesz?:
                    <select
                        v-model="filters['wantedGender']"
                        class="select--wanted-gender"
                        name="wantedGender"
                        id="wantedGender"
                    >
                        <option value="Mężczyzna">Mężczyzna</option>
                        <option value="Kobieta">Kobieta</option>
                        <option value="Osoba niebinarna">
                            Osoba niebinarna
                        </option>
                    </select>
                </div>
                <button
                    @click="resetFilters"
                    class="matches-filters__reset-btn"
                >
                    Wyczyść filtry
                </button>
            </div>
        </div>

        <div
            class="matches-filters__count"
            v-if="FilteredMatches && FilteredMatches.length"
        >
            Wyników: {{ FilteredMatches.length }}
        </div>
        <div
            v-if="FilteredMatches && FilteredMatches.length"
            v-for="(el, index) in FilteredMatches"
            :key="el._id"
        >
            <SingleMatchToggleView
                :userID="el.userId"
                :user="el"
                :mainUserName="userQuestionaire.name"
                :mainUserEmail="userQuestionaire.email"
                :mainUserPhone="userQuestionaire.phone"
                :mainuserQuestionaire="userQuestionaire"
            />
        </div>
        <div v-else>
            <div>Brak wyników...</div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, defineProps } from 'vue'
    import SingleMatchToggleView from '@/components/atoms/SingleMatchToggleView.vue'

    const props = defineProps({
        matches: {
            type: Array,
            required: true
        },
        userQuestionaire: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    })

    const cities = ref([
        'Warszawa',
        'Poznań',
        'Wrocław',
        'Kraków',
        'Łódź',
        'Rzeszów',
        'Gdańsk/Gdynia/Sopot',
        'Katowice',
        'Szczecin',
        'Bydgoszcz',
        'Lublin'
    ])

    const incomes = ref([
        '0-50 tysięcy zł brutto',
        '50-100.000 zł brutto',
        '100-200.000 zł brutto',
        '200-500.000 zł brutto',
        'powyżej 500.000 zł brutto',
        'nie chcę udzielać odpowiedzi'
    ])

    const filters = ref({
        city: null,
        cityPossible: null,
        ageFrom: null,
        ageTo: null,
        heightFrom: null,
        heightTo: null,
        possibilityToMove: null,
        afterDivorce: null,
        approveDivorce: null,
        haveChilds: null,
        wantChilds: null,
        income: null,
        petsAttitude: null,
        wantedGender: null,
        childrenPartnerHas: null,
        ageDifference: null
    })

    const resetFilters = () => {
        Object.keys(filters.value).forEach(key => {
            filters.value[key] = null
        })
    }

    const setAge = el => {
        const date = new Date()
        const birthDate = new Date(el)

        let age = date.getFullYear() - birthDate.getFullYear()

        const month = date.getMonth()
        const day = date.getDate()
        const birthMonth = birthDate.getMonth()
        const birthDay = birthDate.getDate()

        if (month < birthMonth || (month === birthMonth && day < birthDay)) {
            age--
        }

        return age
    }

    const normalizeString = string => {
        return string
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
    }

    const ageDifference = el => {
        if (el.ageDifference === 'do 5 lat') {
            return 5
        } else if (el.ageDifference === 'do 10 lat') {
            return 10
        } else if (el.ageDifference === 'do 15 lat') {
            return 15
        } else if (el.ageDifference === 'powyżej 15 lat') {
            return 100
        } else {
            return 101
        }
    }

    function diff(num1, num2) {
        if (num1 > num2) {
            return num1 - num2
        } else {
            return num2 - num1
        }
    }

    const FilteredMatches = computed(() => {
        const filteredMatches = props.matches.filter(el => {
            const age = setAge(el.age)

            const cityFilterMatch =
                !filters.value.city ||
                (el.city &&
                    normalizeString(el.city.toLowerCase()) ===
                        filters.value.city.toLowerCase())

            const cityPossibleFilterMatch =
                !filters.value.cityPossible ||
                el.cityPossible.some(city =>
                    filters.value.cityPossible.includes(
                        normalizeString(city.toLowerCase())
                    )
                )

            const ageInRange =
                (!filters.value.ageFrom || filters.value.ageFrom <= age) &&
                (!filters.value.ageTo || filters.value.ageTo >= age)

            const growthInRange =
                (!filters.value.heightFrom ||
                    filters.value.heightFrom <= el.growth * 100) &&
                (!filters.value.heightTo ||
                    filters.value.heightTo >= el.growth * 100)

            const movePossibilityFilterMatch =
                !filters.value.possibilityToMove ||
                (el.movePossibility &&
                    el.movePossibility.toLowerCase() ===
                        filters.value.possibilityToMove.toLowerCase())

            const isAfterDivorce =
                !filters.value.afterDivorce ||
                (el.materialStatus &&
                    el.materialStatus.toLowerCase() ===
                        filters.value.afterDivorce.toLowerCase())

            const divorcedMatterFilterMatch =
                !filters.value.approveDivorce ||
                !el.divorcedMatter ||
                el.divorcedMatter.toLowerCase() ===
                    filters.value.approveDivorce.toLowerCase()

            const childrenHasFilterMatch =
                !filters.value.haveChilds ||
                !el.childrenHas ||
                el.childrenHas.toLowerCase() ===
                    filters.value.haveChilds.toLowerCase()

            const childrenWantedFilterMatch =
                !filters.value.wantChilds ||
                !el.childrenWanted ||
                el.childrenWanted.toLowerCase() ===
                    filters.value.wantChilds.toLowerCase()

            const income =
                !filters.value.income ||
                !el.income ||
                el.income.toLowerCase() === filters.value.income.toLowerCase()

            const petsAttitude =
                !filters.value.petsAttitude ||
                !el.petsAttitude ||
                el.petsAttitude.toLowerCase() ===
                    filters.value.petsAttitude.toLowerCase()

            const wantedGender =
                !filters.value.wantedGender ||
                (el.wantedGender &&
                    el.wantedGender.includes(filters.value.wantedGender))

            const childrenPartnerHas =
                !filters.value.childrenPartnerHas ||
                (el.childrenPartnerHas &&
                    el.childrenPartnerHas.toLowerCase() ===
                        filters.value.childrenPartnerHas.toLowerCase())

            return (
                cityFilterMatch &&
                ageInRange &&
                growthInRange &&
                movePossibilityFilterMatch &&
                divorcedMatterFilterMatch &&
                childrenHasFilterMatch &&
                childrenWantedFilterMatch &&
                income &&
                isAfterDivorce &&
                petsAttitude &&
                wantedGender &&
                childrenPartnerHas &&
                cityPossibleFilterMatch
            )
        })

        return filteredMatches
    })
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/main.scss';
    @import '@/assets/scss/Nunito.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    .matches-filters {
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: 21px;
            margin-bottom: 20px;
            background-color: #e3d9d0;
            padding: 20px;
        }

        &__title {
            font-size: 20px;
            font-weight: 600;
        }

        &__count {
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 20px;
        }
        &__filters {
            display: inline-flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 30px;

            div {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            input {
                padding: 6spx 20px;
                width: 90px;
                height: 32px;
            }

            select {
                width: 60px;
                height: 32px;
            }

            .select {
                &--cities {
                    width: 120px;
                }
                &--income {
                    width: 320px;
                }
                &--pets--attitude {
                    width: 140px;
                }
                &--wanted-gender {
                    width: 140px;
                }

                &--age-difference {
                    width: 120px;
                }
            }
        }
        &__reset-btn {
            background-color: $pink;
            color: $white;
            padding: 10px 20px;
            cursor: pointer;
            transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
                opacity: 0.9;
            }
        }
    }
</style>
