<template>
    <div class="admin-plus">
        <div v-if="matches.length">
            <div
                v-for="item in matches"
                class="simulated-match"
                :key="item?._id"
                :data-id="item?._id"
            >
                <div class="simulated-match__first">
                    <div class="simulated-match__person">
                        <img
                            class="simulated-match__person__image"
                            :src="item?.user?.photo1"
                            role="presentation"
                        />
                        <div class="simulated-match__person__name-age">
                            {{ item?.userQuestionnaire?.name }},
                            {{ setAge(item?.userQuestionnaire?.age) }}
                        </div>
                        <div class="simulated-match__person__city">
                            {{ item?.userQuestionnaire?.city }}
                        </div>
                    </div>
                    <div class="simulated-match__person-questionnaire">
                        <ul>
                            <li>
                                Różnica wieku:
                                <span>
                                    {{ item?.userQuestionnaire?.ageDifference }}
                                </span>
                            </li>
                            <li>
                                Dzieci:
                                <span>
                                    {{ item?.userQuestionnaire?.childrenHas }}
                                </span>
                            </li>
                            <li>
                                Chce dzieci:
                                <span>
                                    {{
                                        item?.userQuestionnaire?.childrenWanted
                                    }}
                                </span>
                            </li>
                            <li>
                                Wzrost:
                                <span>
                                    {{ item?.userQuestionnaire?.growth }}
                                </span>
                            </li>
                            <li>
                                Czy wzrost ma znaczenie:
                                <span>
                                    {{ item?.userQuestionnaire?.growthMatter }}
                                </span>
                            </li>
                            <li>
                                Zarobki:
                                <span>
                                    {{ item?.userQuestionnaire?.income }}
                                </span>
                            </li>
                            <li>
                                Jestem osobą:
                                <span>
                                    {{ item?.userQuestionnaire?.religion }},
                                    {{
                                        item?.userQuestionnaire
                                            ?.religionPractice
                                    }}
                                </span>
                            </li>
                            <li>
                                Chce aby druga osoba:
                                <span>
                                    {{
                                        item?.userQuestionnaire?.religionMatter
                                    }},
                                    {{
                                        item?.userQuestionnaire
                                            ?.religionPracticeMatter
                                    }}
                                </span>
                            </li>
                            <li>
                                Wybrane miasta do randkowania:
                                <span
                                    v-for="city in item?.userQuestionnaire
                                        ?.cityPossible"
                                >
                                    {{ city }}&nbsp;
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="simulated-match__person-footer">
                        <ul>
                            <li>&nbsp;</li>
                            <li>
                                Czy posiada aktualnie matcha:
                                <span>
                                    {{
                                        item?.user?.hasAlreadyMatch === false
                                            ? '❌'
                                            : '✅'
                                    }}
                                </span>
                            </li>
                            <li>
                                Czy posiada aktualnie temporary matcha:
                                <span>
                                    {{
                                        item?.user?.hasAlreadyTemporaryMatch ===
                                        false
                                            ? '❌'
                                            : '✅'
                                    }}
                                </span>
                            </li>
                            <li>
                                Czy ma na swojej czarnej liście drugą osobę: ({{
                                    item?.secondUserQuestionnaire?.name
                                }})
                                <span>
                                    {{
                                        checkUserBlacklistContainsSecondUserId(
                                            item?.user?.blacklist,
                                            item?.secondUserId
                                        )
                                    }}
                                </span>
                            </li>
                        </ul>
                        <button class="simulated-match__user-profile-btn">
                            <router-link
                                :to="`/admin/plus/verify/${item?.userId}`"
                                target="_blank"
                            >
                                Zobacz profil użytkownika
                            </router-link>
                        </button>
                    </div>
                </div>
                <div class="simulated-match__second">
                    <div class="simulated-match__person">
                        <img
                            class="simulated-match__person__image"
                            :src="item?.secondUser?.photo1"
                            role="presentation"
                        />
                        <div class="simulated-match__person__name-age">
                            {{ item?.secondUserQuestionnaire?.name }},
                            {{ setAge(item?.secondUserQuestionnaire?.age) }}
                        </div>
                        <div class="simulated-match__person__city">
                            {{ item?.secondUserQuestionnaire?.city }}
                        </div>
                    </div>
                    <div class="simulated-match__person-questionnaire">
                        <ul>
                            <li>
                                Różnica wieku:
                                <span>
                                    {{
                                        item?.secondUserQuestionnaire
                                            ?.ageDifference
                                    }}
                                </span>
                            </li>
                            <li>
                                Dzieci:
                                <span>
                                    {{
                                        item?.secondUserQuestionnaire
                                            ?.childrenHas
                                    }}
                                </span>
                            </li>
                            <li>
                                Chce dzieci:
                                <span>
                                    {{
                                        item?.secondUserQuestionnaire
                                            ?.childrenWanted
                                    }}
                                </span>
                            </li>
                            <li>
                                Wzrost:
                                <span>
                                    {{ item?.secondUserQuestionnaire?.growth }}
                                </span>
                            </li>
                            <li>
                                Czy wzrost ma znaczenie:
                                <span>
                                    {{
                                        item?.secondUserQuestionnaire
                                            ?.growthMatter
                                    }}
                                </span>
                            </li>
                            <li>
                                Zarobki:
                                <span>
                                    {{ item?.secondUserQuestionnaire?.income }}
                                </span>
                            </li>
                            <li>
                                Jestem osobą:
                                <span>
                                    {{
                                        item?.secondUserQuestionnaire?.religion
                                    }},
                                    {{
                                        item?.secondUserQuestionnaire
                                            ?.religionPractice
                                    }}
                                </span>
                            </li>
                            <li>
                                Chce aby druga osoba:
                                <span>
                                    {{
                                        item?.secondUserQuestionnaire
                                            ?.religionMatter
                                    }},
                                    {{
                                        item?.secondUserQuestionnaire
                                            ?.religionPracticeMatter
                                    }}
                                </span>
                            </li>
                            <li>
                                Wybrane miasta do randkowania:
                                <span
                                    v-for="city in item?.secondUserQuestionnaire
                                        ?.cityPossible"
                                >
                                    {{ city }}&nbsp;
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="simulated-match__person-footer">
                        <ul>
                            <li>&nbsp;</li>
                            <li>
                                Czy posiada aktualnie matcha:
                                <span>
                                    {{
                                        item?.secondUser?.hasAlreadyMatch ===
                                        false
                                            ? '❌'
                                            : '✅'
                                    }}
                                </span>
                            </li>
                            <li>
                                Czy posiada aktualnie temporary matcha:
                                <span>
                                    {{
                                        item?.secondUser
                                            ?.hasAlreadyTemporaryMatch === false
                                            ? '❌'
                                            : '✅'
                                    }}
                                </span>
                            </li>
                            <li>
                                Czy ma na swojej czarnej liście drugą osobę: ({{
                                    item?.userQuestionnaire?.name
                                }})
                                <span>
                                    {{
                                        checkUserBlacklistContainsSecondUserId(
                                            item?.secondUser?.blacklist,
                                            item?.userId
                                        )
                                    }}
                                </span>
                            </li>
                        </ul>
                        <button class="simulated-match__user-profile-btn">
                            <router-link
                                :to="`/admin/plus/verify/${item?.secondUserId}`"
                                target="_blank"
                            >
                                Zobacz profil użytkownika
                            </router-link>
                        </button>
                    </div>
                </div>
                <div
                    @click="
                        approveTemporaryMatch(
                            item?.userId,
                            item?.secondUserId,
                            item?.userQuestionnaire.name,
                            item?.secondUserQuestionnaire.name,
                            item?._id
                        )
                    "
                    class="simulated-match__action-btn"
                >
                    <div
                        v-if="loading"
                        class="simulated-match__action-btn__loader"
                    >
                        <Loader />
                    </div>
                    <div v-else>✔ Utwórz dopasowanie</div>
                </div>
            </div>
        </div>
        <div v-else-if="matches.length === 0 && !loading">
            <p>Brak danych do wyświetlenia</p>
        </div>
        <div v-if="loading" class="simulated-match__loader">
            <Loader />
        </div>

        <router-link
            v-if="matchesCount == 'loading'"
            class="admin-plus__btn-next"
            :to="`/admin/simulated-matches/${Number($route.params.page) + 1}`"
        >
            Następna strona
        </router-link>
        <div
            v-if="matchesCount === 'loading'"
            class="admin-plus__loader pagination-loader"
        >
            <p>Ładowanie paginacji ...</p>
            <div class="loader"></div>
        </div>
        <div
            v-if="matchesCount > numberOfPeopleDisplayed"
            class="admin-plus__buttons"
        >
            <div class="admin-plus__buttons-box">
                <router-link
                    v-if="$route.params.page != 1"
                    class="admin-plus__btn admin-plus-view__btn--red"
                    :to="`/admin/matches/simulated-matches/${
                        Number($route.params.page) - 1
                    }`"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        color="#000000"
                    >
                        <path
                            d="M15 6L9 12L15 18"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </router-link>
            </div>
            <router-link
                v-if="$route.params.page != 1"
                :to="`/admin/matches/simulated-matches/1`"
            >
                1
            </router-link>
            <p v-if="$route.params.page != 1">...</p>
            <router-link
                v-if="$route.params.page - 2 > 1"
                :to="`/admin/matches/simulated-matches/${
                    Number($route.params.page) - 2
                }`"
            >
                {{ $route.params.page - 2 }}
            </router-link>
            <router-link
                v-if="$route.params.page - 1 > 1"
                :to="`/admin/matches/simulated-matches/${
                    Number($route.params.page) - 1
                }`"
            >
                {{ $route.params.page - 1 }}
            </router-link>
            <p class="current-page">{{ $route.params.page }}</p>
            <router-link
                v-if="
                    parseInt($route.params.page) + 1 <
                    roundUsersPage(matchesCount / numberOfPeopleDisplayed)
                "
                :to="`/admin/matches/simulated-matches/${
                    Number($route.params.page) + 1
                }`"
            >
                {{ parseInt($route.params.page) + 1 }}
            </router-link>
            <router-link
                v-if="
                    parseInt($route.params.page) + 2 <
                    roundUsersPage(matchesCount / numberOfPeopleDisplayed)
                "
                :to="`/admin/matches/simulated-matches/${
                    Number($route.params.page) + 2
                }`"
            >
                {{ parseInt($route.params.page) + 2 }}
            </router-link>
            <p
                v-if="
                    $route.params.page !=
                    roundUsersPage(matchesCount / numberOfPeopleDisplayed)
                "
            >
                ...
            </p>
            <router-link
                v-if="
                    $route.params.page !=
                    roundUsersPage(matchesCount / numberOfPeopleDisplayed)
                "
                :to="`/admin/matches/simulated-matches/${roundUsersPage(
                    matchesCount / numberOfPeopleDisplayed
                )}`"
            >
                {{ roundUsersPage(matchesCount / numberOfPeopleDisplayed) }}
            </router-link>
            <div class="admin-plus__buttons-box">
                <router-link
                    v-if="
                        $route.params.page <
                        roundUsersPage(matchesCount / numberOfPeopleDisplayed)
                    "
                    class="admin-plus__btn"
                    :to="`/admin/matches/simulated-matches/${
                        Number($route.params.page) + 1
                    }`"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        color="#000000"
                    >
                        <path
                            d="M9 6L15 12L9 18"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </router-link>
            </div>
        </div>
        <div
            v-if="matchesCount > numberOfPeopleDisplayed"
            class="admin-plus__page-input"
        >
            <input placeholder="Numer strony" type="text" v-model="pageInput" />
            <button @click="setPage()">Przejdź do strony</button>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import Loader from '@/components/atoms/Loader.vue'
    const API_URL = process.env.VUE_APP_API

    export default {
        data() {
            return {
                matches: null,
                currentFilter: '',
                check: 'all',
                match: '0',
                machesCount: 'loading',
                numberOfPeopleDisplayed: 8,
                pageInput: '',
                loading: true,
                response_message: ''
            }
        },

        components: {
            Loader
        },
        methods: {
            hideRecord(id) {
                const item = document.querySelector(`[data-id="${id}"]`)
                item.classList.add('simulated-match--hide')
            },
            async sendNotification(title, message, toUserId, secondUserId) {
                try {
                    await axios.post(`${API_URL}api/announcement/send`, {
                        title,
                        message,
                        all: false,
                        toUserId,
                        secondUserId
                    })
                    return true
                } catch (err) {
                    console.error(err)
                    return false
                }
            },
            async deleteSimulatedMatch(ID) {
                try {
                    await axios.delete(`${API_URL}api/match/simulated/${ID}`)
                    return true
                } catch (e) {
                    console.log(e)
                    return false
                }
            },
            async approveTemporaryMatch(
                firstUserID,
                secondUserID,
                firstUserName,
                secondUserName,
                simulatedMatchID
            ) {
                this.loading = true

                try {
                    const response = await axios.post(
                        `${API_URL}api/temporarymatch`,
                        {
                            userId: firstUserID,
                            secondUserId: secondUserID,
                            mainUserName: firstUserName,
                            secondUserName: secondUserName,
                            generatedByScheduler: true
                        }
                    )

                    if (response.status === 200) {
                        this.response_message = response.data.message
                    }

                    if (response.status === 201) {
                        await Promise.all([
                            this.sendNotification(
                                `${firstUserName}! Nowa propozycja znajomości!`,
                                `Sprawdź zakładkę „Dopasowanie", mamy dla Ciebie propozycję znajomości.<br/><br/>Liczymy, że Ci się spodoba.<br/>Basia i Zespół Biura Randkowego`,
                                firstUserID,
                                secondUserID
                            ),

                            this.sendNotification(
                                `${secondUserName}, Nowa propozycja znajomości!`,
                                `Sprawdź zakładkę „Dopasowanie", mamy dla Ciebie propozycję znajomości.<br/><br/>Liczymy, że Ci się spodoba.<br/>Basia i Zespół Biura Randkowego`,
                                secondUserID,
                                firstUserID
                            ),
                            this.deleteSimulatedMatch(simulatedMatchID)
                        ])
                    }

                    this.response_message = response.data.message
                    this.loading = false
                    this.hideRecord(simulatedMatchID)
                } catch (err) {
                    this.response_message = err.message
                    this.loading = false
                }
            },
            checkUserBlacklistContainsSecondUserId(
                firstUserBlackList,
                secondUserId
            ) {
                if (!firstUserBlackList) return '❌'
                else {
                    return firstUserBlackList.includes(secondUserId)
                        ? '✅'
                        : '❌'
                }
            },
            setAge(el) {
                const dzisiaj = new Date()
                const dataUrodzeniaDate = new Date(el)

                let wiek =
                    dzisiaj.getFullYear() - dataUrodzeniaDate.getFullYear()

                const miesiacDzisiaj = dzisiaj.getMonth()
                const dzienDzisiaj = dzisiaj.getDate()
                const miesiacUrodzenia = dataUrodzeniaDate.getMonth()
                const dzienUrodzenia = dataUrodzeniaDate.getDate()

                if (
                    miesiacDzisiaj < miesiacUrodzenia ||
                    (miesiacDzisiaj === miesiacUrodzenia &&
                        dzienDzisiaj < dzienUrodzenia)
                ) {
                    wiek--
                }

                return wiek
            },
            setPage() {
                if (this.pageInput != '' && parseInt(this.pageInput)) {
                    this.$router.push(
                        `/admin/matches/simulated-matches/${this.pageInput}`
                    )
                    this.pageInput = ''
                }
            },
            roundUsersPage(num) {
                return Math.ceil(num)
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            async getSimulatedMatches(page) {
                this.matches = []
                this.loading = true
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                await axios
                    .get(`${API_URL}api/match/simulated/${page}`, config)
                    .then(res => {
                        this.matches = res.data?.matches
                        this.matchesCount = res.data?.totalMatches
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.loading = false
                    })
            },

            setFirstPage() {
                this.$router.push(`/admin/matches/simulated-matches/1`)
            }
        },
        created() {
            this.getSimulatedMatches(this.$route.params.page)
        },
        watch: {
            '$route.params.page': function (page) {
                this.getSimulatedMatches(page)
            },

            match: function () {
                this.getSimulatedMatches(this.$route.params.page)
                this.setFirstPage()
            },
            check: function () {
                this.getSimulatedMatches(this.$route.params.page)
                this.setFirstPage()
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    .pagination-loader {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }

    .simulated-match {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        gap: 20px;
        margin-bottom: 20px;
        position: relative;
        box-sizing: border-box;

        @include breakpoint-min('tablet') {
            flex-direction: row;
            padding: 20px;
        }

        &--hide {
            display: none;
        }

        &__loader {
            padding: 100px 0px;
        }

        &__first,
        &__second {
            flex-grow: 1;
            width: 100%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px 20px;
            box-sizing: border-box;
        }

        &__person {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            column-gap: 10px;
            width: 190px;
            padding-bottom: 30px;

            &__image {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
                margin-bottom: 10px;
            }

            &__name-age {
                font-size: 20px;
                font-weight: 700;
            }

            &__age {
                font-size: 16px;
                font-weight: 400;
            }

            &__city {
                font-size: 16px;
                font-weight: 400;
            }
            &-questionnaire,
            &-footer {
                width: 100%;
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex !important;
                    flex-direction: column;
                    gap: 4px !important;
                    li {
                        font-size: 14px;
                        font-weight: 400;
                        padding: 5px 0;
                        border-bottom: 1px solid #dedede;
                        span {
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        &__user-profile-btn {
            margin: 0 auto;
            display: flex;
            margin-top: 40px;
            padding: 10px;
            background-color: $pink;
            color: $black;
            font-size: 1.4rem;
            line-height: 2.2rem;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;

            opacity: 1;
            cursor: pointer;
            &:hover {
                transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                opacity: 0.6;
            }
            a {
                text-decoration: none;
                color: $white;
            }
        }

        &__action-btn {
            position: absolute;
            right: 20px;
            top: 6px;
            margin: 0 auto;
            display: flex;
            padding: 4px 10px;
            background-color: $pink;
            color: $black;
            font-size: 1.2rem;
            line-height: 2rem;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            opacity: 1;
            text-decoration: none;
            color: $white;
            cursor: pointer;
            width: 180px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                opacity: 0.6;
            }
            &::selection {
                background-color: transparent;
            }

            &__loader {
                transform: scale(0.5);
            }
        }
    }

    .admin-plus {
        padding-bottom: 80px;
        padding-right: 120px;
        @include breakpoint-max('v-large') {
            padding-right: 30px;
        }
        @include breakpoint-max('xs-tablet') {
            padding-left: 30px;
        }
        &__list {
            margin-bottom: 160px;
        }
        &__btn-next {
            display: block;
            width: fit-content;
            margin: 0 auto;
            color: $black;
            font-size: 1.6rem;
            line-height: 2.2rem;
            font-weight: 700;
            padding: 10px;
            text-decoration: none;
            background-color: $bege3;
        }
        &__buttons {
            margin-bottom: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            p,
            a {
                color: $black;
                font-size: 2rem;
                line-height: 2.2rem;
                font-weight: 700;
                padding: 10px;
            }
            a {
                text-decoration: none;
                transition: 0.3s;
                &:hover {
                    color: $pink;
                }
            }
            .current-page {
                color: $pink;
            }
            @include breakpoint-max('mobile') {
                column-gap: 15px;
            }
        }
        &__btn {
            display: block;
            padding: 10px !important;
            background-color: #e1dbd5;
            color: $black;
            font-size: 1.4rem;
            line-height: 2.2rem;
            text-transform: uppercase;
            font-weight: 700;
            text-decoration: none;
            transition: 0.3s;
            svg {
                display: block;
                path {
                    transition: 0.3s;
                }
            }
            &:hover {
                background-color: $pink;
                color: $white;
                svg {
                    path {
                        stroke: $white;
                    }
                }
            }
            @include breakpoint-max('mobile') {
                font-size: 1rem;
                line-height: 1.5rem;
                padding: 6px 8px !important;
            }
        }
        &__buttons-box {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            a {
                text-align: center;
            }
        }
        &__filter {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            gap: 35px;
            flex-wrap: wrap;
            @include breakpoint-max('xs-tablet') {
                gap: 20px;
            }
        }
        &__page-input {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 20px;
            input {
                background-color: transparent;
                outline: none;
                border: 1px solid $black;
                padding: 8px 0;
                color: $black;
                font-size: 2rem;
                line-height: 2.2rem;
                font-weight: 700;
                width: 160px;
                text-align: center;
            }
            button {
                padding: 10px;
                background-color: $bege3;
                color: $black;
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-weight: 700;
                cursor: pointer;
            }
        }
        &__filter-item {
            input {
                display: none;
            }
            label {
                cursor: pointer;
                color: $black;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-transform: uppercase;
                padding: 4px 6px;
                transform: 0.3s;
            }
            .selected {
                background-color: $pink;
                color: $white;
            }
        }
        ul {
            width: 100%;
            max-width: none !important;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 110px 69px;
            @include breakpoint-max('v-large') {
                gap: 80px 39px;
            }
            @include breakpoint-max('large') {
                grid-template-columns: repeat(3, 1fr);
            }
            @include breakpoint-max('xs-tablet') {
                gap: 45px 15px;
            }
            @include breakpoint-max('s-mobile') {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        &__loader {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                color: $black;
                font-size: 1.4rem;
                line-height: 2.2rem;
                text-transform: uppercase;
                font-weight: 700;
                padding: 50px 0;
            }
        }
    }
    .loader {
        margin: 80px 0;
        width: 50px;
        padding: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #f2545b;
        --_m: conic-gradient(#0000 10%, #000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: l3 1s infinite linear;
    }

    .loader {
        margin: 80px 0;
        width: 50px;
        padding: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #f2545b;
        --_m: conic-gradient(#0000 10%, #000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: l3 1s infinite linear;
    }
    @keyframes l3 {
        to {
            transform: rotate(1turn);
        }
    }
</style>
