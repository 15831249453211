<template>
    <div class="question">
        <!-- <pre>
        <code>
        {{ question }}
        </code>
      </pre> -->

        <div class="question__single">
            <span v-html="question.text"></span>
            <p v-if="optionalInfo">(Opcjonalnie)</p>
            <p v-if="required">(To pytanie jest wymagane)</p>
        </div>

        <div v-if="showPopup" class="question__about-pop">
            <div @click="showPopup = false" class="question__about-pop-close">
                zamknij
            </div>
            <h3>Ważna informacja</h3>
            <div class="question__delete-profile-pop-box">
                <div class="question__delete-profile-pop-wrap">
                    <p class="question__delete-profile-error">
                        Przykro mi, ale obecnie zajmujemy się umawianiem na
                        randki w Warszawie i Poznaniu. Poinformujemy Cię, gdy
                        zaczniemy działać w innym, wybranym przez Ciebie
                        mieście.
                    </p>
                    <router-link
                        class="question__button"
                        to="panel/notification"
                    >
                        Rozumiem
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="error" class="error">{{ error }}</div>
        <div class="question__answers" v-if="question.type == 'simple'">
            <div
                class="question__simple-question"
                v-for="(answer, index) in question.answers"
                :key="index"
            >
                <div v-if="question.isMultiSelect">
                    <input
                        type="checkbox"
                        :value="answer"
                        v-model="checkedAnswers"
                        :id="'checkbox-' + index"
                    />
                    <label :for="'checkbox-' + index">{{ answer }}</label>
                </div>
                <div v-else>
                    <input
                        type="radio"
                        :value="answer"
                        v-model="simpleRadio"
                        :id="'radio-' + index"
                    />
                    <label :for="'radio-' + index">{{ answer }}</label>
                </div>
            </div>
            <div
                class="question__button-wrap"
                :class="{ 'question__button-wrap-single': !currentIndex }"
            >
                <button
                    class="question__button-submit question__simple-button"
                    @click="prev()"
                    v-if="currentIndex != 0"
                >
                    Wstecz
                </button>
                <button
                    class="question__button-submit question__simple-button"
                    :class="{ activeButton: isAnyFilled }"
                    :disabled="required ? !isAnyFilled : false"
                    @click="submit(simpleRadio)"
                >
                    Dalej
                </button>
            </div>
        </div>

        <div
            class="question__answers question__answers-numbers"
            v-if="question.type == 'number'"
        >
            <input
                type="number"
                v-if="question.placeholder"
                :placeholder="question.placeholder"
                v-model="number"
                :min="question.answers[0]"
                :max="question.answers[1]"
            />
            <input
                type="number"
                v-else
                v-model="number"
                :min="question.answers[0]"
                :max="question.answers[1]"
            />
            <div class="question__button-wrap">
                <button
                    class="question__button-submit question__simple-button"
                    @click="prev()"
                >
                    Wstecz
                </button>
                <button
                    class="question__button-submit question__simple-button"
                    :class="{ activeButton: isAnyFilled }"
                    :disabled="required ? !isAnyFilled : false"
                    @click="submit(number)"
                >
                    Dalej
                </button>
            </div>
        </div>

        <div
            class="question__answers question__answers-date question__answers-numbers"
            v-if="question.type == 'date'"
        >
            <div>
                <input
                    type="date"
                    v-model="date"
                    min="1940-04-01"
                    max="2030-01-01"
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                >
                    <defs>
                        <clipPath id="clip-path">
                            <rect
                                id="Rectangle_135"
                                data-name="Rectangle 135"
                                width="29.708"
                                height="30.358"
                                fill="#fff"
                            />
                        </clipPath>
                    </defs>
                    <g
                        id="Group_231"
                        data-name="Group 231"
                        transform="translate(-1096 -503)"
                    >
                        <circle
                            id="Ellipse_67"
                            data-name="Ellipse 67"
                            cx="25.5"
                            cy="25.5"
                            r="25.5"
                            transform="translate(1096 503)"
                            fill="#d13724"
                        />
                        <g
                            id="Group_229"
                            data-name="Group 229"
                            transform="translate(1107 513.321)"
                        >
                            <g id="Group_228" data-name="Group 228">
                                <path
                                    id="Path_215"
                                    data-name="Path 215"
                                    d="M26.167,37.251H3.54A3.6,3.6,0,0,1,0,33.6V12.805A3.6,3.6,0,0,1,3.54,9.151H5.077a.37.37,0,0,1,0,.74H3.54a2.862,2.862,0,0,0-2.8,2.914V33.6a2.862,2.862,0,0,0,2.8,2.914H26.167a2.862,2.862,0,0,0,2.8-2.914V12.805a2.862,2.862,0,0,0-2.8-2.914H24.631a.37.37,0,0,1,0-.74h1.536a3.6,3.6,0,0,1,3.541,3.654V33.6a3.6,3.6,0,0,1-3.541,3.655"
                                    transform="translate(0 -6.892)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_216"
                                    data-name="Path 216"
                                    d="M42.711,9.891H40.868a.37.37,0,0,1,0-.74h1.843a.37.37,0,0,1,0,.74"
                                    transform="translate(-30.503 -6.892)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_217"
                                    data-name="Path 217"
                                    d="M71.608,9.891H69.762a.37.37,0,0,1,0-.74h1.846a.37.37,0,0,1,0,.74"
                                    transform="translate(-52.266 -6.892)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_218"
                                    data-name="Path 218"
                                    d="M59.052,5.256a.37.37,0,0,1-.37-.37V.37a.37.37,0,0,1,.74,0V4.886a.37.37,0,0,1-.37.37"
                                    transform="translate(-44.199)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_219"
                                    data-name="Path 219"
                                    d="M30.154,5.256a.37.37,0,0,1-.37-.37V.37a.37.37,0,0,1,.74,0V4.886a.37.37,0,0,1-.37.37"
                                    transform="translate(-22.433)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_220"
                                    data-name="Path 220"
                                    d="M87.95,5.256a.37.37,0,0,1-.37-.37V.37a.37.37,0,1,1,.74,0V4.886a.37.37,0,0,1-.37.37"
                                    transform="translate(-65.965)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_221"
                                    data-name="Path 221"
                                    d="M29.337,34.914H.37a.37.37,0,0,1,0-.74H29.337a.37.37,0,0,1,0,.74"
                                    transform="translate(0 -25.74)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_222"
                                    data-name="Path 222"
                                    d="M42.863,51.189H39.925a.37.37,0,0,1,0-.74h2.938a.37.37,0,0,1,0,.74"
                                    transform="translate(-29.793 -37.998)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_223"
                                    data-name="Path 223"
                                    d="M68.985,51.189H66.28a.37.37,0,0,1,0-.74h2.705a.37.37,0,0,1,0,.74"
                                    transform="translate(-49.643 -37.998)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_224"
                                    data-name="Path 224"
                                    d="M94.4,51.189h-2.7a.37.37,0,0,1,0-.74h2.7a.37.37,0,0,1,0,.74"
                                    transform="translate(-68.784 -37.998)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_225"
                                    data-name="Path 225"
                                    d="M18.16,68.947H15.455a.37.37,0,1,1,0-.74H18.16a.37.37,0,1,1,0,.74"
                                    transform="translate(-11.362 -51.373)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_226"
                                    data-name="Path 226"
                                    d="M43.573,68.947H40.868a.37.37,0,0,1,0-.74h2.705a.37.37,0,1,1,0,.74"
                                    transform="translate(-30.503 -51.373)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_227"
                                    data-name="Path 227"
                                    d="M68.985,68.947H66.28a.37.37,0,0,1,0-.74h2.705a.37.37,0,0,1,0,.74"
                                    transform="translate(-49.643 -51.373)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_228"
                                    data-name="Path 228"
                                    d="M94.4,68.947h-2.7a.37.37,0,0,1,0-.74h2.7a.37.37,0,0,1,0,.74"
                                    transform="translate(-68.784 -51.373)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_229"
                                    data-name="Path 229"
                                    d="M18.16,86.7H15.455a.37.37,0,0,1,0-.74H18.16a.37.37,0,0,1,0,.74"
                                    transform="translate(-11.362 -64.747)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_230"
                                    data-name="Path 230"
                                    d="M43.573,86.7H40.868a.37.37,0,0,1,0-.74h2.705a.37.37,0,1,1,0,.74"
                                    transform="translate(-30.503 -64.747)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_231"
                                    data-name="Path 231"
                                    d="M68.985,86.7H66.28a.37.37,0,0,1,0-.74h2.705a.37.37,0,0,1,0,.74"
                                    transform="translate(-49.643 -64.747)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_232"
                                    data-name="Path 232"
                                    d="M94.4,86.7h-2.7a.37.37,0,0,1,0-.74h2.7a.37.37,0,0,1,0,.74"
                                    transform="translate(-68.784 -64.747)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_233"
                                    data-name="Path 233"
                                    d="M18.16,104.462H15.455a.37.37,0,0,1,0-.74H18.16a.37.37,0,0,1,0,.74"
                                    transform="translate(-11.362 -78.123)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_234"
                                    data-name="Path 234"
                                    d="M43.573,104.462H40.868a.37.37,0,0,1,0-.74h2.705a.37.37,0,1,1,0,.74"
                                    transform="translate(-30.503 -78.123)"
                                    fill="#fff"
                                />
                                <path
                                    id="Path_235"
                                    data-name="Path 235"
                                    d="M68.985,104.462H66.28a.37.37,0,0,1,0-.74h2.705a.37.37,0,0,1,0,.74"
                                    transform="translate(-49.643 -78.123)"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="question__button-wrap">
                <button
                    class="question__button-submit question__simple-button"
                    @click="prev()"
                >
                    Wstecz
                </button>
                <button
                    class="question__button-submit question__simple-button"
                    :class="{ activeButton: isAnyFilled }"
                    :disabled="required ? !isAnyFilled : false"
                    @click="submit(date, true)"
                >
                    Dalej
                </button>
            </div>
        </div>
        <!-- text -->
        <div
            class="question__answers question__answers-text test"
            v-if="question.type == 'text'"
        >
            <input
                v-if="!question.isLimit"
                type="text"
                v-model="text"
                :placeholder="question.placeholder"
            />
            <textarea
                v-model="text"
                :placeholder="question.placeholder"
                :maxlength="question.maxLength"
                v-else
            ></textarea>
            <small v-if="question.maxLength">
                limit znaków w odpowiedzi:
                <span v-if="text.length > 1">{{ text.length }} /</span>
                {{ question.maxLength }}
            </small>
            <div class="question__button-wrap">
                <button
                    class="question__button-submit question__simple-button"
                    @click="prev()"
                >
                    Wstecz
                </button>
                <!-- <button
                    v-if="answer && required"
                    class="question__button-submit question__simple-button activeButton"
                    @click="submit(text)"
                >Dalej</button> -->
                <button
                    class="question__button-submit question__simple-button"
                    :class="{
                        activeButton: isAnyFilled,
                        activeButtonReq: !required
                    }"
                    :disabled="required ? !isAnyFilled : false"
                    @click="submit(text)"
                >
                    Dalej
                </button>
            </div>
        </div>
        <!-- multiple -->
        <div class="question__answers" v-if="question.type == 'multiple'">
            <div class="question__new-checks-box">
                <div class="question__new-checks">
                    <span>Zaznacz poniższe pola</span>
                    <div class="question__new-checks-wrap">
                        <div
                            class="question__checkbox-wrapper"
                            v-for="(answer, index) in question.answers"
                            :key="index"
                        >
                            <input
                                type="checkbox"
                                :id="'checkbox-' + index"
                                :value="answer"
                                v-model="checkboxes"
                                :disabled="
                                    checkboxes.length >= question.maxLength &&
                                    !checkboxes.includes(answer)
                                "
                            />
                            <label for="coding">{{ answer }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="question__button-wrap">
                <button
                    class="question__button-submit question__simple-button"
                    @click="prev()"
                >
                    Wstecz
                </button>
                <button
                    class="question__button-submit question__simple-button"
                    :class="{ activeButton: isAnyFilled }"
                    :disabled="required ? !isAnyFilled : false"
                    @click="submit(checkboxes)"
                >
                    Dalej
                </button>
            </div>
        </div>
        <!-- fill -->
        <div class="question__answers" v-if="question.type == 'fill'">
            <div class="question__answer-fill-wrap">
                <div
                    v-for="(answer, index) in question.answers"
                    :class="[
                        {
                            question__checked: question.checkedAnswer === index,
                            activeButton: answer.fill && answer.showTextarea,
                            activeButtonNo: !answer.fill && fillBgc
                        },
                        'question__answer-fill question__simple-button'
                    ]"
                    @click="checkFill(answer, question.answers)"
                >
                    <span>{{ answer.text }}</span>
                    <!-- span as button -->
                    <textarea
                        :placeholder="question.placeholder"
                        v-if="answer.fill && answer.showTextarea"
                        v-model="textareas[index]"
                        @click.stop
                        :maxlength="question.maxLength"
                    ></textarea>
                </div>
                <small v-if="question.maxLength">
                    limit znaków w odpowiedzi:
                    <span v-if="text.length > 1">{{ text.length }} /</span>
                    {{ question.maxLength }}
                </small>
                <div class="question__button-wrap">
                    <button
                        class="question__button-submit question__simple-button"
                        @click="prev()"
                    >
                        Wstecz
                    </button>
                    <button
                        class="question__button-submit question__simple-button"
                        :class="{ activeButton: isAnyFilled }"
                        :disabled="required ? !isAnyFilled : false"
                        @click="
                            isNoSelected
                                ? submit('false', true)
                                : submit(textareas)
                        "
                    >
                        Dalej
                    </button>
                </div>
            </div>
        </div>
        <!-- list -->
        <div
            class="question__answers question__answers-text question__answers-list"
            v-if="question.type == 'list'"
        >
            <transition-group name="fade" tag="div">
                <input
                    v-for="(answer, index) in list"
                    :key="index"
                    :placeholder="question.placeholder"
                    maxlength="100"
                    type="text"
                    v-model="list[index]"
                />
            </transition-group>
            <div class="question__button-wrap">
                <button
                    class="question__button-submit question__simple-button"
                    @click="prev()"
                >
                    Wstecz
                </button>
                <button
                    class="question__button-submit question__simple-button"
                    :class="{
                        activeButton: isAnyFilled,
                        activeButtonNo: question.optional
                    }"
                    :disabled="required ? !isAnyFilled : false"
                    @click="submit(list)"
                >
                    Dalej
                </button>
            </div>
        </div>
        <!-- bundle -->
        <div class="question__answers" v-if="question.type == 'photos'">
            <div class="question__slider">
                <div>
                    <img
                        :src="imageSrc1"
                        @click="triggerFileInput1"
                        class="question__main-image"
                    />
                    <button
                        class="question__simple-button"
                        @click="triggerFileInput1"
                    >
                        <span v-if="selectedFile1 == ''">dodaj</span>
                        <span v-if="selectedFile1 !== ''">zmień</span>
                    </button>
                </div>
                <div class="images-box">
                    <img
                        :src="imageSrc2"
                        @click="triggerFileInput2"
                        class="question__main-image"
                    />
                    <p class="image-delete" @click="singleImageDelete(2)">
                        Usuń
                    </p>
                    <button
                        class="question__simple-button"
                        @click="triggerFileInput2"
                    >
                        <span v-if="selectedFile2 == ''">dodaj</span>
                        <span v-if="selectedFile2 !== ''">zmień</span>
                    </button>
                </div>
                <div class="images-box">
                    <img
                        :src="imageSrc3"
                        @click="triggerFileInput3"
                        class="question__main-image"
                    />
                    <p class="image-delete" @click="singleImageDelete(3)">
                        Usuń
                    </p>
                    <button
                        class="question__simple-button"
                        @click="triggerFileInput3"
                    >
                        <span v-if="selectedFile3 == ''">dodaj</span>
                        <span v-if="selectedFile3 !== ''">zmień</span>
                    </button>
                </div>
                <div class="images-box">
                    <img
                        :src="imageSrc4"
                        @click="triggerFileInput4"
                        class="question__main-image"
                    />
                    <p class="image-delete" @click="singleImageDelete(4)">
                        Usuń
                    </p>
                    <button
                        class="question__simple-button"
                        @click="triggerFileInput4"
                    >
                        <span v-if="selectedFile4 == ''">dodaj</span>
                        <span v-if="selectedFile4 !== ''">zmień</span>
                    </button>
                </div>
            </div>

            <div
                v-show="!imageValidation"
                class="question__answers__image-validation-text"
            >
                {{ imageValidationText }}
            </div>

            <form @submit.prevent="editProfile()">
                <input
                    class="question__file"
                    type="file"
                    ref="fileInput1"
                    @change="onFileChange1"
                />
                <input
                    class="question__file"
                    type="file"
                    ref="fileInput2"
                    @change="onFileChange2"
                />
                <input
                    class="question__file"
                    type="file"
                    ref="fileInput3"
                    @change="onFileChange3"
                />
                <input
                    class="question__file"
                    type="file"
                    ref="fileInput4"
                    @change="onFileChange4"
                />

                <div class="question-view__inputs-button">
                    <input
                        :disabled="!this.imageValidation"
                        type="submit"
                        value="Zapisz i przejdź dalej"
                    />
                </div>
                <button
                    class="question__button-submit question__simple-button question__simple-button--center"
                    @click="prev()"
                >
                    Wstecz
                </button>
            </form>
        </div>
        <div
            class="question__answers question__answers-text question__bundle"
            :class="{
                'question__bundle-multiform':
                    question.type == 'bundle' &&
                    question.innerQuestions.length > 4
            }"
            v-if="question.type == 'bundle'"
        >
            <div class="question__bundle-box">
                <div
                    v-for="(answer, index) in question.innerQuestions"
                    :key="index"
                >
                    <span class="question__bundle-box-title-span">
                        {{ answer.text }}
                    </span>
                    <div class="question__bundle-multiform-wrap">
                        <span class="question__bundle-multiform-number">
                            {{ (index + 1).toString().padStart(2, '0') }}
                        </span>
                        <div>
                            <label for="question__bundle-input">
                                {{ answer.text }}:
                            </label>
                            <input
                                id="question__bundle-input"
                                maxlength="50"
                                :placeholder="
                                    question.innerQuestions.length > 4
                                        ? 'wpisz dokończenia zdania'
                                        : ''
                                "
                                type="text"
                                v-model="bundleAnswers[index]"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="question__button-wrap">
                <button
                    class="question__button-submit question__simple-button"
                    @click="prev()"
                >
                    Wstecz
                </button>
                <button
                    class="question__button-submit question__simple-button"
                    :class="{ activeButton: isAnyFilled }"
                    :disabled="required ? !isAnyFilled : false"
                    @click="submit(bundleAnswers, question.optional)"
                >
                    Dalej
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { Thumbs } from 'swiper/modules'
    import { toHandlers } from 'vue'

    export default {
        data() {
            const startItemsNum =
                this.question &&
                this.question.listInit &&
                this.question.listInit.startItemsNum
                    ? this.question.listInit.startItemsNum
                    : 0
            return {
                fillBgc: false,
                checked: false,
                number: null,
                text: '',
                checkboxes: [],
                simpleRadio: [],
                textareas: [],
                list: Array(startItemsNum).fill(''),
                error: null,
                bundleAnswers: [],
                date: null,
                isNoSelected: false,
                selectedFile1: '',
                selectedFile2: '',
                selectedFile3: '',
                selectedFile4: '',
                imageSrc1: require('@/assets/images/ludzik1.png'),
                imageSrc2: require('@/assets/images/ludzik2.png'),
                imageSrc3: require('@/assets/images/ludzik1.png'),
                imageSrc4: require('@/assets/images/ludzik2.png'),
                user: null,
                checkedAnswers: [],
                showPopup: false,
                imageValidation: true,
                imageValidationText: ''
            }
        },
        props: {
            question: Object,
            currentIndex: Number,
            backClicked: Boolean,
            required: {
                type: Boolean,
                default: false
            },
            answer: Object,
            optionalInfo: Boolean
        },
        computed: {
            isAnyFilled() {
                switch (this.question.type) {
                    case 'list':
                        return this.list.some(answer => {
                            if (typeof answer === 'string') {
                                return answer.trim() !== ''
                            } else {
                                return false
                            }
                        })
                    case 'number':
                        return this.number !== null
                    case 'simple':
                        if (this.question.isMultiSelect) {
                            // Sprawdzamy, czy jakakolwiek odpowiedź różna od "0" i "1" jest zaznaczona
                            return this.checkedAnswers.some(
                                answer => answer !== '0' && answer !== '1'
                            )
                        } else {
                            if (this.answer && this.answer[0] != null) {
                                return true
                            } else if (this.checkedAnswers.length > 0) {
                                return true
                            } else if (this.simpleRadio) {
                                return this.simpleRadio.length > 0
                            } else {
                                return false
                            }
                        }
                    case 'bundle':
                        return this.bundleCheck()
                    case 'fill':
                        if (this.answer && this.answer[0] != null) {
                            return true
                        } else {
                            return this.textareas != '' || this.isNoSelected
                        }
                    case 'multiple':
                        return (
                            this.checkboxes.length > this.question.minLength - 1
                        )
                    case 'date':
                        return this.date !== null
                    case 'text':
                        if (this.text && this.text.length > 0) {
                            if (this.text != '' && this.text != ' ') {
                                return true
                            }
                            return false
                        } else {
                            return (
                                typeof this.text === 'string' &&
                                this.text.trim() !== ''
                            )
                        }
                    default:
                        break
                }
            }
        },
        methods: {
            singleImageDelete(photoNum) {
                switch (photoNum) {
                    case 2:
                        this.deletePhoto(2)
                        this.imageSrc2 = require('@/assets/images/ludzik2.png')
                        this.selectedFile2 = ''
                        break
                    case 3:
                        this.deletePhoto(3)
                        this.imageSrc3 = require('@/assets/images/ludzik1.png')
                        this.selectedFile3 = ''
                        break
                    case 4:
                        this.deletePhoto(4)
                        this.imageSrc4 = require('@/assets/images/ludzik2.png')
                        this.selectedFile4 = ''
                        break
                }
            },
            async deletePhoto(photoIndex) {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                this.infoPopup = ''
                const userId = localStorage.getItem('userId')
                try {
                    const response = await axios.delete(
                        `${this.url}api/edit-user/photosdel/${photoIndex}/${userId}`,
                        config
                    )
                    this.infoPopup = 'Zdjęcie usunięte pomyślnie.'
                } catch (error) {
                    this.infoPopup = 'Wystąpił problem.'
                }
            },
            checkFill(answer, allAnswers) {
                if (!answer.fill) {
                    allAnswers.forEach(el => {
                        el.showTextarea = false
                    })
                    if (allAnswers[0]) {
                        this.fillBgc = true
                    }
                } else {
                    this.fillBgc = false
                }

                answer.fill
                    ? ((answer.showTextarea = true),
                      (this.isNoSelected = false))
                    : ((answer.showTextarea = false),
                      (this.isNoSelected = true))
            },
            bundleCheck() {
                if (!this.required) {
                    return true
                } else {
                    let counter = 0
                    for (
                        let i = 0;
                        i < this.question.innerQuestions.length;
                        i++
                    ) {
                        !this.bundleAnswers[i] == '' ||
                        !this.bundleAnswers[i] == undefined
                            ? counter++
                            : ''
                    }
                    if (counter == this.question.innerQuestions.length) {
                        return true
                    } else {
                        return false
                    }
                }
            },
            validationQuestions() {},
            triggerFileInput1() {
                this.$refs.fileInput1.click()
            },
            triggerFileInput2() {
                this.$refs.fileInput2.click()
            },
            triggerFileInput3() {
                if (this.selectedFile1 && this.selectedFile2) {
                    this.error = ''
                    this.$refs.fileInput3.click()
                } else {
                    this.error = 'Uzupełnij najpierw dwa pierwsze zdjęcia.'
                }
            },
            triggerFileInput4() {
                if (this.selectedFile1 && this.selectedFile2) {
                    this.error = ''
                    this.$refs.fileInput4.click()
                } else {
                    this.error = 'Uzupełnij najpierw dwa pierwsze zdjęcia.'
                }
            },
            checkImagesFormat(imageType) {
                this.error = ''
                if (imageType == 'image/png' || imageType == 'image/jpeg') {
                    return true
                } else {
                    this.error = 'Poprawny format zdjęcia to .jpg lub .png'
                    return false
                }
            },
            validateFileSize(file) {
                const maxSize = 1024 * 1024 * 1
                if (file.size > maxSize) {
                    this.imageValidationText =
                        'Zdjęcie jest za duże. Maksymalny rozmiar zdjęcia to 1MB.'
                    this.imageValidation = false
                    return false
                } else {
                    this.imageValidationText = ''
                    this.imageValidation = true
                    return true
                }
            },
            onFileChange1(event) {
                this.validateFileSize(event.target.files[0])

                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                if (
                    event.target.files.length > 0 &&
                    this.validateFileSize(event.target.files[0])
                ) {
                    this.selectedFile1 = event.target.files[0]
                    this.imageSrc1 = URL.createObjectURL(this.selectedFile1)
                } else {
                    // Resetuj do domyślnego obrazu, jeśli nie wybrano żadnego pliku
                    this.imageSrc1 = require('@/assets/images/ludzik1.png')
                    this.selectedFile1 = '' // Dodatkowo resetuj stan selectedFile1
                }
            },
            onFileChange2(event) {
                this.validateFileSize(event.target.files[0])

                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                // Analogicznie dla pozostałych metod onFileChange
                if (
                    event.target.files.length > 0 &&
                    this.validateFileSize(event.target.files[0])
                ) {
                    // console.log('File 2 changed:', event.target.files[0]);
                    this.selectedFile2 = event.target.files[0]
                    this.imageSrc2 = URL.createObjectURL(this.selectedFile2)
                } else {
                    this.imageSrc2 = require('@/assets/images/ludzik2.png')
                    this.selectedFile2 = ''
                }
            },
            onFileChange3(event) {
                this.validateFileSize(event.target.files[0])
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                // I tak dalej dla onFileChange3 i onFileChange4
                if (
                    event.target.files.length > 0 &&
                    this.validateFileSize(event.target.files[0])
                ) {
                    // console.log('File 3 changed:', event.target.files[0]);
                    this.selectedFile3 = event.target.files[0]
                    this.imageSrc3 = URL.createObjectURL(this.selectedFile3)
                } else {
                    this.imageSrc3 = require('@/assets/images/ludzik1.png')
                    this.selectedFile3 = ''
                }
            },
            onFileChange4(event) {
                this.validateFileSize(event.target.files[0])
                if (!this.checkImagesFormat(event.target.files[0].type)) {
                    return
                }
                if (
                    event.target.files.length > 0 &&
                    this.validateFileSize(event.target.files[0])
                ) {
                    // console.log('File 4 changed:', event.target.files[0]);
                    this.selectedFile4 = event.target.files[0]
                    this.imageSrc4 = URL.createObjectURL(this.selectedFile4)
                } else {
                    this.imageSrc4 = require('@/assets/images/ludzik2.png')
                    this.selectedFile4 = ''
                }
            },
            filesChange() {
                if (!fileList.length) return

                for (var i = 0; i < fileList.length; i++) {
                    let file = fileList[i]
                    formData.append('files[' + i + ']', file)
                }
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            editProfile() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                const formData = new FormData()
                this.loading = true

                if (this.selectedFile1) {
                    formData.append('file1', this.selectedFile1)
                }
                if (this.selectedFile2) {
                    formData.append('file2', this.selectedFile2)
                }
                if (this.selectedFile3) {
                    formData.append('file3', this.selectedFile3)
                }
                if (this.selectedFile4) {
                    formData.append('file4', this.selectedFile4)
                }

                const userId = localStorage.getItem('userId')

                if (userId) {
                    formData.append('userid', userId)
                }

                // if ((!formData.get('file1') || !formData.get('file2')) && (!this.imageSrc1.startsWith('https://biuro-basia.s3.amazonaws.com/') && !this.imageSrc2.startsWith('https://biuro-basia.s3.amazonaws.com/'))) {
                //     this.error = 'Uzupełnij dwa pierwsze zdjęcia.'
                //     return;
                // } else { this.error = '' }

                axios
                    .post(
                        `${this.url}api/edit-user/photos`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        },
                        config
                    )
                    .then(response => {
                        this.loading = false
                        setTimeout(() => {
                            this.$emit('answer-selected')
                        }, 500)
                    })
                    .catch(error => {
                        this.error = 'Nie dodano zdjęć'
                        this.error = error.response.data
                        console.log(error)
                    })
            },
            getProfile() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(
                        `${this.url}api/edit-user/` +
                            localStorage.getItem('userId'),
                        config
                    )
                    .then(response => {
                        this.user = response.data
                        console.log('user', response.data.user.photo1)
                        if (response.data.user.photo1) {
                            this.imageSrc1 = response.data.user.photo1
                        }
                        if (response.data.user.photo2) {
                            this.imageSrc2 = response.data.user.photo2
                        }
                        if (response.data.user.photo3) {
                            this.imageSrc3 = response.data.user.photo3
                        }
                        if (response.data.user.photo4) {
                            this.imageSrc4 = response.data.user.photo4
                        }

                        // console.log(response);
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            prev() {
                this.$emit('answer-prev')
            },
            submit(allAnswers, index) {
                let question = JSON.parse(JSON.stringify(this.question))

                switch (question.type) {
                    case 'bundle':
                        let ans = JSON.parse(JSON.stringify(allAnswers))
                        let isValid = true

                        if (
                            !this.question.text.includes(
                                'Twoje social media'
                            ) &&
                            !this.question.text.includes(
                                'Dokończ poniższe zdania'
                            )
                        ) {
                            for (let key in ans) {
                                if (
                                    !/^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/.test(
                                        ans[key]
                                    )
                                ) {
                                    isValid = false
                                    break
                                }
                            }
                        }

                        if (
                            this.question.text.includes(
                                'Dokończ poniższe zdania'
                            )
                        ) {
                            for (let key in ans) {
                                if (/\d/.test(ans[key])) {
                                    isValid = false
                                    break
                                }
                            }
                            if (!isValid) {
                                this.error = 'Pola nie mogą zawierać cyfr'
                            } else {
                                this.error = ''
                                setTimeout(() => {
                                    this.$emit('answer-selected', ans)
                                }, 500)
                            }
                        } else {
                            if (!isValid) {
                                this.error =
                                    'Pola nie mogą zawierać cyfr oraz znaków specjalnych'
                            } else {
                                this.error = ''
                                setTimeout(() => {
                                    this.$emit('answer-selected', ans)
                                }, 500)
                            }
                        }

                        break
                    case 'date':
                        let ansDate = JSON.parse(JSON.stringify(allAnswers))
                        const userAge =
                            new Date().getFullYear() -
                            new Date(ansDate).getFullYear()

                        if (userAge >= 18 && userAge <= 100) {
                            this.error = ''
                            setTimeout(() => {
                                this.$emit('answer-selected', ansDate)
                            }, 500)
                        } else {
                            this.error =
                                'Twój wiek musi być w przedziale od 18 do 100 lat'
                        }

                        break
                    case 'simple':
                        let ansSimple = JSON.parse(JSON.stringify(allAnswers))
                        if (this.answer) {
                            if (ansSimple[0] == this.answer[0]) {
                                ansSimple = this.answer[0]
                            }
                        }

                        if (this.currentIndex == 0) {
                            if (this.simpleRadio == 'Nie') {
                                this.showPopup = true
                                return
                            } else {
                                setTimeout(() => {
                                    this.$emit('answer-selected', [ansSimple])
                                }, 500)
                                return
                            }
                        }

                        if (this.question.isMultiSelect) {
                            setTimeout(() => {
                                if (
                                    typeof this.question.checkedAnswer ==
                                    'array'
                                ) {
                                    this.$emit('answer-selected', [
                                        this.question.checkedAnswer[0]
                                    ])
                                } else {
                                    if (this.question.isMultiSelect) {
                                        console.log(
                                            'checked',
                                            this.checkedAnswers
                                        )
                                        this.$emit(
                                            'answer-selected',
                                            this.checkedAnswers
                                        )
                                    } else {
                                        this.$emit('answer-selected', [
                                            this.question.checkedAnswer
                                        ])
                                    }
                                }
                            }, 500)
                        } else {
                            console.log('test222222')
                            setTimeout(() => {
                                this.$emit('answer-selected', [ansSimple])
                            }, 500)
                        }

                        break
                    case 'multiple':
                        let ansMultiple = JSON.parse(JSON.stringify(allAnswers))

                        setTimeout(() => {
                            this.$emit('answer-selected', ansMultiple)
                        }, 500)
                        break
                    case 'fill':
                        let isValidText = true

                        if (!this.isNoSelected && this.textareas[0]) {
                            const textLength = this.textareas[0].length
                            if (textLength < 40 || textLength > 250) {
                                isValidText = false
                            }
                        }
                        if (!isValidText) {
                            this.error =
                                'Odpowiedzi muszą zawierać od 40 do 250 znaków.'
                        } else {
                            this.error = ''
                            let ansFill = JSON.parse(JSON.stringify(allAnswers))

                            setTimeout(() => {
                                if (index) {
                                    this.$emit('answer-selected', [ansFill])
                                } else {
                                    this.$emit('answer-selected', [ansFill[0]])
                                }
                            }, 500)
                        }
                        break
                    case 'text':
                        if (allAnswers.length <= 0) {
                            setTimeout(() => {
                                this.$emit('answer-selected', [''])
                            }, 500)
                        } else if (
                            this.answer &&
                            this.answer[0] ==
                                JSON.parse(JSON.stringify(allAnswers[0]))
                        ) {
                            let ansText = JSON.parse(JSON.stringify(allAnswers))
                            setTimeout(() => {
                                this.$emit('answer-selected', [ansText[0]])
                            }, 500)
                        } else if (this.answer) {
                            let ansText = JSON.parse(JSON.stringify(allAnswers))
                            setTimeout(() => {
                                this.$emit('answer-selected', [ansText])
                            }, 500)
                        } else if (this.question.text == 'Twój opis:') {
                            let ansText = JSON.parse(JSON.stringify(allAnswers))
                            setTimeout(() => {
                                this.$emit('answer-selected', ansText)
                            }, 500)
                        } else {
                            let isValidTextarea = true
                            const textLength = this.text.length
                            const minL = this.question.minLength
                            const maxL = this.question.maxLength

                            if (!this.isNoSelected) {
                                if (textLength != 0 || textLength != '') {
                                    if (
                                        !(
                                            textLength >= minL &&
                                            textLength <= maxL
                                        )
                                    ) {
                                        isValidTextarea = false
                                    }
                                }
                            }
                            if (minL && maxL && !isValidTextarea) {
                                this.error = `Odpowiedzi muszą zawierać od ${minL} do ${maxL} znaków.`
                            } else if (!isValidTextarea) {
                                this.error =
                                    'Odpowiedzi muszą zawierać od 40 do 250 znaków.'
                            } else {
                                this.error = ''
                                this.question.checkedAnswer = index
                                let ansText = JSON.parse(
                                    JSON.stringify(allAnswers)
                                )
                                setTimeout(() => {
                                    this.$emit('answer-selected', [ansText])
                                }, 500)
                            }
                        }
                        break
                    case 'number':
                        if (this.answer[0])
                            setTimeout(() => {
                                this.$emit('answer-selected', [allAnswers[0]])
                            }, 500)
                        else if (allAnswers < 1.2 || allAnswers > 2.5) {
                            this.error =
                                'Twój wzrost powinien mieścić się w przedziale od 1,2m do 2,5m'
                            break
                        } else {
                            this.error = ''
                        }
                        setTimeout(() => {
                            this.$emit('answer-selected', [allAnswers])
                        }, 500)
                        break
                    case 'list':
                        let ansList = JSON.parse(JSON.stringify(allAnswers))
                        let counter = 0
                        ansList.forEach(el => {
                            if (
                                (el.length >= 5 && el.length <= 130) ||
                                el.length == 0
                            ) {
                                counter++
                            }
                        })

                        if (counter == ansList.length) {
                            setTimeout(() => {
                                this.error = ''
                                this.$emit('answer-selected', [ansList])
                            }, 500)
                        } else {
                            this.error =
                                'Odpowiedź musi zawierać od 5 do 130 znaków.'
                        }
                        break

                    default:
                        break
                }
            },
            fillText() {
                let question = JSON.parse(JSON.stringify(this.question))

                switch (question.type) {
                    case 'bundle':
                        if (this.answer) {
                            if (this.currentIndex == 44) {
                                this.answer[0].forEach((element, index) => {
                                    this.bundleAnswers[index] =
                                        this.answer[0][index]
                                })
                            } else {
                                this.answer.forEach((element, index) => {
                                    this.bundleAnswers[index] =
                                        this.answer[index]
                                })
                            }
                        }
                        break
                    case 'multiple':
                        if (!this.answer) {
                            return
                        }
                        if (this.answer[0]) {
                            this.answer.forEach(ans => {
                                if (this.question.maxLength == 1) {
                                    ans.forEach(answer => {
                                        this.checkboxes.push(answer)
                                    })
                                } else {
                                    this.checkboxes.push(ans)
                                }
                            })
                        }
                        break
                    case 'fill':
                        if (this.answer) {
                            if (this.answer[0] == 'false') {
                                this.fillBgc = true
                            } else {
                                this.fillBgc = false

                                this.textareas[0] = this.answer[0]
                                this.answer.showTextarea = true

                                if (
                                    this.answer &&
                                    this.answer[0] != undefined
                                ) {
                                    this.checkFill(
                                        this.question.answers[0],
                                        this.question.answers
                                    )
                                }
                            }
                            // this.checkFill(this.question.answers[0], this.question.answers)
                        }

                        break
                    case 'simple':
                        if (this.question.isMultiSelect) {
                            // console.log('------------------------------');
                            if (this.answer && Array.isArray(this.answer)) {
                                this.question.checkedAnswers = this.answer
                                this.checkedAnswers = this.answer
                            } else if (this.answer) {
                                this.question.checkedAnswers = [this.answer[0]]
                            }
                        } else {
                            // Dla pytania z pojedynczym wyborem
                            if (this.answer) {
                                this.question.checkedAnswer = this.answer
                                this.simpleRadio = this.answer[0]
                            }
                        }
                        break
                    case 'date':
                        if (this.answer) {
                            this.date = this.answer
                        }
                        break
                    case 'text':
                        if (this.answer) {
                            this.text = this.answer
                        }
                        break
                    case 'number':
                        if (this.answer) {
                            this.number = this.answer
                        }
                        break
                    case 'list':
                        // console.log(this.answer)
                        if (this.answer[0] != null) {
                            let answ = JSON.parse(JSON.stringify(this.answer))
                            // console.log([0]);
                            answ[0].forEach((element, index) => {
                                // console.log(element);
                                this.list[index] = answ[0][index]
                            })
                        } else {
                            // question.innerQuestions.forEach((element, index) => {
                            //     this.bundleAnswers[index] = this.question.innerQuestions[index].firstValue;
                            // });
                        }
                        break

                    default:
                        break
                }
            }
        },
        watch: {
            list: {
                handler(newValue) {
                    const lastItem = newValue[newValue.length - 1]
                    if (
                        lastItem &&
                        lastItem.trim() !== '' &&
                        newValue.length < this.question.listInit.maxItems
                    ) {
                        this.list.push('') // dodaj nowy pusty element
                    }
                },
                deep: true // ważne, aby obserwować głębokie zmiany w tablicy
            },
            backClicked(newVal) {
                this.fillText()
            }
        },
        created() {
            this.getProfile()
            this.fillText()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';

    .question__answers__image-validation-text {
        text-align: center;
        color: $pink;
        font-size: 16px;
        font-weight: bold;
        padding: 30px 0px;
    }

    input[type='submit']:disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    .question-view {
        &__inputs-button {
            display: flex;
            justify-content: center;
            input {
                display: block;
                background-color: #d13724;
                color: #fff;
                font-size: 16px;
                transition: 0.3s ease;
                font-weight: bold;
                max-width: 314px;
                margin: 0 auto;
                padding: 14px 10px;
                text-decoration: none;
                cursor: pointer;
                font-size: 16px;
                text-decoration: none;
                width: 100%;
                font-weight: 800;
                text-align: center;
                border: none;
                outline: none;
                text-transform: uppercase;
            }
            @include breakpoint-max('mobile') {
                margin-top: -30px;
            }
        }
    }
    .question {
        margin: 0 auto;

        &__answers {
            padding: 0 20px;
            textarea {
                @include breakpoint-max('mobile') {
                    padding: 15px !important;
                }
            }
        }

        &__slider {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 27px;
            margin: 0 auto;
            margin-bottom: 30px;
            padding: 0 30px;
            max-width: 1100px;
            @include breakpoint-max('s-tablet') {
                grid-template-columns: repeat(2, 1fr);
            }
            @include breakpoint-max('mobile') {
                margin-bottom: 0;
            }
            div {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    height: 100%;
                    width: 100%;
                    max-height: 264px;
                    max-width: 264px;
                    aspect-ratio: 1;
                    // object-fit: contain;
                    @include breakpoint-max('s-tablet') {
                        max-height: 220px;
                        max-width: 220px;
                    }
                    @include breakpoint-max('mobile') {
                        max-height: 160px;
                        max-width: 160px;
                    }
                }
                button {
                    margin: 0 auto;
                    margin-top: 20px;
                    max-width: 165px;
                }
            }
        }
        &__buttons {
            margin-top: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        &__button-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 80px;
            width: 100%;
            max-width: 600px;
            @include breakpoint-max('xs-tablet') {
                justify-content: space-around;
            }
            &--margin {
                margin-top: 80px;
            }
            button {
                @include breakpoint-max('mobile') {
                    margin-top: 30px !important;
                    padding: 12.5px 0;
                }
            }
        }
        &__buttons-content {
            display: flex;
            justify-content: center;
            max-width: 264px;
            flex: 1;
            button {
                display: block;
                padding: 16px 10px;
                width: 100%;
                max-width: 165px;
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 3rem;
                background-color: #e1dbd5;
                cursor: pointer;
            }
        }
        &__file {
            visibility: hidden;
            height: 0 !important;
            margin: 0 !important;
        }
        &__main-image {
            width: 100% !important;
            max-width: 264px;
            object-fit: contain;
            aspect-ratio: 1;
            cursor: pointer;
            background-color: #e1dbd5;
            border: 1px solid #000000;
            color: #000;
            @include breakpoint-max('xl-tablet') {
                max-width: 180px;
            }
            @include breakpoint-max('s-tablet') {
                max-width: 140px;
            }
        }
        .images-box {
            position: relative;
            .image-delete {
                padding: 10px;
                position: absolute;
                color: #d13724;
                font-weight: 600;
                top: 8px;
                right: 15px;
                z-index: 999999;
                cursor: pointer;
            }
        }
        &__checked {
            border: 1px solid $bege;
            background-color: #d13724 !important;
            border-color: #d13724 !important;
            color: #fff !important;
        }
        &__button {
            border: none;
            outline: none;
            padding: 9px 24px;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            background-color: $gray;
            font-family: 'NunitoBold';
            transition: 0.3s;
            background-color: $pink;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: $white;
            &:active {
                transform: scale(0.9);
            }
        }
        &__single {
            font-family: 'Promenade', serif;
            font-weight: 400;
            font-size: 4.5rem;
            text-align: center;
            color: #2a2a29;
            margin: 0 auto;
            margin-bottom: 60px;
            padding: 0 30px;
            max-width: 600px;
            p {
                font-family: 'Promenade', serif;
                font-size: 2rem;
            }
            @include breakpoint-max('mobile') {
                font-size: 3.8rem;
                margin-bottom: 40px;
            }
        }

        &__answers {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__answers-numbers {
            input {
                display: block;
                margin: 20px auto 40px auto;
                width: 400px;
                border: 1px solid #000000;
                border-radius: 0;
                background-color: #f4f2ee;
                padding: 25px 5px;
                text-align: center;
                font-family: 'Open Sans', sans-serif;
                text-transform: uppercase;
                &:focus {
                    outline: none;
                }
                @include breakpoint-max('mobile') {
                    width: 240px;
                    height: 50px;
                }
            }
        }
        &__answers-numbers-error {
            text-align: center;
            color: $pink;
            font-size: 1.6rem;
            font-weight: bold;
        }
        &__answers-text {
            input {
                display: block;
                margin: 20px auto 40px auto;
                padding: 10px;
                width: 400px;
                height: 72px;
                font-family: 'Open Sans', sans-serif;
                border: 1px solid #000000;
                color: #000000;
                font-weight: normal;
                text-align: center;
                background-color: #f4f2ee;
                &::placeholder {
                    font-family: 'Open Sans', sans-serif;
                    color: #a28970;
                    font-weight: normal;
                }
                &:focus {
                    outline: none;
                }
                @include breakpoint-max('mobile') {
                    width: 240px;
                    height: 50px;
                }
            }
            textarea {
                display: block;
                margin: 20px auto 40px auto;
                padding: 10px;
                width: 400px;
                height: 260px;
                padding: 40px 60px;
                background-color: #f4f2ee;
                border: none;
                resize: none;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                line-height: 2rem;
                border: 1px solid #2a2a29;
                &:focus {
                    outline: none;
                }
                @include breakpoint-max('mobile') {
                    width: 240px;
                    padding: 15px !important;
                }
            }
        }

        &__simple-button {
            padding: 10px 0;
            max-width: 280px;
            cursor: pointer;
            background-color: #e1dbd5;
            border: 1px solid #000000;
            font-family: 'Open Sans', sans-serif;
            color: $black;
            font-weight: 700;
            margin-bottom: 12px;
            font-size: 18px;
            padding: 15px 8px;
            width: 100%;
            font-weight: 700;
            transition: 0.3s ease;
            &--center {
                display: flex;
                justify-content: center;
                margin: 0 auto;
                margin-top: 20px;
            }
            @include breakpoint-max('mobile') {
                padding: 11px 0;
                margin-top: 8px !important;
            }
        }
        &__new-checks-box {
            padding: 0 30px;
            max-width: 600px;
            margin: 0 auto;
            width: 100%;
        }
        &__new-checks {
            max-width: 600px;
            margin: 0 auto;
            width: 100%;
            margin-bottom: 47px;
            border: 1px solid #2a2a29;
            span {
                background-color: #e1dbd5;
                display: block;
                font-size: 16px;
                padding: 14px 30px;
                border-bottom: 1px solid #2a2a29;
            }
        }
        &__new-checks-wrap {
            max-height: 400px;
            width: 100%;
            overflow-y: scroll;
            padding: 30px 0;
        }
        &__checkbox-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            padding: 0 30px;
            label {
                font-size: 16px;
                @include breakpoint-max('mobile') {
                    font-size: 14px;
                }
            }
            input[type='checkbox'],
            input[type='radio'] {
                border: 1px solid #2a2a29;
                border-radius: 100%;
                height: 24px;
                width: 24px;
                margin-right: 30px;
                cursor: pointer;
                background-color: transparent;
                &::before {
                    content: none;
                }
                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    height: 14px;
                    width: 14px;
                    background-color: #d13724;
                    opacity: 0;
                    border-radius: 100%;
                    transition: 0.3s ease;
                    @include breakpoint-max('mobile') {
                        height: 12px;
                        width: 12px;
                    }
                }
                @include breakpoint-max('mobile') {
                    height: 20px;
                    width: 20px;
                }
            }
            input[type='checkbox']:checked:after,
            input[type='radio']:checked:after {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
            @include breakpoint-max('mobile') {
                margin-bottom: 15px;
            }
        }
        &__button-submit {
            // position: absolute;
            // bottom: 0;
            // right: 20%;
            background-color: #e1dbd5 !important;
            color: #fff;
            font-size: 16px;
            transition: 0.3s ease;
            margin-top: 60px;
            min-width: 210px;
            max-width: 210px;
            @include breakpoint-max('xs-tablet') {
                min-width: 140px;
                max-width: 180px;
            }
            @include breakpoint-max('mobile') {
                min-width: 120px;
                max-width: 120px;
            }
            &:hover {
                background-color: #d13724 !important;
                border-color: #d13724;
            }
        }
        &__button-submit:disabled {
            background-color: #e1dbd5 !important;
            border-color: #2a2a29;
        }
        &__answer-fill-wrap {
            width: 100%;
            max-width: 600px;
            padding: 0 30px;
            margin: 0 auto;
            text-align: center;
            .question__answer-fill:last-of-type {
                border-bottom: 1px solid #2a2a29;
            }
        }
        &__answer-fill {
            padding: 0;
            cursor: pointer;
            background-color: #e1dbd5;
            font-family: 'NunitoBold';
            margin-bottom: 12px;
            font-size: 18px;
            width: 100%;
            font-weight: 700;
            border: 1px solid #2a2a29;
            border-bottom: none;
            transition: 0.3s ease;
            display: flex;
            flex-direction: column;
            max-width: 100%;
            span {
                text-align: center;
                padding: 15px;
                border-bottom: 1px solid #2a2a29;
            }
            textarea {
                display: block;
                width: 100%;
                height: 260px;
                padding: 40px 60px;
                background-color: #f4f2ee;
                border: none;
                resize: none;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                line-height: 2rem;
                border-bottom: 1px solid #2a2a29;
                &:focus {
                    outline: none;
                }
                @include breakpoint-max('mobile') {
                    padding: 15px;
                }
            }
        }
        &__answer-fill:last-of-type {
            padding: 15px 30px;
            span {
                border-bottom: none;
                padding: 0;
            }
        }
        &__save-button {
            background-color: #f1eee8;
            display: inline-block;
            border: none;
            border-radius: 30px;
            padding: 8px 15px;
            margin: 15px auto 0 auto;
            cursor: pointer;
        }
        .error {
            color: red;
            font-weight: bold;
            margin-top: 10px;
            text-align: center;
            margin: 60px;
            font-size: 16px;
        }
        &__answers-list {
            margin-top: 20px;
            input {
                margin-bottom: 10px;
                margin-top: 10px;
                height: 40px;
                &:first-child {
                    margin-top: 0;
                }
            }
            button {
                margin-top: 10px;
            }
        }
        &__delete-profile-pop {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70vw;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
        }
        &__delete-profile-pop-box {
            padding: 40px 0 30px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__about-pop {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 600px;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
        }
        &__delete-profile-pop-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p {
                font-size: 2.4rem;
                text-align: center;
                margin-bottom: 40px;
            }
            input {
                display: block;
                padding: 10px 15px;
                color: #000000;
                font-size: 16px;
                line-height: 30px;
                border-radius: 0;
                font-family: 'Open Sans', sans-serif;
                outline: none;
                border: 1px solid #000000;
                background-color: transparent;
                width: 100%;
                max-width: 300px;
                margin: 0 auto;
            }
            input:first-of-type {
                margin-bottom: 10px;
            }
            button {
                margin-top: 20px;
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                padding: 15px 0;
                width: 200px;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                }
            }
        }
        &__delete-profile-pop-close,
        &__about-pop-close {
            position: absolute;
            right: 10px;
            top: 10px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: $white;
        }
        &__delete-profile-error {
            margin-top: 20px;
            color: #000;
            font-size: 1.6rem !important;
        }
        &__about-pop {
            textarea {
                padding: 10px;
                outline: none;
                resize: none;
                max-width: 500px !important;
                width: 100%;
                height: 100% !important;
                font-family: 'Open Sans', sans-serif;
                border: 1px solid $black;
                border-radius: 0;
                &::placeholder {
                    font-family: 'Open Sans', sans-serif;
                }
            }
        }
        &__bundle {
            max-width: 600px;
            margin: 0 auto;
            padding: 0 30px;
            div {
                margin: 0 auto;
            }
            input {
                margin: 0;
                width: 100%;
            }
            .question__bundle-multiform-number {
                display: none;
            }
            .question__bundle-box-title-span {
                display: none;
            }
        }
        &__bundle-box {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
            div {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                span {
                    font-size: 12px;
                    margin-left: 10px;
                    margin-bottom: 5px;
                    display: block;
                }
                .question__bundle-multiform-wrap {
                    div {
                        border-bottom: none;
                    }
                }
            }
            div:last-of-type {
                .question__bundle-multiform-wrap {
                    div {
                        border-bottom: 1px solid #000000;
                    }
                }
                input {
                    border-bottom: 1px solid #000000;
                }
            }

            @include breakpoint-max('mobile') {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        &__answers-date {
            padding: 0 30px;
            width: 100%;
            input {
                position: relative;
                width: 400px;
                border: 1px solid #000000;
                border-radius: 0;
                padding: 25px 5px;
                font-size: 16px;
                margin: 0;
                text-align: center;
                font-family: 'Open Sans', sans-serif;
                text-transform: uppercase;
                @include breakpoint-max('mobile') {
                    width: 240px;
                }
            }
            input[type='date'] {
                cursor: pointer; /* Sprawia, że całe pole jest wizualnie 'klikalne' */
            }
            input[type='date']::-webkit-calendar-picker-indicator {
                background: transparent;
                bottom: 0;
                color: transparent;
                cursor: pointer;
                height: auto;
                position: absolute;
                right: 0;
                top: 0;
                width: 15%;
            }
            button {
                margin-top: 60px;
            }
            div {
                position: relative;
                svg {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                }
            }
        }
        &__bundle-multiform-wrap {
            div {
                display: flex;
                flex-direction: row !important;
                border: 1px solid #000000;
                background-color: #f4f2ee;
                padding: 27px 25px 26px;
                label {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: bold;
                    font-size: 16px;
                    margin-right: 10px;
                    align-self: center;
                    @include breakpoint-max('mobile') {
                        font-size: 12px;
                    }
                }
                input {
                    border: none !important;
                    background-color: #f4f2ee;
                    padding: 0;
                    height: auto;
                    text-align: left;
                    font-weight: normal;
                    font-size: 16px;
                    @include breakpoint-max('mobile') {
                        padding: 10px 16px !important;
                    }
                }
                @include breakpoint-max('mobile') {
                    padding: 12px 12px 12px;
                }
            }
        }
        &__bundle-multiform {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 30px;
            .question__bundle-box {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 40px 60px;
                @include breakpoint-max('mobile') {
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 15px 60px !important;
                }
            }
            .question__bundle-box-title-span {
                display: block;
                margin-bottom: 16px !important;
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                font-size: 16px !important;
                margin-left: 9% !important;
                @include breakpoint-max('s-tablet') {
                    margin-left: 14% !important;
                }
                @include breakpoint-max('mobile') {
                    font-size: 13px !important;
                    margin-bottom: 8px !important;
                }
            }
            .question__bundle-multiform-wrap {
                display: flex;
                flex-direction: row !important;
                align-items: center;
                span {
                    width: 10%;
                }
                label {
                    display: none;
                }
                div {
                    width: 90%;
                    padding: 0;
                    border-bottom: 1px solid #000 !important;
                }
                input {
                    width: 100%;
                    text-align: left;
                    padding: 17px 16px;
                    font-weight: normal;
                    &::placeholder {
                        color: #a28970;
                        text-align: left;
                        font-weight: normal;
                        font-size: 16px;
                        @include breakpoint-max('mobile') {
                            font-size: 12px;
                        }
                    }
                }
                @include breakpoint-max('s-tablet') {
                    span {
                        width: 15%;
                    }
                    div {
                        width: 85%;
                    }
                }
            }
            .question__bundle-multiform-number {
                display: block;
                font-family: 'Promenade', serif;
                font-size: 4.5rem !important;
                font-weight: 400 !important;
                margin: 0 !important;
                text-align: center;
                @include breakpoint-max('mobile') {
                    font-size: 4rem !important;
                }
            }
        }
        &__simple-question {
            width: 100%;
            text-align: center;
            input {
                display: none;
            }
            input[type='radio']:checked + label {
                background-color: #d13724;
                border-color: #d13724;
                color: #fff;
            }
            input[type='checkbox']:checked + label {
                background-color: #d13724;
                border-color: #d13724;
                color: #fff;
            }
            label {
                display: inline-block;
                padding: 10px 0;
                max-width: 280px;
                cursor: pointer;
                background-color: #e1dbd5;
                border: 1px solid #000000;
                font-family: 'Open Sans', sans-serif;
                color: $black;
                font-weight: 700;
                margin-bottom: 12px;
                font-size: 18px;
                padding: 19px 10px;
                width: 100%;
                font-weight: 700;
                transition: 0.3s ease;
                @include breakpoint-max('mobile') {
                    padding: 15px 0;
                    font-size: 16px;
                }
            }
        }
        &__button-wrap-single {
            justify-content: center;
        }

        .question-slide-btn-enter-active {
            animation: slide-in 0.2s ease-out;
        }

        .question-slide-btn-leave-active {
            animation: slide-out 0.2s ease-out;
        }

        @keyframes slide-in {
            from {
                opacity: 0;
                transform: translateX(100%);
            }
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @keyframes slide-out {
            from {
                opacity: 1;
                transform: translateX(0);
            }
            to {
                opacity: 0;
                transform: translateX(-100%);
            }
        }
    }
    .activeButton,
    .activeButtonNo,
    .activeButtonReq {
        span {
            color: $white;
            border-color: $pink;
        }
        background-color: #d13724 !important;
        border-color: #d13724;
    }
</style>
