<template>
    <div class="single rejected">
        <div class="single__overlay" v-if="popup"></div>
        <div class="admin-users-view__wrapper">
                <div class="single__left">
                    <div class="single__left-top">
                        <div class="single__left-top-l">
                            <router-link class="single__left-prev" v-if="linkPrev" :to="linkPrev">Powrót</router-link>
                            <router-link class="single__left-prev" v-else to="Warszawa/">Powrót</router-link>
                        </div>
                        <div class="single__right-user-heading">
                            <h2>Dopasowania:</h2>
                            <div class="single__right-user-heading-boxes">
                                <router-link :to="'/admin/users/user/' + user._id">
                                    <figure>
                                        <img  src="../assets/images/ludzik1.png" alt="Photo">
                                    </figure>
                                    <p>Do weryfikacji</p>
                                </router-link>
                                <router-link :to="'/admin/users/user/match/accepted/' + user._id">
                                    <figure>
                                        <img  src="../assets/images/ludzik1.png" alt="Photo">
                                    </figure>
                                    <p>Zaakceptowane</p>
                                </router-link>
                                <router-link :to="'/admin/users/user/match/canceled/' + user._id">
                                    <figure>
                                        <img  src="../assets/images/ludzik1.png" alt="Photo">
                                    </figure>
                                    <p>Rezygnacja</p>
                                </router-link>
                                <!-- <router-link :to="'/admin/users/user/match/rejected/' + user._id">
                                    <figure>
                                        <div class="single__right-user-heading-boxes-second"></div>
                                    </figure>
                                    <p>Odrzucone</p>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                    <div class="single__wrapper">
                        <div class="single__user">
                            <div class="single__user-top">
                                <div class="single__user-top-gallery gallery">
                                    <div class="gallery__thumbnails">
                                        <figure v-if="userPhotos[0]" @click="openLightbox(0)">
                                            <img :src="userPhotos[0]" alt="Photo">
                                        </figure>
                                        <figure v-else>
                                            <p v-if="!userPhotos[0]">Brak zdjęcia</p> 
                                            <img  src="../assets/images/ludzik1.png" alt="Photo">
                                        </figure>
                                    </div>

                                    <!-- Lightbox -->
                                    <div v-if="lightboxVisible" class="lightbox" @click.self="lightboxVisible = false">
                                        <figure>
                                            <img :src="currentPhoto" alt="Powiększone zdjęcie">
                                        </figure>

                                        <div class="lightbox__controls">
                                            <button class="lightbox__close" @click="lightboxVisible = false">X</button>
                                            <button class="lightbox__nav lightbox__nav--left" @click="navigatePhoto(-1)">&#8592;</button>
                                            <button class="lightbox__nav lightbox__nav--right" @click="navigatePhoto(1)">&#8594;</button>
                                        </div>
                                    </div>
                                </div>
                                <div :class="{ 'no-image' : !userPhotos[0] }, 'single__user-top-person'">
                                    <h1 class="single__user-top-person-name">{{ questionnaire.name }}, {{setAge(questionnaire.age)}}</h1>
                                    <div class="single__user-top-person-data">
                                        <div class="single__user-top-person-data-city">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.004" height="20.372" viewBox="0 0 15.004 20.372">
                                                <defs>
                                                    <clipPath id="clip-path">
                                                    <rect id="Rectangle_226" data-name="Rectangle 226" width="15.004" height="20.372" transform="translate(-1 -1)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    </clipPath>
                                                </defs>
                                                <g id="Group_341" data-name="Group 341" transform="translate(1 1)">
                                                    <g id="Group_340" data-name="Group 340" transform="translate(0 0)" clip-path="url(#clip-path)">
                                                    <path id="Path_325" data-name="Path 325" d="M6.5,18.372l-.237-.281A42.963,42.963,0,0,1,.62,9.224,6.351,6.351,0,0,1,0,6.463a6.5,6.5,0,0,1,13,0,6.375,6.375,0,0,1-.634,2.771,43.1,43.1,0,0,1-5.631,8.858ZM6.5.619A5.87,5.87,0,0,0,.619,6.463a5.749,5.749,0,0,0,.564,2.5A41.552,41.552,0,0,0,6.5,17.4a41.685,41.685,0,0,0,5.3-8.427,5.77,5.77,0,0,0,.578-2.513A5.87,5.87,0,0,0,6.5.619" transform="translate(0 0)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_326" data-name="Path 326" d="M13.406,16.448a3.406,3.406,0,1,1,3.406-3.406,3.41,3.41,0,0,1-3.406,3.406m0-6.193a2.787,2.787,0,1,0,2.787,2.787,2.79,2.79,0,0,0-2.787-2.787" transform="translate(-6.904 -6.653)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            <p>{{ questionnaire.city }}</p>
                                        </div>
                                        <div class="single__user-top-person-data-gender">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.705" height="20.062" viewBox="0 0 19.705 20.062">
                                                <g id="Group_349" data-name="Group 349" transform="translate(-110.047 -753.468)">
                                                    <g id="Group_24" data-name="Group 24" transform="translate(110.047 756.945)">
                                                    <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(0 0)" fill="none" stroke="#d13724" stroke-width="1">
                                                        <ellipse cx="5.407" cy="5.407" rx="5.407" ry="5.407" stroke="none"/>
                                                        <ellipse cx="5.407" cy="5.407" rx="4.907" ry="4.907" fill="none"/>
                                                    </g>
                                                    <line id="Line_23" data-name="Line 23" y1="5.677" transform="translate(5.542 10.408)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_24" data-name="Line 24" x2="4.055" transform="translate(3.379 13.382)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                    <g id="Group_26" data-name="Group 26" transform="translate(115.711 753.969)">
                                                    <g id="Ellipse_5-2" data-name="Ellipse 5" transform="translate(0.001 2.368)" fill="none" stroke="#d13724" stroke-width="1">
                                                        <ellipse cx="5.407" cy="5.407" rx="5.407" ry="5.407" stroke="none"/>
                                                        <ellipse cx="5.407" cy="5.407" rx="4.907" ry="4.907" fill="none"/>
                                                    </g>
                                                    <line id="Line_23-2" data-name="Line 23" x1="4.055" y2="4.055" transform="translate(9.058 0.307)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="1"/>
                                                    <path id="Path_50" data-name="Path 50" d="M822.272,6398.362h3.463v3.1" transform="translate(-812.194 -6398.363)" fill="none" stroke="#d13724" stroke-linecap="round" stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            <p>{{ setSex(questionnaire.sex) }}</p>
                                        </div>
                                        <div class="single__user-top-person-data-height">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.097" height="26.128" viewBox="0 0 21.097 26.128">
                                                <defs>
                                                    <clipPath id="clip-path">
                                                    <rect id="Rectangle_227" data-name="Rectangle 227" width="5.532" height="28.277" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    </clipPath>
                                                </defs>
                                                <g id="Group_343" data-name="Group 343" transform="matrix(0.809, 0.588, -0.588, 0.809, 16.621, 0)">
                                                    <g id="Group_342" data-name="Group 342" clip-path="url(#clip-path)">
                                                    <path id="Path_327" data-name="Path 327" d="M27.063,180.972H25.651a.217.217,0,1,1,0-.435h1.412a.217.217,0,1,1,0,.435" transform="translate(-21.748 -154.375)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_328" data-name="Path 328" d="M20.355,165.352H17.806a.217.217,0,0,1,0-.435h2.549a.217.217,0,0,1,0,.435" transform="translate(-15.04 -141.019)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_329" data-name="Path 329" d="M27.063,149.731H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,0,1,0,.435" transform="translate(-21.748 -127.661)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_330" data-name="Path 330" d="M20.355,134.111H17.806a.217.217,0,0,1,0-.435h2.549a.217.217,0,0,1,0,.435" transform="translate(-15.04 -114.305)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_331" data-name="Path 331" d="M5.532,28.277H0V0H5.532Zm-5.1-.435H5.1V.435H.435Z" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_332" data-name="Path 332" d="M27.063,118.49H25.651a.217.217,0,1,1,0-.435h1.412a.217.217,0,1,1,0,.435" transform="translate(-21.748 -100.947)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_333" data-name="Path 333" d="M20.355,102.869H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435" transform="translate(-15.04 -87.59)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_334" data-name="Path 334" d="M27.063,87.249H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,0,1,0,.435" transform="translate(-21.748 -74.234)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_335" data-name="Path 335" d="M20.355,71.628H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435" transform="translate(-15.04 -60.876)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_338" data-name="Path 338" d="M20.355,71.628H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435" transform="translate(-14.764 -66.022)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_336" data-name="Path 336" d="M27.063,56.008H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,1,1,0,.435" transform="translate(-21.748 -47.52)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    <path id="Path_337" data-name="Path 337" d="M27.063,56.008H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,1,1,0,.435" transform="translate(-21.472 -52.665)" fill="#d13724" stroke="#d13724" stroke-width="0.5"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            <p>{{ setGrowth(questionnaire.growth) }}</p>
                                        </div>
                                        <div class="single__user-top-person-data-children">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="21" viewBox="0 0 22 21">
                                                <defs>
                                                    <clipPath id="clip-path">
                                                    <rect id="Rectangle_229" data-name="Rectangle 229" width="22" height="21" transform="translate(0 -0.067)" fill="#d13724"/>
                                                    </clipPath>
                                                </defs>
                                                <g id="Group_347" data-name="Group 347" transform="translate(0 0.067)">
                                                    <g id="Group_346" data-name="Group 346" clip-path="url(#clip-path)">
                                                    <path id="Path_359" data-name="Path 359" d="M10.8,123.469a8.433,8.433,0,0,1-8.1-6.077H2.7a2.7,2.7,0,0,1,0-5.4h.462a.338.338,0,0,1,0,.675H2.7a2.026,2.026,0,1,0,0,4.051h.264a.338.338,0,0,1,.327.253,7.758,7.758,0,0,0,15.027,0,.338.338,0,0,1,.327-.253h.262a2.026,2.026,0,0,0,.006-4.051l-.448.018a.338.338,0,1,1-.027-.675l.455-.018h.013a2.7,2.7,0,0,1,0,5.4h0a8.434,8.434,0,0,1-8.1,6.077" transform="translate(0 -102.536)" fill="#d13724"/>
                                                    <path id="Path_360" data-name="Path 360" d="M161.652,60.758a.338.338,0,0,1-.335-.3,7.817,7.817,0,0,0-5.119-6.353.338.338,0,0,1,.225-.637,8.5,8.5,0,0,1,5.564,6.906.338.338,0,0,1-.293.377l-.042,0" transform="translate(-142.808 -48.942)" fill="#d13724"/>
                                                    <path id="Path_361" data-name="Path 361" d="M88.675,133.35a.675.675,0,1,1,.675-.675.676.676,0,0,1-.675.675m0-.675h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z" transform="translate(-80.572 -120.858)" fill="#d13724"/>
                                                    <path id="Path_362" data-name="Path 362" d="M152.675,133.35a.675.675,0,1,1,.675-.675.676.676,0,0,1-.675.675m0-.675h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z" transform="translate(-139.17 -120.858)" fill="#d13724"/>
                                                    <path id="Path_363" data-name="Path 363" d="M102.436,189.28a4.46,4.46,0,0,1-2.286-.662.338.338,0,0,1,.374-.562,3.441,3.441,0,0,0,3.678,0,.338.338,0,0,1,.375.562,3.8,3.8,0,0,1-2.14.662" transform="translate(-91.559 -172.131)" fill="#d13724"/>
                                                    <path id="Path_364" data-name="Path 364" d="M29.081,11.817l-.042,0a.338.338,0,0,1-.293-.377A8.408,8.408,0,0,1,36.115,4.12a2.728,2.728,0,0,0,1.269-.488A2.03,2.03,0,0,0,38.44,1.965,1.289,1.289,0,0,0,37.157.675h0a.928.928,0,0,0-.671.308.921.921,0,0,0-.276.785.7.7,0,0,0,.383.5.338.338,0,0,1-.294.608,1.381,1.381,0,0,1-.752-.977,1.576,1.576,0,0,1,.44-1.368A1.592,1.592,0,0,1,37.157,0h0a1.96,1.96,0,0,1,1.958,1.953A2.672,2.672,0,0,1,37.777,4.18a3.406,3.406,0,0,1-1.583.61,7.734,7.734,0,0,0-6.779,6.731.338.338,0,0,1-.335.3" transform="translate(-26.317)" fill="#d13724"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            <p>{{ questionnaire.childrenHas === 0 ? 'Nie ma' : 'Ma' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single__user-parts">
                                <!-- #region PartOne -->
                                    <div class="single__user-parts-first">
                                        <h2 class="single__user-parts-first-title">Część I</h2>
                                        <div class="single__user-parts-line"></div>
                                        <p v-for="el in partOne">{{ el.text }}:<span>{{ el.value }}</span></p>
                                        <p v-if="partOneSocials[0]">Media społecznościowe:</p>
                                        <a v-for="el in partOneSocials" target="_blank" class="single__user-parts-first-social" :href="el.value">{{el.text}}</a>
                                    </div>
                                <!-- #endregion -->
                                <!-- #region PartTwo -->
                                    <div class="single__user-parts-second">
                                        <h2 class="single__user-parts-second-title">Część II</h2>
                                        <div class="single__user-parts-line"></div>
                                        <div class="single__user-parts-second">
                                            <div class="single__user-parts-second-item" v-for="(el, index) in partTwo">
                                                <div class="single__user-parts-second-item-box">
                                                    <div v-if="el" class="single__user-parts-second-item-box-left">
                                                        <div class="single__user-parts-second-text">
                                                            <h3>{{ el.text }}</h3>
                                                            <div v-if="el.value">
                                                                <div v-if="typeof(el.value) == 'number'">
                                                                    <p v-for="(item, indexx) in el.answers"><span v-if="indexx == el.value">{{ item }}</span></p>
                                                                </div>
                                                                <div v-else-if="typeof(el.value[0]) == 'object'">
                                                                    <p v-for="item in el.value"><span>{{ item[0] }}</span></p>
                                                                </div> 
                                                                <div v-else><p v-for="item in el.value"><span>{{ item }}</span></p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="single__user-parts-second-item-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- #endregion -->
                                <!-- #region PartThree -->
                                    <div class="single__user-parts-second">
                                        <h2 class="single__user-parts-second-title">Część III</h2>
                                        <div class="single__user-parts-line"></div>
                                        <div class="single__user-parts-second">
                                            <div class="single__user-parts-second-item" v-for="(el, index) in partThree">
                                                <div class="single__user-parts-second-item-box">
                                                    <div v-if="el" class="single__user-parts-second-item-box-left">
                                                        <div class="single__user-parts-second-text">
                                                            <h3>{{ el.text }}</h3>
                                                            <div v-if="el.value">
                                                                <div v-if="el.text.includes('Jaka różnica')">
                                                                    <p v-for="(item, indexx) in el.answers"> <span v-if="indexx == el.value">Druga osoba mogłaby być młodsza maksymalnie: <em>{{ item }}</em></span></p>
                                                                    <p v-for="(item, indexx) in el.answers"><span v-if="indexx == el.value">Druga osoba mogłaby być starsza maksymalnie: <em>{{ item }}</em></span></p>
                                                                </div>
                                                                <div v-else-if="typeof(el.value) == 'number'">
                                                                    <p v-for="(item, indexx) in el.answers"><span v-if="indexx == el.value">{{ item }}</span></p>
                                                                </div>                                                        
                                                                <div v-else-if="typeof(el.value[0]) == 'object'">
                                                                    <p v-for="item in el.value"><span>{{ item[0] }}</span></p>
                                                                </div> 
                                                                <div v-else-if="typeof(el.value) == 'string'">
                                                                    <p><span>{{ el.value }}</span></p>
                                                                </div>
                                                                <div v-else><p v-for="item in el.value"><span>{{ item }}</span></p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="single__user-parts-second-item-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- #endregion -->
                                <!-- #region PartFourth -->
                                    <div class="single__user-parts-second">
                                        <h2 class="single__user-parts-second-title">Część IV</h2>
                                        <div class="single__user-parts-line"></div>
                                        <div class="single__user-parts-second">
                                            <div class="single__user-parts-second-item" v-for="(el, index) in partFourth">
                                                <div class="single__user-parts-second-item-box">
                                                    <div v-if="el" class="single__user-parts-second-item-box-left">
                                                        <div class="single__user-parts-second-text">
                                                            <h3>{{ el.text }}</h3>
                                                            <div v-if="el.value">
                                                                <div v-if="el.text.includes('Jaka różnica')">
                                                                    <p v-for="(item, indexx) in el.answers"> <span v-if="indexx == el.value">Druga osoba mogłaby być młodsza maksymalnie: <em>{{ item }}</em></span></p>
                                                                    <p v-for="(item, indexx) in el.answers"><span v-if="indexx == el.value">Druga osoba mogłaby być starsza maksymalnie: <em>{{ item }}</em></span></p>
                                                                </div>
                                                                <div v-else-if="el.text.includes('Jestem osobą')">
                                                                    <p><span>{{ el.answers[0][el.value[0]] }}</span></p>
                                                                    <p><span>{{ el.answers[1][el.value[1]] }}</span></p>
                                                                </div>
                                                                <div v-else-if="el.text.includes('Zależy mi, żeby potencjalny partner/partnerka')">
                                                                    <p><span>{{ el.answers[0][el.value[0]] }}</span></p>
                                                                    <p><span>{{ el.answers[1][el.value[1]] }}</span></p>
                                                                </div>
                                                                <div v-else-if="typeof(el.value) == 'number'">
                                                                    <p v-for="(item, indexx) in el.answers"><span v-if="indexx == el.value">{{ item }}</span></p>
                                                                </div>                                                        
                                                                <div v-else-if="typeof(el.value[0]) == 'object'">
                                                                    <p v-for="item in el.value"><span>{{ item[0] }}</span></p>
                                                                </div> 
                                                                <div v-else-if="typeof(el.value) == 'string'">
                                                                    <p><span>{{ el.value }}</span></p>
                                                                </div>
                                                                <div v-else><p v-for="item in el.value"><span>{{ item }}</span></p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="single__user-parts-second-item-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- #endregion -->
                                <!-- #region PartFifth -->
                                    <div class="single__user-parts-second">
                                        <h2 class="single__user-parts-second-title">Część V</h2>
                                        <div class="single__user-parts-line"></div>
                                        <div class="single__user-parts-second">
                                            <div class="single__user-parts-second-item" v-for="(el, index) in partFifth">
                                                <div class="single__user-parts-second-item-box">
                                                    <div v-if="el" class="single__user-parts-second-item-box-left">
                                                        <div class="single__user-parts-second-text">
                                                            <h3>{{ el.text }}</h3>
                                                            <div v-if="el.value">
                                                                <div v-if="el.text.includes('Wzrost:')">
                                                                    <p><span>{{ setGrowth(el.value) }}</span></p>
                                                                </div>
                                                                <div v-else-if="typeof(el.value) == 'number'">
                                                                    <p v-for="(item, indexx) in el.answers"><span v-if="indexx == el.value">{{ item }}</span></p>
                                                                </div>                                                        
                                                                <div v-else-if="typeof(el.value[0]) == 'object'">
                                                                    <p v-for="item in el.value"><span>{{ item[0] }}</span></p>
                                                                </div> 
                                                                <div v-else-if="typeof(el.value) == 'string'">
                                                                    <p><span>{{ el.value }}</span></p>
                                                                </div>
                                                                <div v-else><p v-for="item in el.value"><span>{{ item }}</span></p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="single__user-parts-second-item-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- #endregion -->
                                <!-- #region PartSixth -->
                                    <div class="single__user-parts-second">
                                        <h2 class="single__user-parts-second-title">Część VI</h2>
                                        <div class="single__user-parts-line"></div>
                                        <div class="single__user-parts-second">
                                            <div class="single__user-parts-second-item" v-for="(el, index) in partSixth">
                                                <div class="single__user-parts-second-item-box">
                                                    <div v-if="el" class="single__user-parts-second-item-box-left">
                                                        <div class="single__user-parts-second-text">
                                                            <h3>{{ el.text }}</h3>
                                                            <div v-if="el.value">
                                                                <div v-if="el.text.includes('Wzrost:')">
                                                                    <p><span>{{ setGrowth(el.value) }}</span></p>
                                                                </div>
                                                                <div v-else-if="typeof(el.value) == 'number'">
                                                                    <p v-for="(item, indexx) in el.answers"><span v-if="indexx == el.value">{{ item }}</span></p>
                                                                </div>                                                        
                                                                <div v-else-if="typeof(el.value[0]) == 'object'">
                                                                    <p v-for="item in el.value"><span>{{ item[0] }}</span></p>
                                                                </div> 
                                                                <div v-else-if="typeof(el.value) == 'string'">
                                                                    <p><span>{{ el.value }}</span></p>
                                                                </div>
                                                                <div v-else><p v-for="item in el.value"><span>{{ item }}</span></p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="single__user-parts-second-item-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- #endregion -->
                                <!-- #region PartSeventh -->
                                    <div class="single__user-parts-second seventh">
                                        <h2 class="single__user-parts-second-title">Część VII</h2>
                                        <div class="single__user-parts-line"></div>
                                        <div class="single__user-parts-second">
                                            <div class="single__user-parts-second-item" v-for="(el, index) in partSeventh">
                                                <div class="single__user-parts-second-item-box">
                                                    <div v-if="el" class="single__user-parts-second-item-box-left">
                                                        <div class="single__user-parts-second-text">
                                                            <!-- <div v-if="el.text.includes('Uwielbiam')"><p><span>{{ el.text }} {{ el.value[0] }}</span></p></div>
                                                            <div v-if="el.text.includes('Nie przepadam')"><p><span>{{ el.text }} {{ el.value[1] }}</span></p></div>
                                                            <div v-if="el.text.includes('Tracę poczucie czasu, gry')"><p><span>{{ el.text }} {{ el.value[2] }}</span></p></div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="single__user-parts-second-item-line"></div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- #endregion -->
                            </div>
                        </div>
                        <div class="single__right-user">
                            <div class="single__right-user-matches">
                                <div v-for="el in matches" class="single__right-user-matches-item">
                                    <SingleMatchViewRejected :userId="el.userId" :toBackId="user._id"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SingleMatchViewRejected from '@/components/atoms/SingleMatchViewRejected.vue';

export default {
    name: 'single user',
    data(){
        return{
            linkPrev: '',
            user: [],
            matches: [],
            questionnaire: [],
            temporarymatch: null,
            match: null,
            flagTrimmed: 0,
            verifications: [],
            popup: false,
            // 
            partOne: [],
            partOneSocials: [],
            partTwo: [],
            partThree: [],
            partFourth: [],
            partFifth: [],
            partSixth: [],
            partSeventh: [],
            matchedUsers: [],
            // 
            flagMap: {
                0: { color: 'red', text: 'Czerwona' },
                1: { color: 'yellow', text: 'Żółta' },
                2: { color: 'blue', text: 'Niebieska' },
                3: { color: 'green', text: 'Zielona' },
                default: { color: 'red', text: 'Czerwona' }
            },
            data: {
                image: require("@/assets/images/hero-girl.png"),
            },
            currentPhotoIndex: 0,
            lightboxVisible: false,
            userPhotos: []
        }
    },
    components: {
        SingleMatchViewRejected,
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown);
        document.addEventListener('keydown', this.handleKeydownExit);
        this.linkPrev = localStorage.getItem('adminUrlPrev')
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
        document.removeEventListener('keydown', this.handleKeydownExit);
    },
    methods: {
        setGrowth(el) {
            return (el/100).toFixed(2) + ' m';
        },
        setSex(el) {
            switch (el) {
                case 0: return 'Kobieta';
                case 1: return 'Mężczyzna'
                case 2: return 'Niebinarna'
                default: 
            }
        },
        setAge(el) {
            return new Date().getFullYear() - new Date(el).getFullYear() - 1; 
        },
        checkVerifications(){
            if (this.verifications && this.verifications.length > 0) {
                const lastVerification = this.verifications[this.verifications.length - 1];
                return lastVerification && lastVerification.resolved === true;
            }
            return true;
        },
        addVerify(){
            axios
                .post(`${this.url}api/verify/`, {
                    userid: this.$route.params.id
                })
                .then(() => {
                    location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        decline(){
            const authToken = this.getCookie('authToken');
            const config = { headers: { 'Authorization': `Bearer ${authToken}` } };
            
            axios
                .post(`${this.url}api/verify/decline`, {
                    userid: this.$route.params.id,
                    reason: this.reason
                }, config)
                .then(() => {
                    location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        formatDateWithAge(isoDate) {
            try {
                let birthDate = new Date(isoDate);
                if (isNaN(birthDate.getTime())) {
                    // Data nie jest prawidłową datą
                    return 'Nieprawidłowa data';
                }

                let currentDate = new Date();
                let currentYear = currentDate.getFullYear();
                let age = currentYear - birthDate.getFullYear();

                if (currentDate.getMonth() < birthDate.getMonth() || 
                    (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
                    age--;
                }

                return `${birthDate.toISOString().split('T')[0]} (${age} lat)`;
            } catch (e) {
                // Błąd podczas przetwarzania daty
                return 'Nieprawidłowa data';
            }
        },
        openLightbox(index) {
            this.currentPhotoIndex = index;
            this.lightboxVisible = true;
        },
        navigatePhoto(direction) {
            this.currentPhotoIndex += direction;
            if (this.currentPhotoIndex < 0) {
                this.currentPhotoIndex = this.userPhotos.length - 1;
            } else if (this.currentPhotoIndex >= this.userPhotos.length) {
                this.currentPhotoIndex = 0;
            }
        },
        trimUserFlag() {
            if (this.user.flag) {
                this.flagTrimmed = this.user.flag.trim();
            }
        },
        getClass(userId) {
            for(let i = 0; i < this.temporarymatch.length; i++) {
                if(this.temporarymatch[i].secondUserId === userId) {
                    return "single__btn--connected";
                }
            }
            return null;
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            }
        },
        getUsers(){
            const authToken = this.getCookie('authToken');
            const config = {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            };

            axios
                .get(`${this.url}api/questionnaire/singleuser/${this.$route.params.id}`, config)
                .then((resp) => {
                    this.user = resp.data.user;
                    this.matches = resp.data.matchedQuestionnaires;
                    this.questionnaire = resp.data.questionnaire;
                    this.userPhotos = [this.user.photo1, this.user.photo2, this.user.photo3, this.user.photo4].filter(Boolean);
                    if (this.userPhotos.length > 0) {
                        this.currentPhoto = this.userPhotos[0];
                    }

                    

                    this.setValuesPartOne(resp.data.questionnaire, resp.data.user)
                    this.setValuesPartTwo(resp.data.questionnaire)
                    this.setValuesPartThree(resp.data.questionnaire)
                    this.setValuesPartFourth(resp.data.questionnaire)
                    this.setValuesPartFifth(resp.data.questionnaire)
                    this.setValuesPartSixth(resp.data.questionnaire)
                    this.setValuesPartSeventh(resp.data.questionnaire)

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        setValuesPartOne(questionnaire, user) {
            this.partOne.push({text: 'Imię', value: questionnaire.name})
            this.partOne.push({text: 'Nazwisko', value: questionnaire.surname})
            this.partOne.push({text: 'E-mail', value: user.email})
            this.partOne.push({text: 'Telefon', value: questionnaire.phone})
            this.partOne.push({text: 'Miasto zamieszkania', value: questionnaire.city})
            this.partOne.push({text: 'Miasto randkowania', value: questionnaire.cityPossible})
            
            if(questionnaire.facebook) {this.partOneSocials.push({text: 'Facebook', value: questionnaire.facebook})}
            if(questionnaire.instagram) {this.partOneSocials.push({text: 'Instagram', value: questionnaire.instagram})}
            if(questionnaire.linkedin) {this.partOneSocials.push({text: 'LinkedIn', value: questionnaire.linkedin})}
            if(questionnaire.website) {this.partOneSocials.push({text: 'Strona www', value: questionnaire.website})}
        },
        setValuesPartTwo(questionnaire) {
            this.partTwo.push({text: 'Nawiązanie nowych znajomości:', value: questionnaire.openToNewPeople, answers: ['Przychodzi mi łatwo', 'Nie przychodzi mi łatwo']})
            this.partTwo.push({text: 'Czy jesteś osobą otwartą?', value: questionnaire.openToNewThings, answers: ['Tak', 'Raczej tak', 'Nie']})
            this.partTwo.push({text: 'Czy jesteś osobą, która potrzebuje dużo wrażeń?', value: questionnaire.lotOfImpressions, answers: ['Zdecydowanie tak', 'Raczej tak', 'Raczej nie', 'Zdecydowanie nie']})
            this.partTwo.push({text: 'Jestem osobą:', value: questionnaire.characterTraits})
            this.partTwo.push({text: 'Mówienie o emocjach i uczuciach:', value: questionnaire.feelingsTalking, answers: ['Jest dla mnie wyzwaniem', 'Przychodzi mi dość trudno', 'Przychodzi mi dość łatwo', 'Nie jest dla mnie wyzwaniem']})
            this.partTwo.push({text: 'Jak lubisz spędzać wolny czas?', value: questionnaire.freeTime, answers: ['Zdecydowanie aktywnie', 'Cenię balans, łączę aktywność i odpoczynek', 'Relaksując się, mało aktywnie']})
            this.partTwo.push({text: 'Czy masz zwierzę lub zwierzęta domowe?', value: questionnaire.petsHas, answers: ['Tak', 'Nie, ale chcę mieć', 'Nie i nie chcę mieć']})
            this.partTwo.push({text: 'Jaki jest Twój stosunek do zwierząt?', value: questionnaire.petsAttitude, answers: ['Lubię zwierzaki', 'Nie przepadam', 'Zdecydowanie nie przepadam']})
            this.partTwo.push({text: 'Czy jest coś, co szczególnie Cię pasjonuje?', value: questionnaire.hobby})
        },
        setValuesPartThree(questionnaire) {
            this.partThree.push({text: 'Ile masz lat?', value: ((new Date().getFullYear() - new Date(questionnaire.age).getFullYear()) - 1).toString() })
            this.partThree.push({text: 'Jaka różnica wieku w relacji jest dla Ciebie akceptowalna?', value: questionnaire.ageDifference, answers: ['do 5 lat', 'do 10 lat', 'do 15 lat', 'powyżej 15 lat']})
            this.partThree.push({text: 'Dodatkowa uwaga dotycząca wieku:', value: questionnaire.ageAdditionalComment})
            this.partThree.push({text: 'Jaki zawód wykonujesz?', value: questionnaire.job})
            this.partThree.push({text: 'Czy Twoja praca jest pod jakimś względem bardzo wymagająca?', value: questionnaire.demandingJob, answers: ['Tak', 'Nie']})
            this.partThree.push({text: 'Co najbardziej lubisz w swojej pracy?', value: questionnaire.jobPros})
            this.partThree.push({text: 'Czy zawód, jaki wykonuje druga osoba jest dla Ciebie specjalnie ważny?', value: questionnaire.jobImportance, answers: ['Tak', 'Nie']})
            this.partThree.push({text: 'Twój roczny dochód zawiera się w przedziale:', value: questionnaire.income, answers: ['0-50 tysięcy zł brutto', '50-100.000 zł brutto', '100-200.000 zł brutto', '200-500.000 zł brutto', 'powyżej 500.000 zł brutto', 'nie chcę udzielać odpowiedzi']})
            this.partThree.push({text: 'Wysokość zarobków drugiej osoby:', value: questionnaire.incomeImportance, answers: ['Ma dla mnie bardzo duże znaczenie', 'Jest dla mnie istotna', 'Nie ma większego znaczenia', 'Nie ma w ogóle znaczenia']})
            this.partThree.push({text: 'Jakie jest Twoje wykształcenie:', value: questionnaire.education, answers: ['podstawowe','gimnazjalne','zawodowe','średnie','licencjat/inżynier','magister','wyższy stopień naukowy']})
            this.partThree.push({text: 'Jak ważne jest dla Ciebie wykształcenie potencjalnego partnera/partnerki?', value: questionnaire.educationImportance, answers: ['Najważniejsze', 'Ważne', 'Nie ma dla mnie większego znaczenia']})
            this.partThree.push({text: 'Dodatkowa uwaga dotycząca wykształcenia:', value: questionnaire.educationAdditionalComment})
        },
        setValuesPartFourth(questionnaire) {
            this.partFourth.push({text: 'Twój stan cywilny:', value: questionnaire.materialStatus, answers: ['panna/kawaler', 'po rozwodzie', 'wdowiec/wdowa']})
            this.partFourth.push({text: 'Czy zależy Ci na tym, żeby w przyszłości wziąć ślub?', value: questionnaire.weddingWanted, answers: ['zdecydowanie tak', 'raczej tak', 'raczej nie', 'zdecydowanie nie']})
            this.partFourth.push({text: 'Jestem osobą:', value: [questionnaire.religion, questionnaire.religionPractice], answers: [['Wierzącą', 'Niewierzącą'],['Praktykującą', 'Niepraktykującą']]})
            this.partFourth.push({text: 'Zależy mi, żeby potencjalny partner/partnerka:', value: [questionnaire.religionMatter, questionnaire.religionPracticeMatter], answers: [['Była osobą wierzącą', 'Była osobą niewierzącą'],['Była osobą praktykującą', 'Była osobą niepraktykującą']]})
            this.partFourth.push({text: 'Czy bierzesz pod uwagę związek z osobą po rozwodzie?', value: questionnaire.divorcedMatter, answers: ['Tak', 'Absolutnie nie']})
            this.partFourth.push({text: 'Czy masz dzieci?', value: questionnaire.childrenHas, answers: ['Tak', 'Nie']})
            this.partFourth.push({text: 'Czy chcesz dzieci?', value: questionnaire.childrenWanted, answers: ['Chcę','Nie chcę',]})
            this.partFourth.push({text: 'Moje poglądy w kwestiach społecznych i politycznych:', value: questionnaire.politicalViews, answers: ['są bardzo konserwatywne','są konserwatywne','są liberalne','są bardzo liberalne']})
            this.partFourth.push({text: 'Czy bierzesz pod uwagę możliwość przeprowadzki do innego miasta?', value: questionnaire.movePossibility, answers: ['Tak','Nie','Rozważam taką możliwość']})
        },
        setValuesPartFifth(questionnaire) {
            this.partFifth.push({text: 'Wzrost:', value: questionnaire.growth})
            this.partFifth.push({text: 'Jak ważny jest dla Ciebie wzrost osoby, z którą możesz się umówić?', value: questionnaire.growthMatter, answers: ['Musi być niższa','Musi być wyższa','Nie ma dla mnie znaczenia']})
            this.partFifth.push({text: 'Dodatkowa uwaga dotycząca wzrostu:', value: questionnaire.growthComment})
            this.partFifth.push({text: 'Czego absolutnie nie jesteś w stanie zaakceptować w wyglądzie drugiej osoby?', value: questionnaire.appearanceNoAcceptation})
            this.partFifth.push({text: 'Na co zwracasz szczególną uwagę w wyglądzie drugiej osoby?', value: questionnaire.specialAppearance})
        },
        setValuesPartSixth(questionnaire) {
            this.partSixth.push({text: 'Dodatkowe informacje o Tobie, które powinnam znać:', value: questionnaire.additionalInfo})
            this.partSixth.push({text: 'Co, poza uwzględnionymi wcześniej kwestiami, ma dla Ciebie szczególne znaczenie przy doborze partnera lub partnerki:', value: questionnaire.additionalPartnerInfo})
        },
        setValuesPartSeventh(questionnaire) {
            this.partSeventh.push({text: 'Uwielbiam ', value: questionnaire.completeSentences})
            this.partSeventh.push({text: 'Nie przepadam ', value: questionnaire.completeSentences})
            this.partSeventh.push({text: 'Tracę poczucie czasu, gry ', value: questionnaire.completeSentences})
        },
        getUser(id){

            axios
                .get(`${this.url}api/verify/${id}`)
                .then((resp) => {
                    this.verifications = resp.data.verifications;
                })
                .catch((err) => {
                    console.log(err);
                });

        },
        setCurrentPhoto(photo) {
            this.currentPhoto = photo;
        },
        getMatch(){
            const authToken = this.getCookie('authToken');
            const config = { headers: { 'Authorization': `Bearer ${authToken}` } };

            let id = window.localStorage.getItem('userId');

            axios
                .get(`${this.url}api/match/${this.$route.params.id}`, config)
                .then((resp) => {
                    this.match = resp.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getTemporaryMatch(){
            const authToken = this.getCookie('authToken');
            const config = { headers: { 'Authorization': `Bearer ${authToken}` } };

            let id = window.localStorage.getItem('userId');

            axios
                .get(`${this.url}api/temporarymatch/${this.$route.params.id}`, config)
                .then((resp) => {
                    this.getUsers(this.$route.params.id);
                    this.temporarymatch = resp.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        addTemporaryMatch(id){
            axios
                .post(`${this.url}api/temporarymatch`, {
                    userId: this.user._id,
                    secondUserId: id
                })
                .then(() => {
                    location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
            // console.log(this.user._id, id);
        },

        // matched person format data methods
        politicalViewsFormattedMatch(item) {
            switch(item.politicalViews) {
                case 0: return 'są bardzo konserwatywne';
                case 1: return 'są konserwatywne';
                case 2: return 'są liberalne';
                case 3: return 'są bardzo liberalne';
                default: return 'nieznane';
            }
        },
        weddingWantedFormattedMatch(item) {
            switch(item.weddingWanted) {
                case 0: return 'zdecydowanie tak';
                case 1: return 'raczej tak';
                case 2: return 'raczej nie';
                case 3: return 'zdecydowanie nie';
                default: return 'nieznana';
            }
        },
        incomeImportanceFormattedMatch(item) {
            switch(item.incomeImportance) {
                case 0: return 'Ma dla mnie bardzo duże znaczenie';
                case 1: return 'Jest dla mnie istotna';
                case 2: return 'Nie ma większego znaczenia';
                case 3: return 'Nie ma w ogóle znaczenia';
                default: return 'nieznana';
            }
        },
        incomeFormattedMatch(item) {
            switch(item.income) {
                case 0: return '0-50 tysięcy zł brutto';
                case 1: return '50-100.000 zł brutto';
                case 2: return '100-200.000 zł brutto';
                case 3: return '200-500.000 zł brutto';
                case 4: return 'powyżej 500.000 zł brutto';
                case 5: return 'nie chcę udzielać odpowiedzi';
                default: return 'nieznana';
            }
        },
        petsAttitudeFormattedMatch(item) {
            switch(item.petsAttitude) {
                case 0: return 'Lubię zwierzaki';
                case 1: return 'Nie przepadam';
                case 2: return 'Zdecydowanie nie przepadam';
                default: return 'nieznana';
            }
        },
        petsHasFormattedMatch(item) {
            switch(item.petsHas) {
                case 0: return 'Tak';
                case 1: return 'Nie, ale chcę mieć';
                case 2: return 'Nie i nie chcę mieć';
                default: return 'nieznana';
            }
        },
        freeTimeFormattedMatch(item) {
            switch(item.freeTime) {
                case 0: return 'Zdecydowanie aktywnie';
                case 1: return 'Cenię balans, łączę aktywność i odpoczynek';
                case 2: return 'Relaksując się, mało aktywnie';
                default: return 'nieznana';
            }
        },
        feelingsTalkingFormattedMatch(item) {
            switch(item.feelingsTalking) {
                case 0: return 'Jest dla mnie wyzwaniem';
                case 1: return 'Przychodzi mi dość trudno';
                case 2: return 'Przychodzi mi dość łatwo';
                case 3: return 'Nie jest dla mnie wyzwaniem';
                default: return 'nieznana';
            }
        },
        lotOfImpressionsFormattedMatch(item) {
            switch(item.lotOfImpressions) {
                case 0: return 'Zdecydowanie tak';
                case 1: return 'Raczej tak';
                case 2: return 'Raczej nie';
                case 3: return 'Zdecydowanie nie';
                default: return 'nieznana';
            }
        },
        openToNewThingsFormattedMatch(item) {
            switch(item.openToNewThings) {
                case 0: return 'Tak';
                case 1: return 'Raczej tak';
                case 2: return 'Nie';
                default: return 'nieznana';
            }
        },
        openToNewPeopleFormattedMatch(item) {
            switch(item.openToNewPeople) {
                case 0: return 'Przychodzi mi łatwo';
                case 1: return 'Nie przychodzi mi łatwo';
                default: return 'nieznana';
            }
        },
        cityPossibleFormattedMatch(item) {
            switch(item.cityPossible) {
                case "0": return 'Poznań';
                case "1": return 'Warszawa';
                case 0: return 'Poznań';
                case 1: return 'Warszawa';
                default: return 'nieznana';
            }
        },
        growthMatterFormattedMatch(item) {
            switch(item.growthMatter) {
                case 0: return 'Musi być niższa';
                case 1: return 'Musi być wyższa';
                case 2: return 'Nie ma dla mnie znaczenia';
                default: return 'nieznana';
            }
        },
        addPremiumOption(){
            const authToken = this.getCookie('authToken');
            const config = { headers: { 'Authorization': `Bearer ${authToken}` } };
            
            axios
                .put(`${this.url}api/verify/addpremium/${this.$route.params.id}`, config)
                .then(() => {
                    location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleKeydown(e) {
            if (!this.lightboxVisible) return;
            if (e.key === 'ArrowLeft') {
                this.navigatePhoto(-1);
            } else if (e.key === 'ArrowRight') {
                this.navigatePhoto(1);
            }
        },
        handleKeydownExit(e) {
            if (!this.lightboxVisible) return;
            if (e.key == 'Escape') {
                this.lightboxVisible = false
            }
        }

    },
    created(){
        this.getUsers();
        this.getUser(this.$route.params.id);
        this.getTemporaryMatch();
        this.getMatch();
        this.trimUserFlag();
    },
    watch: {
        '$route.params.id': function(newId, oldId) {
            this.getTemporaryMatch();
        }
    },
    computed: {
        currentPhoto() {
            return this.userPhotos[this.currentPhotoIndex];
        },
        politicalViewsFormatted() {
            switch(this.questionnaire.sex) {
                case 0: return 'są bardzo konserwatywne';
                case 1: return 'są konserwatywne';
                case 2: return 'są liberalne';
                case 3: return 'są bardzo liberalne';
                default: return 'nieznane';
            }
        },
        matchMediaweddingWantedFormatted() {
            switch(this.questionnaire.weddingWanted) {
                case 0: return 'zdecydowanie tak';
                case 1: return 'raczej tak';
                case 2: return 'raczej nie';
                case 3: return 'zdecydowanie nie';
                default: return 'nieznana';
            }
        },
        incomeImportanceFormatted() {
            switch(this.questionnaire.incomeImportance) {
                case 0: return 'Ma dla mnie bardzo duże znaczenie';
                case 1: return 'Jest dla mnie istotna';
                case 2: return 'Nie ma większego znaczenia';
                case 3: return 'Nie ma w ogóle znaczenia';
                default: return 'nieznana';
            }
        },
        incomeFormatted() {
            switch(this.questionnaire.income) {
                case 0: return '0-50 tysięcy zł brutto';
                case 1: return '50-100.000 zł brutto';
                case 2: return '100-200.000 zł brutto';
                case 3: return '200-500.000 zł brutto';
                case 4: return 'powyżej 500.000 zł brutto';
                case 5: return 'nie chcę udzielać odpowiedzi';
                default: return 'nieznana';
            }
        },
        petsAttitudeFormatted() {
            switch(this.questionnaire.petsAttitude) {
                case 0: return 'Lubię zwierzaki';
                case 1: return 'Nie przepadam';
                case 2: return 'Zdecydowanie nie przepadam';
                default: return 'nieznana';
            }
        },
        petsHasFormatted() {
            switch(this.questionnaire.petsHas) {
                case 0: return 'Tak';
                case 1: return 'Nie, ale chcę mieć';
                case 2: return 'Nie i nie chcę mieć';
                default: return 'nieznana';
            }
        },
        freeTimeFormatted() {
            switch(this.questionnaire.freeTime) {
                case 0: return 'Zdecydowanie aktywnie';
                case 1: return 'Cenię balans, łączę aktywność i odpoczynek';
                case 2: return 'Relaksując się, mało aktywnie';
                default: return 'nieznana';
            }
        },
        feelingsTalkingFormatted() {
            switch(this.questionnaire.feelingsTalking) {
                case 0: return 'Jest dla mnie wyzwaniem';
                case 1: return 'Przychodzi mi dość trudno';
                case 2: return 'Przychodzi mi dość łatwo';
                case 3: return 'Nie jest dla mnie wyzwaniem';
                default: return 'nieznana';
            }
        },
        lotOfImpressionsFormatted() {
            switch(this.questionnaire.lotOfImpressions) {
                case 0: return 'Zdecydowanie tak';
                case 1: return 'Raczej tak';
                case 2: return 'Raczej nie';
                case 3: return 'Zdecydowanie nie';
                default: return 'nieznana';
            }
        },
        openToNewThingsFormatted() {
            switch(this.questionnaire.openToNewThings) {
                case 0: return 'Tak';
                case 1: return 'Raczej tak';
                case 2: return 'Nie';
                default: return 'nieznana';
            }
        },
        openToNewPeopleFormatted() {
            switch(this.questionnaire.openToNewPeople) {
                case 0: return 'Przychodzi mi łatwo';
                case 1: return 'Nie przychodzi mi łatwo';
                default: return 'nieznana';
            }
        },
        cityPossibleFormatted() {
            switch(this.questionnaire.cityPossible) {
                case "0": return 'Poznań';
                case "1": return 'Warszawa';
                case 0: return 'Poznań';
                case 1: return 'Warszawa';
                default: return 'nieznana';
            }
        },
        growthMatterFormatted() {
            switch(this.questionnaire.growthMatter) {
                case 0: return 'Musi być niższa';
                case 1: return 'Musi być wyższa';
                case 2: return 'Nie ma dla mnie znaczenia';
                default: return 'nieznana';
            }
        },
        //matched
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/Nunito.css";
@import "@vueform/multiselect/themes/default.css";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/Nunito.css";

.seventh {
    .single__user-parts-second-text {
        padding-top: 21px;
        margin-top: 3px;
    }
    .single__user-parts-line {
        margin-bottom: 0;
    }
    .single__user-parts-second-item-line {
        display: block !important;
    }
}
.send-questionnaire-button {
    padding-bottom: 184px;
}
.send-questionnaire {
    display: block;
    outline: none;
    border: 1px solid $black;
    margin: 0 auto;
    width: 200px;
    text-align: center;
    padding: 15px 0;
    background-color: #E1DBD5;
    font-size: 1.6rem;
    line-height: 3rem;
    color: $black;
    font-weight: bold;
    cursor: pointer;
}
.single {
    &__wrapper {
        display: flex;
        column-gap: 20px;
        @include breakpoint-max("m-large") {
            flex-direction: column;
            row-gap: 50px;
        }
    }
    &__message{
        list-style-type: none;
        background-color: $gray;
        padding: 10px;
        border-radius: 8px;
    }
    &__overlay{
        width: 100%;
        height: 100vh;
        background-color: #111111;
        opacity: 70%;
        position: fixed;
        top: 0;
        left: 0;
    }
    &__popup{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 500px;
        width: 100%;
        padding: 20px;
        background-color: $gray;
        border-radius: 15px;
        &-wrapper{
            display: flex;
            flex-direction: column;
            textarea{
                resize: none;
                padding: 20px;
                border: 1px solid darken($gray, 10%);
                border-radius: 10px;
                font-size: 1.6rem;
            }
            .single__btn{
                margin-top: 8px;
            }
        }
    }
    &__check{
        border: 3px dashed #F2545B;
        border-radius: 15px;
        margin: 20px 0;
        margin-top: 0;
        padding: 20px;
        h3{
            margin-bottom: 10px;
        }
        p{
            margin-bottom: 10px;
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: rgba(17, 17, 17, 0.50);
            font-weight: 600;
            padding: 15px 20px 0px 0;
            border-radius: 10px;
        }
    }
    &__verification{
        color: #F2545B;
        background: lighten(#F2545B, 10%);
        padding: 15px 20px !important;
        margin-top: 10px;
    }
    
    &__columns {
        display: flex;
        margin-left: 90px;
    }
    &__photos{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    &__left, &__right{
        flex: 1;
        figure {
            width: fit-content;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: 10px;
            }
        }
        h3 {
            font-size: 1.8rem;
            line-height: 2.5rem;
            color: $black1;
            font-weight: 700;
        }
    }
    &__left {
        @include breakpoint-max("xs-tablet") {
            padding: 0 30px;
        }
    }
    &__left-top {
        display: flex;
        margin-bottom: 40px;
        a {
            flex: 1;
        }
        @include breakpoint-max("s-tablet") {
            flex-direction: column;
            row-gap: 20px;
        }
    }
    &__left-top-l {
        flex: 1;
    }
    &__left-prev {
        display: block;
        width: fit-content;
        padding: 17px 25px;
        padding-top: 20px;
        background-color: $pink;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        font-size: 1.6rem;
        line-height: 3rem;
        color: $white;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
    }
    &__gallery-display figure, &__gallery-thumbnails figure {
        margin: 10px;
        cursor: pointer;
    }
    &__gallery-thumbnails img {
        height: auto;
    }
    &__user {
        margin-bottom: 50px;
        background-color: $gray;
        border-radius: 15px;
        flex: 1;
    }
    &__right-user {
        flex: 1;
        @include breakpoint-max("v-large") {
            flex: .52;
        }
    }
    &__user-top, &__users-top {
        display: flex;
        margin-bottom: 35px;
    }
    &__user-top-person {
        margin-top: 30px;
    }
    .no-image {
        margin-top: 30px;
    }
    &__user-top-person-name {
        padding: 8px 36px 8px 59px;
        background-color: $pink;
        color: $white;
        font-size: 3rem;
        line-height: 4.5rem;
        font-weight: 700;
        text-transform: capitalize;
        font-family: 'Open Sans', sans-serif;
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 41px;
        @include breakpoint-max("xs-tablet") {
            padding: 6px 20px;
        }
        @include breakpoint-max("mobile") {
            padding: 4px 10px;
            font-size: 2rem;
            margin-bottom: 10px;
        }
    }
    &__user-top-person-data {
        padding-left: 51px;
        div {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            svg {
                margin-right: 26px;
                @include breakpoint-max("mobile") {
                    margin-right: 15px;
                    max-width: 15px;
                }
            }
            p {
                font-size: 1.6rem;
                line-height: 3rem;
                color: $black;
                font-weight: 700;
                text-transform: capitalize;
                font-family: 'Open Sans', sans-serif;
            }
            @include breakpoint-max("mobile") {
                margin-bottom: 0px;
            }
        }
        @include breakpoint-max("xs-tablet") {
            padding-left: 20px;
        }
        @include breakpoint-max("mobile") {
            padding-left: 10px;
        }
    }
    &__user-top-person-data-height {
        p {
            text-transform: lowercase !important;
        }
    }
    &__user-parts-first {
        max-width: 1200px;
        margin-bottom: 44px;
        p {
            font-size: 1.6rem;
            line-height: 2rem;
            color: $black;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            margin-bottom: 17px;
            span {
                font-weight: 400;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
    &__user-parts-first-social {
        display: block;
        margin-bottom: 7px;
        color: $black;
        text-decoration: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.6rem;
        line-height: 3rem;
        font-weight: 400;
        transition: .3s;
        width: fit-content;
        &:hover {
            color: $pink;
        }
    }
    &__user-parts-second {
        max-width: 1200px;
        margin-bottom: 43px;
    }
    &__user-parts-first-title,
    &__user-parts-second-title {
        font-size: 3.5rem;
        line-height: 5rem;
        color: $black;
        padding-bottom: 17px;
        width: 100%;
        max-width: 506px;
        font-family: 'Promenade';
        font-weight: 400;
    }
    &__user-parts-line {
        margin-bottom: 17px;
        height: 3px;
        background-color: $black;
        width: 100%;
        @include breakpoint-max("xl-tablet") {
            width: 65%;
        }
    }
    &__user-parts {
        width: 100%;
        max-width: 505px;
        padding-right: 30px;
        @include breakpoint-max("xs-tablet") {
        padding-right: 0px;
        }
    }
    &__user-parts-second-title {
        margin-bottom: 3px;
    }
    &__user-parts-second-item {
        max-width: 1200px;
        width: 100%;
        &:last-child {
            .single__user-parts-second-item-line {
                display: none;
            }
        }
    }
    &__user-parts-second-item-box {
        padding-top: 3px;
        display: flex;
        align-items: flex-start;
        column-gap: 41px;
        @include breakpoint-max("m-large") {
            column-gap: 15px;
        }
        @include breakpoint-max("xl-tablet") {
            flex-direction: column;
        }
    }
    &__user-parts-second-item-box-left {
        flex: 68;
        display: flex;
        column-gap: 41px;
        @include breakpoint-max("m-large") {
            column-gap: 15px;
        }
        @include breakpoint-max("xl-tablet") {
            width: 100%;
        }
    }
    &__user-parts-second-item-line {
        margin-bottom: 17px;
        width: 100%;
        height: 1px;
        background-color: $black;
        @include breakpoint-max("xl-tablet") {
            margin-top: 20px;
            width: 65%;
        }
    }
    &__user-parts-second-text {
        flex: 50;
        padding-bottom: 21px;
        margin-bottom: 3px;
        h3 {
            margin-bottom: 3px;
            font-size: 1.6rem;
            line-height: 3rem;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            color: $black;
        }
        p {
            font-size: 1.6rem;
            line-height: 2.2rem;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            color: $black;
        }
        em {
            font-style: normal;
            color: $pink;
            font-weight: 700;
        }
        @include breakpoint-max("large") {
            flex: 35;
        }
        @include breakpoint-max("xl-tablet") {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
    &__user-parts-second-fix {
        flex: 13;
        display: flex;
        justify-content: center;
        column-gap: 22px;
        label {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            align-items: center;
        }
        span {
            font-size: 1rem;
            line-height: 1.5rem;
            color: $black;
            font-weight: 400;
        }
        // Input type radio
            .content {
                position: relative;
                display: grid;
                place-items: center;
                width: 27px;
                height: 27px;
            }
            .checkBox {
                display: block;
                cursor: pointer;
                width: 27px;
                height: 27px;
                border: 4px solid transparent;
                border-radius: 50%;
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 0px 1.5px $black;
            }

            .checkBox div {
                width: 60px;
                height: 60px;
                background-color: $pink;
                top: -52px;
                left: -52px;
                position: absolute;
                transform: rotateZ(45deg);
                z-index: 100;
            }
            .correct {
                .checkBox div {
                    background-color: $black;
                }
            }

            .checkBox input[type="radio"]:checked + div {
                left: -10px;
                top: -10px;
            }

            .checkBox input[type="radio"] {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                visibility: hidden;
            }

            .transition {
                transition: 300ms ease;
            }
        // 
        @include breakpoint-max("m-large") {
            flex: 15;
        }
        @include breakpoint-max("mobile") {
            column-gap: 10px;
        }
    }
    &__user-parts-second-fix-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 17px;
    }
    .to-fix {
        min-width: 42px;
    }
    &__user-parts-second-input {
        flex: 47;
        div {
            display: flex;
            flex-direction: column;
            row-gap: 17px;
            @include breakpoint-max("xl-tablet") {
                margin-top: 10px;
            }
        }
        span {
            font-size: 1rem;
            line-height: 1.5rem;
            color: $black;
            font-weight: 400;
        }
        input {
            border: 1px solid #2A2A29;
            background: transparent;
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
            color: $black;
            outline: none;
            padding: 4px 13px;
        }
        @include breakpoint-max("m-large") {
            flex: 35;
        }
        @include breakpoint-max("xl-tablet") {
            width: 100%;
        }
    }
    &__users {
        margin-bottom: 50px;
        background-color: $gray;
        padding: 31px 35px;
        border-radius: 15px;
        max-width: 650px;
    }
    &__user-info, &__users-info {

        p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: $black1;
            font-weight: 600;
            margin-bottom: 10px;
        }
        span {
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: rgba(17, 17, 17, 0.50);
            font-weight: 600;
            text-align: right;
            margin-left: 10px;
        }
    }
    &__user-mail, &__user-phone {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        p {
            margin-bottom: 0;
            margin-left: 13px;
        }
    }
    &__btn{
        padding: 14px 21px;
        cursor: pointer;
        border-radius: 10px;
        color: $white;
        background-color: #0fb70d;
        &--margin{
            margin-left: 8px;
        }
        &--red{
            background-color: #F2545B;
        }

    }

    .admin-users-view {
        &__header {
            max-width: 1597px;
            height: 104px;
            background-color: $white;
            padding-left: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__header-logo {
            display: flex;
            align-items: center;
            svg {
                margin-right: 14px;
            }
            p {
                font-family: 'NunitoExtraBold';
                font-weight: 800;
                font-size: 2.0rem;
                line-height: 3.7rem;
                color: $black;
            }
        }
        &__header-notification {
            cursor: pointer;
            width: 53px;
            height: 53px;
            background: $gray;
            border-radius: 15px;
            display: grid;
            place-items: center;
            position: relative;
            transition: .3s;
            &:hover {
                svg {
                    animation: myAnim 2s ease 0s 1 normal forwards;
                }
            }
        }
        &__header-notification-circle {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 12px;
            height: 12px;
            background-color: #F2545B;
            border-radius: 50%;
        }
        &__wrapper {
            display: flex;
            padding-bottom: 86px;
            padding-right: 30px;
        }
        &__sidebar {
            max-width: 303px;
            width: 100%;
            height: calc(100vh - 104px);
            background: $gray;
            border-radius: 0 15px 15px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-top: 54px;
            padding-bottom: 150px;
        }
        &__sidebar-pages {
            position: relative;
            display: flex;
            flex-direction: column;
            list-style: none;
            li {
                margin-bottom: 13px;
                a {
                    text-decoration: none;
                    font-family: 'NunitoMedium';
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2.5rem;
                    color: $black1;
                    height: 38px;
                    display: flex;
                    align-items: center;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                svg {
                    margin-right: 13px;
                }
            }
        }
        &__sidebar-nav {
            position: absolute;
            left: -19px;
            top: 0px;
            width: 4px;
            height: 38px;
            background-color: #F2545B;
            box-shadow: -3px 3px 6px rgba(242, 84, 91, 0.35);
            border-radius: 500px;
            transition: .3s;
        }
        &__sidebar-logout {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__sidebar-line {
            margin-bottom: 34px !important;
            max-width: 239px;
            margin: 0 auto;
            width: 100%;
            height: 1px;
            background-color: $bege;
        }
        &__sidebar-logout-wrapper {
            display: flex;
            align-items: center;
                cursor: pointer;
            p {
                font-family: 'NunitoMedium';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.5rem;
                color: $black1;
            }
            &:hover {
                .arrow {
                    left: 12px;
                }
            }
        }
        &__sidebar-logout-svg {
            position: relative;
            width: 17px;
            height: 19px;
            margin-right: 15px;
            .left {
                position: absolute;
                top: 0px;
                left: 0px;
            }
            .arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 6px;
                transition: .3s;
            }
        }
        &__content {
            width: 100%;
            height: calc(100vh - 104px);
            // background-image: url(http://localhost:8080/img/light-bcg-big.93d5f768.png);
            input {
                padding-left: 93px;
                width: 270px;
                padding: 15px;
            }
        }
        &__content-users {
            padding-top: 49px;
            padding-left: 93px;
            padding-top: 13px;
            max-width: 1294px;
            width: 100%;
        }
        &__content-input {
            padding-left: 93px;
            input {
                background: $gray;
                border-radius: 10px;
                outline: none;
                border: none;
                font-family: "NunitoBold";
                font-weight: 700;
                font-size: 1.6rem;
                color: #111111;
            }
        }
        &__content-input-wrapper {
            position: relative;
            width: fit-content;
            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 24px;
            }
        }

        @keyframes myAnim {
            0%,
            100% {
                transform: translateX(0%);
                transform-origin: 50% 50%;
            }

            15% {
                transform: translateX(-5px) rotate(12deg);
            }

            30% {
                transform: translateX(5px) rotate(-12deg);
            }

            45% {
                transform: translateX(0px) rotate(3.6deg);
            }

            60% {
                transform: translateX(0px) rotate(-2.4deg);
            }

            75% {
                transform: translateX(0px) rotate(1.2deg);
            }
        }
    }
    &__right-user {

    }
    &__right-user-heading {
        flex: 1;
        display: flex;
        align-items: center;
        column-gap: 63px;
        h2 {
            font-size: 3.5rem;
            line-height: 5rem;
            color: $black;
            font-weight: 400;
            font-family: 'Promenade';
        }
        @include breakpoint-max("mobile") {
            flex-direction: column;
            row-gap: 20px;
            align-items: flex-start;
        }
    }
    &__right-user-heading-boxes {
        display: flex;
        column-gap: 13px;
        a {
            text-decoration: none;
            figure {
                display: block;
                margin-bottom: 5px;
                img {
                    display: block;
                    width: 59px;
                    height: 59px;
                    object-fit: cover;
                }
            }
            p {
                font-size: 0.8rem;
                line-height: 2rem;
                font-weight: 700;
                color: $black;
                width: 100%;
                text-align: center;
                font-family: 'Open Sans', sans-serif;
            }
        }
    }
    &__right-user-heading-boxes-second {
        width: 59px;
        height: 59px;
        border-radius: 10px;
        background-color: $pink;
    }
}
.gallery {
    &__thumbnails{
        figure{
            display: block;
            img {
                cursor: pointer;
                display: block;
                height: auto;
                width: 280px;
                height: 280px;
                object-fit: cover;
                aspect-ratio: 1;
                object-position: center;
                border-radius: 0 !important;
                border: 1px solid $black;
                @include breakpoint-max("xs-tablet") {
                    width: 240px;
                    height: 240px;
                }
                @include breakpoint-max("mobile") {
                    width: 160px;
                    height: 160px;
                }
            }
            p {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
                font-size: 1.6rem;
                line-height: 2rem;
                font-weight: 700;
                color: $black;
            }
        }
    }
}
.lightbox {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    figure{
        max-width: 80vw;  /* 80% szerokości widoku */
        max-height: 80vh; /* 80% wysokości widoku */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img{
        max-width: 95vw;
        max-height: 95vh;
        object-fit: contain !important;
    }
}
.lightbox__controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.lightbox__nav {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
}
.lightbox__nav--left {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
}
.lightbox__nav--right {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
}
.lightbox__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
}

</style>