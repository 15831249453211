<template>
  <div class="admin-info">
    <h1>Dodaj komunikat</h1>
    <div class="admin-info__editor">
      <input type="text" placeholder="Tytuł" v-model="announcementTitle" />
      <quill-editor
        v-model:content="content"
        contentType="text"
        ref="myQuillEditor"
        placeholder="Komunikat"
      ></quill-editor>
    </div>
    <!-- <label class="admin-info__specific-user" for="specificUser">
            <input type="checkbox" name="specificUser" id="specificUser" v-model="specificUser">
            <p>Zaznacz, aby wysłać powiadomienie do konkretnego uytkownika</p>
        </label> -->
    <button
      v-if="announcementTitle.length >= 1"
      @click="sendNotification"
      class="admin-info__button"
    >
      Wyślij
    </button>
    <button v-else class="admin-info__button-error">Wyślij</button>
    <ul class="admin-info__announcements">
      <li v-for="el in announcements" class="admin-info__announcements-item">
        <picture>
          <img src="../assets/images/basia-chat.png" alt="" />
        </picture>
        <p v-html="el.title" class="admin-info__announcements-item-text"></p>
        <svg
          @click="deleteNotification(el._id)"
          class="admin-info__announcements-item-delete"
          xmlns="http://www.w3.org/2000/svg"
          width="16.714"
          height="16.714"
          viewBox="0 0 16.714 16.714"
        >
          <g
            id="Group_239"
            data-name="Group 239"
            transform="translate(-1088.086 -7139.086)"
          >
            <line
              id="Line_68"
              data-name="Line 68"
              y1="13.886"
              x2="13.886"
              transform="translate(1089.5 7140.5)"
              fill="none"
              stroke="#d13724"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Line_69"
              data-name="Line 69"
              x2="13.886"
              y2="13.886"
              transform="translate(1089.5 7140.5)"
              fill="none"
              stroke="#d13724"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </li>
    </ul>
  </div>
</template>

<script>
  import axios from 'axios'
  import { QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css'
  import '@vueup/vue-quill/dist/vue-quill.bubble.css'

  export default {
    components: {
      QuillEditor
    },
    data() {
      return {
        content: '',
        announcements: [],
        error: false,
        specificUser: false,
        specificUserId: '',
        announcementTitle: ''
      }
    },
    mounted() {
      this.getNotification()
    },
    methods: {
      textValidation() {
        return this.content.includes('<br>')
      },
      getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) {
          return parts.pop().split(';').shift()
        }
      },
      sendNotification() {
        const authToken = this.getCookie('authToken')
        const config = { headers: { Authorization: `Bearer ${authToken}` } }

        try {
          axios
            .post(
              `${this.url}api/announcement/send`,
              {
                title: this.announcementTitle,
                message: this.content,
                all: true
              },
              config
            )
            .then(res => {
              this.$refs.myQuillEditor.setHTML('')
              this.announcementTitle = ''
              this.getNotification()
              console.log(res)
            })
        } catch (error) {
          console.log(error)
        }
      },
      getNotification() {
        const authToken = this.getCookie('authToken')
        const config = { headers: { Authorization: `Bearer ${authToken}` } }

        try {
          axios.get(`${this.url}api/announcement/admin`, config).then(res => {
            this.announcements = res.data
          })
        } catch (error) {
          console.log(error)
        }
      },
      deleteNotification(id) {
        const authToken = this.getCookie('authToken')
        const config = { headers: { Authorization: `Bearer ${authToken}` } }

        try {
          axios
            .delete(`${this.url}api/announcement/delete/${id}`, config)
            .then(res => {
              window.location.reload()
            })
        } catch (error) {
          console.log(error)
        }
      },
      closeAnnouncement(id) {
        const authToken = this.getCookie('authToken')
        const config = { headers: { Authorization: `Bearer ${authToken}` } }

        try {
          axios
            .put(`${this.url}api/announcement/close/${id}`, config)
            .then(resp => {
              const item = document.querySelector(
                `[data-notificaton-id="${id}"]`
              )
              item.classList.add('single-notification--is-hidden')
              console.log(resp)
            })
            .catch(err => {
              console.log(err)
            })
        } catch (error) {
          console.log(error)
        }
        this.showDeleteBox = false
      }
    }
  }
</script>

<style lang="scss">
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/breakpoints.scss';
  @import '@/assets/scss/containers.scss';
  @import '@/assets/scss/global.scss';

  .admin-info {
    padding-right: 120px;
    @include breakpoint-max('xs-tablet') {
      padding: 0 30px;
    }
    &__editor {
      margin-top: 30px;
      margin-bottom: 30px;
      input {
        display: block;
        font-size: 2rem;
        padding: 12px 15px;
        width: 100%;
        margin-bottom: 20px;
        background: transparent;
        border: 1px solid #d1d5db;
        outline: none;
        &::placeholder {
          font-style: italic;
        }
      }
      .ql-editor {
        min-height: 200px;
        font-size: 2rem;
        font-weight: 400;
        @include breakpoint-max('mobile') {
          min-height: 140px;
        }
      }
    }
    &__specific-user {
      display: flex;
      align-items: center;
      column-gap: 20px;
      margin-bottom: 30px;
      cursor: pointer;
      width: fit-content;
    }
    &__button,
    &__button-error {
      width: 200px;
      text-align: center;
      padding: 15px 0;
      background-color: $pink;
      outline: none;
      border: none;
      cursor: pointer;
      text-transform: uppercase;
      color: $white;
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 30px;
    }
    &__button-error {
      cursor: not-allowed;
      margin-bottom: 30px;
    }
    &__announcements {
      max-width: 984px;
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      padding-bottom: 100px;
      @include breakpoint-max('mobile') {
        row-gap: 15px;
      }
    }
    &__announcements-item {
      display: flex;
      align-items: center;
      column-gap: 38px;
      padding: 15px 30px;
      border: 1px solid $black;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 10px;
        height: 102.5%;
        z-index: 2;
        background-color: $pink;
      }
      picture {
        img {
          width: 45px;
          height: 45px;
          object-fit: cover;
          aspect-ratio: 1;
        }
      }
      @include breakpoint-max('mobile') {
        padding: 10px 20px;
      }
    }
    &__announcements-item-text {
      flex: 1;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      a {
        color: $pink;
      }
    }
    &__announcements-item-eye {
      cursor: pointer;
      min-width: 33px;
    }
    &__announcements-item-delete {
      cursor: pointer;
      min-width: 17px;
    }
  }
</style>
