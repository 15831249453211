<template>
    <div class="user-settings__wrapper">
        <div class="user-settings__inner">
            <div class="user-settings__item">
                <div class="user-settings__item-header">
                    <p>Powiadomienia</p>

                    <input
                        :checked="store.permissions"
                        :v-model="store.permissions"
                        type="checkbox"
                        @input="handleNotification()"
                    />
                </div>

                <div
                    class="user-settings__item-info"
                    v-if="
                        store.permissions === 'denied' ||
                        store.permissions === false
                    "
                >
                    <p>Aktywacja Powiadomień</p>
                    <p class="font-normal">
                        Wygląda na to, że zablokowałeś(aś) powiadomienia w
                        swojej przeglądarce. Aby je teraz włączyć, musisz
                        ręcznie zresetować ustawienia powiadomień. Instrukcja:
                        Otwórz ustawienia przeglądarki. Przejdź do sekcji
                        dotyczącej prywatności i bezpieczeństwa. Znajdź
                        ustawienia powiadomień. Znajdź naszą stronę na liście
                        zablokowanych witryn i usuń ją z tej listy. Po wykonaniu
                        tych kroków, wróć do naszej aplikacji i ponownie włącz
                        powiadomienia. Jeśli potrzebujesz dodatkowej pomocy,
                        skontaktuj się z naszym wsparciem technicznym.
                    </p>
                </div>
                <div class="user-settings__item-info" v-if="store.permissions">
                    <p>Powiadomienia włączone 😊</p>
                    <p class="font-normal">
                        Powiadomienia są włączone. Jeśli chcesz je zablokować,
                        otwórz preferencje przeglądarki lub kliknij ikonę kłódki
                        obok paska adresu, aby zmienić ustawienia powiadomień.
                        🔒
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref, onMounted } from 'vue'
    import { useNotificationStore } from '@/stores/notification'
    import { useUserStore } from '@/stores/user'

    const store = useNotificationStore()

    const blockedByUser = ref(null)

    const handleNotification = () => {
        // store.setNotifications()
    }

    onMounted(() => {
        // store.checkNotificationPermission()
    })
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .user-settings {
        &__wrapper {
            padding: 0px 20px;
            @include breakpoint-min('tablet') {
                padding-right: 90px;
            }
        }

        &__item {
            padding: 20px 10px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;

            font-size: 14px;
            font-weight: 700;
            color: #111111;
            @include breakpoint-min('tablet') {
                font-size: 18px;
            }

            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                border-bottom: 1px solid $black;
                padding: 20px 10px;
            }

            &-info {
                padding: 20px 10px;

                p {
                    margin-bottom: 10px;
                }
            }
        }
    }
</style>
