<template>
    <div class="question">
        <div class="question__single">
            <span>{{ question.text }}</span>
        </div>
        <div v-if="error" class="error">{{ error }}</div>
        <div class="question__answers" v-if="question.type == 'simple'">
            <button 
                class="question__simple-button"
                v-for="(answer, index) in question.answers" 
                :key="index"
                :class="{ 'question__checked': question.checkedAnswer === index }"
                @click="selectAnswer(index)"
            >{{ answer }}</button>
        </div>
        <div class="question__answers question__answers-numbers" v-if="question.type == 'number'">
            <input type="number" v-model="number" :min="question.answers[0]" :max="question.answers[1]">
            <button 
                class="question__button-submit question__simple-button"
                @click="selectAnswer(number)"
            >Dalej</button>
        </div>

        <div class="question__answers question__answers-date question__answers-numbers" v-if="question.type == 'date'">
            <input type="date" v-model="date" min="1940-04-01" max="2005-04-30">
            <button 
                class="question__button-submit question__simple-button"
                @click="selectAnswer(date, true)"
            >Dalej</button>
        </div>

        <div class="question__answers question__answers-text" v-if="question.type == 'text'">
            <input type="text" v-model="text" :placeholder="question.placeholder">
            <button 
                class="question__button-submit question__simple-button"
                @click="submit(text)"
            >Dalej</button>
        </div>
        <div class="question__answers" v-if="question.type == 'multiple'">
            <div class="question__new-checks-box">
                <div class="question__new-checks">
                <span>Zaznacz poniższe pola</span>
                <div class="question__new-checks-wrap">
                    <div class="question__checkbox-wrapper"  v-for="(answer, index) in question.answers" :key="index">
                    <input 
                        type="checkbox" 
                        :id="'checkbox-' + index"
                        :value="answer" 
                        v-model="checkboxes" 
                        :disabled="checkboxes.length >= question.maxLength && !checkboxes.includes(answer)"
                    />
                    <label for="coding">{{ answer }}</label>
                </div>
                </div>
            </div>
            </div>
            <button 
                class="question__button-submit question__simple-button"
                @click="selectAnswer(checkboxes)"
            >Dalej</button>
        </div>
        <div class="question__answers" v-if="question.type == 'fill'">
            <div class="question__answer-fill-wrap">
                <div 
                v-for="(answer, index) in question.answers" 
                :key="index"
                class="question__answer-fill question__simple-button"
                :class="{ 'question__checked': question.checkedAnswer === index }"
                @click="answer.fill ? (answer.showTextarea = !answer.showTextarea) : selectAnswer(index.toString(), true)"
            >
                <span @click="!answer.fill ? (selectAnswer(index.toString(), true)) : ''">{{ answer.text }}</span>
                

                <!-- span as button -->
                <textarea :placeholder="question.placeholder" v-if="answer.fill && answer.showTextarea" v-model="textareas[index]" @click.stop></textarea>
            </div>
            <button 
                class="question__button-submit question__simple-button"
                @click="selectAnswer(textareas)"
            >Dalej</button>
            </div>
        </div>
        <div class="question__answers question__answers-text question__answers-list" v-if="question.type == 'list'">
            <transition-group name="fade" tag="div">
            <input v-for="(answer, index) in list" :key="index" :placeholder="question.placeholder" type="text" v-model="list[index]">
            </transition-group>
            <button 
                class="question__button-submit question__simple-button"
                @click="selectAnswer(list)"
            >Dalej</button>
        </div>
        <div 
            class="question__answers question__answers-text question__bundle" 
            :class="{ 'question__bundle-multiform': question.type == 'bundle' && question.innerQuestions.length > 4}" 
            v-if="question.type == 'bundle'"
        >
            <div class="question__bundle-box">
                <div v-for="(answer, index) in question.innerQuestions" :key="index">
                    <span class="question__bundle-box-title-span">{{ answer.text }}</span>
                    <div class="question__bundle-multiform-wrap">
                        <span class="question__bundle-multiform-number">{{ (index + 1).toString().padStart(2, '0') }}</span>
                        <div>
                            <label for="question__bundle-input">{{answer.text}}:</label>
                            <input id="question__bundle-input" :placeholder="question.innerQuestions.length > 4 ? 'wpisz dokończenia zdania' : ''" type="text" v-model="bundleAnswers[index]">
                        </div>
                    </div>
                </div>
            </div>
            <button 
                class="question__button-submit question__simple-button"
                @click="!question.toOneArray ? submitBundle(bundleAnswers, question.optional) : selectAnswer(bundleAnswers, true, true)"
            >Dalej</button>
        </div>
        <!-- <pre>
            {{ bundleAnswers }}
        </pre> -->
    </div>   
</template>

<script>
export default {
    data(){
        const startItemsNum = (this.question && this.question.listInit && this.question.listInit.startItemsNum) ? this.question.listInit.startItemsNum : 0;
        return{
            checked: false,
            number: null,
            text: '',
            checkboxes: [],
            textareas: [],
            list: Array(startItemsNum).fill(''),
            error: null,
            bundleAnswers: [],
            date: null,
        }
    },
    props: {
        question: Object
    },
    methods:{
        submitBundle(innerQuestions, optional = false) {
            // Konwertuj Proxy array na zwykłą tablicę
            let answers = JSON.parse(JSON.stringify(this.bundleAnswers));
            
            const allEmpty = answers.every(answer => !answer || answer === null);
            const containsNull = answers.some(answer => answer === null);

            // Jeśli wszystkie odpowiedzi są puste i nie są opcjonalne
            if (allEmpty && !optional) {
                this.error = 'Wypełnij przynajmniej jedno pole';
                return;
            }

            // Jeśli odpowiedzi są niekompletne i nie są opcjonalne
            if (containsNull && !optional) {
                this.error = 'Wszystkie odpowiedzi muszą być wypełnione';
                return;
            }

            // Jeśli odpowiedzi są opcjonalne i zawierają wartości null, uzupełnij je pustymi stringami
            if (optional) {
                answers = answers.map(answer => answer === null ? '' : answer);
            }

            // Zapisz odpowiedzi
            answers.forEach(element => {
                console.log(element);
                this.setAnswer(element);
            });
        },
        handleAnswerClick(answer, index) {
            if (answer.fill) return; // jeśli fill jest true, nie rób nic
            this.selectAnswer(index); // w przeciwnym razie wywołaj selectAnswer
        },
        selectAnswer(index, skipValidation = false, checkIfAllItemsInArray = false) {
            this.error = null;  // zresetuj błąd na początek każdej próby
            // Jeśli pomijamy walidację, po prostu ustaw odpowiedź i zakończ działanie funkcji
            if (skipValidation) {
                if (checkIfAllItemsInArray) {
                    
                    let convertedIndex = JSON.parse(JSON.stringify(index));


                    if (this.question.innerQuestions.length === index.length && !convertedIndex.includes(null)) {
                        this.setAnswer(index);
                        return;
                    } else {
                        this.error = 'Wypełnij wszystkie pola';
                        return;
                    }
                } else {
                    this.setAnswer(index);
                    return;
                }
                
            }


            if (this.question.optional) {
                this.setAnswer(index);
                return;
            }

            if (!this.isAnswerValid(index)) {
                this.error = 'To pytanie jest obowiązkowe!';
                return;
            }

            this.setAnswer(index);
        },
        setAnswer(index) {
            this.question.checkedAnswer = index;
            setTimeout(() => {
                let answerObject = {
                    index: index,
                    text: this.question.text
                };
                this.$emit('answer-selected', answerObject);
            }, 500);
        },
        saveText(index) {
            // Uzyskaj wartość z textarea o odpowiednim indeksie
            const textValue = this.$el.querySelectorAll('textarea')[index].value;

            // Jeśli tablica textareas ma już wartość dla tego indeksu
            if (this.textareas[index]) {
                // Aktualizuj wartość dla tego indeksu
                this.textareas.splice(index, 1, textValue);
            } else {
                // Dodaj nową wartość do tablicy textareas
                this.textareas.push(textValue);
            }
        },
        isAnswerValid(index) {
            switch(this.question.type) {
                case 'simple':
                    return typeof index !== 'undefined';
                case 'number':
                    return this.number !== null;
                case 'text':
                    return this.text.trim() !== '';
                case 'multiple':
                    return this.checkboxes.length > 0;
                case 'fill':
                    return this.textareas.length > 0 && this.textareas.every(text => text.trim() !== '');
                default:
                    return false;
            }
        }
    },
    watch: {
        list: {
            handler(newValue) {
                const lastItem = newValue[newValue.length - 1];
                if (lastItem && lastItem.trim() !== '' && newValue.length < this.question.listInit.maxItems) {
                    this.list.push(''); // dodaj nowy pusty element
                }
            },
            deep: true, // ważne, aby obserwować głębokie zmiany w tablicy
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";
.question{
    margin: 0 auto;
    &__checked{
        border: 1px solid $bege;
        background-color: #D13724 !important;
        border-color: #D13724 !important;
        color: #fff !important;
    }
    &__single{
        font-family: "Promenade", serif;
        font-weight: 400;
        font-size: 4.5rem;
        text-align: center;
        color: #2A2A29;
        margin: 0 auto;
        margin-bottom: 60px;
        padding: 0 30px;
        max-width: 600px;
    }

    &__answers{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    &__answers-numbers {
        input {
            display: block;
            margin: 20px auto 40px auto;
            width: 400px;
            border: 1px solid #000000;
            border-radius: 0;
            background-color: #F4F2EE;
            padding: 25px 5px;
            text-align: center;
            font-family: "Open Sans", sans-serif;
            text-transform: uppercase;
            &:focus {
                outline: none;
            }
            @include breakpoint-max('mobile') {
            width: 240px;
            }
        }
    }

    &__answers-text {
        input {
            display: block;
            margin: 20px auto 40px auto;
            padding: 10px;
            width: 400px;
            height: 72px;
            font-family: "Open Sans", sans-serif;
            border: 1px solid #000000;
            color: #000000;
            font-weight: normal;
            text-align: center;
            background-color: #F4F2EE;
            &::placeholder {
                font-family: "Open Sans", sans-serif;
                color: #A28970;
                font-weight: normal;
            }
            &:focus {
                outline: none;
            }
            @include breakpoint-max('mobile') {
            width: 240px;
            }
        }
    }

    &__simple-button{
        padding: 10px 0;
        max-width: 210px;
        cursor: pointer;
        background-color: #E1DBD5;
        border: 1px solid #000000;
        font-family: "Open Sans", sans-serif;
        color: $black;
        font-weight: 700;
        margin-bottom: 12px;
        font-size: 18px;
        padding: 19px 10px;
        width: 100%;
        font-weight: 700;
        transition: .3s ease;
    }
    &__new-checks-box {
        padding: 0 30px;
        max-width: 600px;
        margin: 0 auto;
        width: 100%;
    }
    &__new-checks {
        max-width: 600px;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 47px;
        border: 1px solid #2A2A29;
        span {
            background-color: #E1DBD5;
            display: block;
            font-size: 16px;
            padding: 14px 30px;
            border-bottom: 1px solid #2A2A29;
        }
    }
    &__new-checks-wrap {
        max-height: 400px;
        width: 100%;
        overflow-y: scroll;
        padding: 30px 0;
    }
    &__checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        padding: 0 30px;
        label {
            font-size: 16px;
        }
        input[type=checkbox] {
            border: 1px solid #2A2A29;
            border-radius: 100%;
            height: 24px;
            width: 24px;
            margin-right: 30px;
            cursor: pointer;
            background-color: transparent;
            &::before {
                content: none;
            }
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) scale(0);
                height: 14px;
                width: 14px;
                background-color: #D13724;
                opacity: 0;
                border-radius: 100%;
                transition: .3s ease;
            }
        }
        input[type=checkbox]:checked:after {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
    &__button-submit {
        background-color: #E1DBD5 !important;
        color: #FFF;
        font-size: 16px;
        transition: .3s ease;
        margin-top: 60px;
        &:hover {
            background-color: #D13724 !important;
            border-color: #D13724;
        }
    }
    &__answer-fill-wrap {
        width: 100%;
        max-width: 600px;
        padding: 0 30px;
        margin: 0 auto;
        text-align: center;
        .question__answer-fill:last-of-type {
            border-bottom: 1px solid #2A2A29;
        }
    }
    &__answer-fill {
        padding: 0;
        cursor: pointer;
        background-color: #E1DBD5;
        font-family: 'NunitoBold';
        margin-bottom: 12px;
        font-size: 18px;
        width: 100%;
        font-weight: 700;
        border: 1px solid #2A2A29;
        border-bottom: none;
        transition: .3s ease;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        span {
            text-align: center;
            padding: 15px;
            border-bottom: 1px solid #2A2A29;
        }
        textarea {
            display: block;
            width: 100%;
            height: 260px;
            padding: 40px 60px;
            background-color: #F4F2EE;
            border: none;
            resize: none;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            line-height: 2rem;
            border-bottom: 1px solid #2A2A29;
            &:focus {
                outline: none;
            }
        }
    }
    &__answer-fill:last-of-type {
        padding: 15px 30px;
        span {
            border-bottom: none;
            padding: 0;
        }
    }
    &__save-button {
        background-color: #F1EEE8;
        display: inline-block;
        border: none;
        border-radius: 30px;
        padding: 8px 15px;
        margin: 15px auto 0 auto;
        cursor: pointer;
    }
    .error {
        color: red;
        font-weight: bold;
        margin-top: 10px;
        text-align: center;
        margin: 60px;
        font-size: 16px;
    }
    &__answers-list {
        margin-top: 20px;
        input {
            margin-bottom: 10px;
            margin-top: 10px;
            height: 40px;
            &:first-child {
                margin-top: 0;
            }
        }
        button {
            margin-top: 10px;
        }
    }
    &__bundle {
        max-width: 600px;
        margin: 0 auto;
        padding: 0 30px;
        div {
            margin: 0 auto;
        }
        input {
            margin: 0;
            width: 100%;
        }
        .question__bundle-multiform-number {
            display: none;
        }
        .question__bundle-box-title-span {
            display: none;
        }
    }
    &__bundle-box {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            span {
                font-size: 12px;
                margin-left: 10px;
                margin-bottom: 5px;
                display: block;
            }
             .question__bundle-multiform-wrap {
                div {
                    border-bottom: none;
                }
            }
        }
        div:last-of-type {
            .question__bundle-multiform-wrap {
                div {
                    border-bottom: 1px solid #000000;
                }
            }
            input {
                border-bottom: 1px solid #000000;
            }
        }
        
        @include breakpoint-max('mobile') {
            grid-template-columns: repeat(1, 1fr);

        }
    }
    &__answers-date {
        padding: 0 30px;
        width: 100%;
        input {
            width: 400px;
            border: 1px solid #000000;
            border-radius: 0;
            padding: 25px 5px;
            font-size: 16px;
            margin: 0;
            text-align: center;
            font-family: "Open Sans", sans-serif;
            text-transform: uppercase;
             @include breakpoint-max('mobile') {
            width: 240px;
            }
        }
        button {
            margin-top: 60px;
        }
    }
    &__bundle-multiform-wrap {
        div {
            display: flex;
            flex-direction: row !important;
            border: 1px solid #000000;
            background-color: #F4F2EE;
            padding: 27px 25px 26px;
            label {
                font-family: "Open Sans", sans-serif;
                font-weight: bold;
                font-size: 16px;
                margin-right: 10px;
                align-self: center;
            }
            input {
                border: none !important;
                background-color: #F4F2EE;
                padding: 0;
                height: auto;
                text-align: left;
                font-weight: normal;
                font-size: 16px;
            }
        }
    }
     &__bundle-multiform {
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 60px;
        padding: 0 30px;
        .question__bundle-box {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 40px 60px;
            @include breakpoint-max('mobile') {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 40px 60px;
        }
        }
        .question__bundle-box-title-span {
        display: block;
        margin-bottom: 16px !important;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        font-size: 16px !important;
        margin-left: 9% !important;
        @include breakpoint-max('s-tablet') {
            margin-left: 14% !important;
            }
        }
        .question__bundle-multiform-wrap {
            display: flex;
            flex-direction: row !important;
            align-items: center;
            span {
                width: 10%;
            }
            label {
                display: none;
            }
            div {
                width: 90%;
                padding: 0;
                border-bottom: 1px solid #000 !important;
            }
            input {
                width: 100%;
                text-align: left;
                padding: 17px 16px;
                font-weight: normal;
                &::placeholder {
                    color: #A28970;
                    text-align: left;
                    font-weight: normal;
                    font-size: 16px;
                    @include breakpoint-max('mobile') {
                         font-size: 12px;
                        }
                }
            }
            @include breakpoint-max('s-tablet') {
                span {
                    width: 15%;
                }
                div {
                    width: 85%;
                }
            }
        }
        .question__bundle-multiform-number {
            display: block;
            font-family: "Promenade", serif;
            font-size: 4.5rem !important;
            font-weight: 400 !important;
            margin: 0 !important;
            text-align: center;
        }   
    }
}
</style>