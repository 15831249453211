<template>
    <div>
        <HeaderComponent/>
        <section class="faqs main-landing">
            <div class="scroll-div" id="faqs"></div>
            <div class="container-landing">
                <h2 class="faqs__title">FAQ</h2>
                <div class="faqs__columns">
                    <div class="faqs__columns-left">
                        <h3 class="faqs__columns-left-title">REJESTRACJA</h3>
                        <ul class="faqs__columns-left-list">
                            <SingleFaq
                                v-for="(question, index) in firstColumnQuestions"
                                :key="index"
                                :question="question"
                                :inIndex="index"
                                :showMore="showMore"
                            />
                        </ul>
                    </div>
                    <div class="faqs__columns-right">
                        <h3 class="faqs__columns-right-title">FORMULARZ</h3>
                        <ul class="faqs__columns-right-list">
                            <SingleFaq
                                v-for="(question, index) in secondColumnQuestions"
                                :key="'second_' + index"
                                :question="question"
                                :inIndex="index"
                                :showMore="showMore"
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>
<script>
// import SingleFaq from './atoms/SingleFaq.vue';
import SingleFaq from '../components/landing-03/atoms/SingleFaq.vue';
import HeaderComponent from "@/components/landing-03/HeaderComponent.vue";
import Footer from "@/components/landing-03/footer.vue";

    export default {
        data() {
            return {
                firstColumnQuestions: [
                    {title: 'Dlaczego muszę się zapisać na listę?', text: 'To pierwszy etap, przez który musisz przejść, żeby zapisać się do biura. Bez niego nie otrzymasz informacji, że możesz wypełnić formularz i rozpocząć swój proces poszukiwania dopasowanych osób.'},
                    {title: 'Czy muszę dodawać zdjęcia?', text: 'Tak. Wypełniając formularz na kolejnym etapie możesz zdecydować się na dodanie innych zdjęć. Zdjęcia pozwalają nam potwierdzać, że to wciąż ta sama osoba. Prosimy o dołączenie zdjęcia portretowego i całej sylwetki. Nie zostaną one nikomu udostępnione.'},
                    {title: 'Dlaczego na liście są tylko największe miasta?', text: 'Ponieważ tam rozpoczyna swoją działalność biuro randkowe. Osoby mieszkające w mniejszych miejscowościach mogą zdecydować się na randkę w jednym z miast znajdujących się na liście.'},
                    {title: 'Czy zapis na listę oczekujących jest płatny?', text: 'Nie.'},
                    {title: 'Czy mogę zrezygnować z wypełniania formularza na kolejnym etapie?', text: 'Tak, możesz. Wypełnienie formularza zgłoszeniowego na kolejnym etapie jest dobrowolne.'},
                    {title: 'Zarejestruję się i co dalej?', text: 'Po rejestracji wystarczy trzymać rękę na pulsie i czekać na wiadomość o możliwości wypełnienia formularza.'},
                ],
                secondColumnQuestions: [
                    {title: 'Jak długo będę na liście oczekujących?', text: 'Jest bardzo wiele chętnych osób. Ze względu na ogromne zainteresowanie, nie jesteśmy w stanie podać dokładnego czasu. '},
                    {title: 'Czy biuro zacznie działalność od razu we wszystkich miastach?', text: 'Nie. O kolejności, w której będziemy rozpoczynać działalność zadecyduje liczba osób zapisanych w danych miastach. '},
                    {title: 'Czy warto czekać z zapisem na listę?', text: 'Nie, ponieważ formularze zgłoszeniowe będą wysyłane zgodnie z kolejnością zapisu na listę oczekujących.'},
                    {title: 'Jak dowiem się o tym, że mogę wypełnić formularz?', text: 'Poinformujemy o tym mailowo i/lub sms-em.'},
                ]
            }
        },
        components: {
            SingleFaq,
            HeaderComponent,
            Footer
        },
    }
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";


    .faqs {
        padding-top: 120px;
        background-color: $bege3;
        padding-bottom: 84px;
        &__title {
            font-family: 'Oswald', sans-serif;
            font-size: 30rem;
            line-height: 45.5rem;
            color: $begeLight3;
            text-transform: uppercase;
            position: relative;
            margin-bottom: 53px;
            &::after {
                content: '';
                position: absolute;
                bottom: 25px;
                left: 0px;
                width: 100%;
                height: 1px;
                background-color: $black;
                @include breakpoint-max("mobile") {
                    content: none;
                }
            }
            @include breakpoint-max("xs-tablet") {
                margin-bottom: 15px;
            }
            @include breakpoint-max("mobile") {
                font-size: 48px;
                line-height: 50px;
                padding: 60px 0px;
                margin-bottom: 0;
            }
        }
        &__columns {
            display: flex;
            column-gap: 93px;
            @include breakpoint-max('s-tablet') {
                column-gap: 30px;
            }
            @include breakpoint-max("mobile") {
                flex-direction: column;
            }
        }
        &__columns-left,
        &__columns-right {
            flex: 1;
        }
        &__columns-left-title,
        &__columns-right-title {
            font-family: 'Helvetica', sans-serif;
            font-size: 3.2rem;
            line-height: 3.9rem;
            color: $black;
            margin-bottom: 62px;
            @include breakpoint-max("xs-tablet") {
                margin-bottom: 30px;
            }
            @include breakpoint-max("mobile") {
                display: none;
            }
        }
        &__columns-right-title {
            opacity: 0;
        }
        &__columns-left-list,
        &__columns-right-list {
            list-style: none;
            li {
                border-top: 1px solid $black;
                padding: 20px 0 26px;
            }
        }
        &__more {
            padding-top: 9px;
            a {
                text-decoration: none;
                display: block;
                width: fit-content;
            }
            p {
                padding: 11px 31px;
                border: 1px solid $black;
                font-family: 'Open Sans', sans-serif;
                font-size: 2.4rem;
                line-height: 3rem;
                font-weight: 700;
                color: $black;
                text-transform: uppercase;
                width: fit-content;
                cursor: pointer;

            }
        }
        &__columns-content-text {
            p {
                font-size: 14px;
                line-height: 20px;

            }
        }
    }

</style>