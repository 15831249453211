<template>
  <router-link
    :to="route"
    class="simple-button"
    :class="{ 'on-black': isOnBlack }"
  >
    <span>{{ text }}</span>
    <svg
      class="simple-button__link-heart"
      xmlns="http://www.w3.org/2000/svg"
      width="38.267"
      height="48.434"
      viewBox="0 0 38.267 48.434"
    >
      <path
        id="Path_157"
        data-name="Path 157"
        d="M-3110.574,10316.744s3.836-18.917,15.763-17.157-1.86,26.956-6.026,33.515-7.528,14.589-12.2,14.8-21.51-30.97-12.862-41.176S-3110.574,10316.744-3110.574,10316.744Z"
        transform="translate(3128.328 -10299.473)"
        fill="#d13724"
      />
    </svg>
  </router-link>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: {
      text: {
        type: String,
        required: true
      },
      route: {
        type: String,
        required: true
      },
      isOnBlack: {
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/breakpoints.scss';
  @import '@/assets/scss/containers.scss';
  @import '@/assets/scss/Nunito.css';
  @import '@/assets/scss/global.scss';

  .simple-button {
    background-color: $pink;
    border: 1px solid $white;
    color: $white;
    padding: 17px 42px;
    font-size: 1.7rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    display: inline-block;
    transition: 0.3s 0.3s ease;
    @include breakpoint-max('mobile') {
      font-size: 17px;
      line-height: 20px;
      padding: 14px 30px;
    }
    &:hover {
      border-color: $white;
      opacity: 0.9;
      .simple-button__link-heart {
        transform: translate(-50%, -50%);
      }
      &::before {
        transform: translate(-50%, -50%) scale(1);
        transition-delay: 0.4s;
      }
      span {
        color: $white;
      }
    }
    span {
      position: relative;
      z-index: 1;
      transition: 0.3s 0.3s ease;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      background-color: #d13724;
      width: 200px;
      height: 200px;
      transition: 0.3s ease;
      border-radius: 100%;
      transition-delay: 0s;
    }
    &__link-heart {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 105%);
      max-width: 30px;
      transition: 0.3s ease;
    }
    @include breakpoint-max('xl-tablet') {
      background-color: $pink !important;
      color: $white !important;
      border-color: $white !important;
    }
  }
  .on-black {
    border: 1px solid $white;
    color: $white;
  }
</style>
