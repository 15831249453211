<template>
    <section class="process-steps">
        <div id="process"></div>
        <div class="join-our-team">
            <p>
                <span v-for="n in 5" :key="n"><em>WYSOKIE STANDARDY RANDKOWANIA</em></span>
            </p>
        </div>
        <div class="container-landing">
            <div class="process-steps__wrapper">
                <ul class="process-steps__items">
                    <li class="process-steps__item">
                        <span class="process-steps__count">
                            01
                        </span>
                        <span class="process-steps__text">
                            Rejestracja na liście oczekujących
                        </span>
                    </li>
                    <li class="process-steps__item">
                        <span class="process-steps__count">
                            02
                        </span>
                        <span class="process-steps__text">
                            Uzupełnienie formularza zgłoszeniowego
                        </span>
                    </li>
                    <li class="process-steps__item">
                        <span class="process-steps__count">
                            03
                        </span>
                        <span class="process-steps__text">
                            Weryfikacja formularza i stworzenie profilu singla
                        </span>
                    </li>
                    <li class="process-steps__item">
                        <span class="process-steps__count">
                            04
                        </span>
                        <span class="process-steps__text">
                            Rozpoczęcie procesu poszukiwań
                        </span>
                    </li>
                </ul>
                <h2 class="process-steps__title">
                    PROCES
                </h2>
            </div>
        </div>
    </section>
</template>
  
<script>


    export default {
        data() {
            return {
            };
        },
        props: {
            
        },
        computed: {
            
        },
        components: {
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/global.scss";


    .process-steps {
        position: relative;
        padding-bottom: 120px;
        @include breakpoint-max('xs-tablet') {
            padding-bottom: 81px;
        }
        @include breakpoint-max('mobile') {
            padding-bottom: 50px;
        }
        #process {
            position: absolute;
            top: -140px;
            left: 0px;
        }
        .join-our-team {

            @keyframes marquee {
                from {
                    transform: translate3d(0, 0, 0);
                }
                to {
                    transform: translate3d(-100%, 0, 0);
                }
            }
                margin-bottom: 100px;
                background-color: $black;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                @include breakpoint-max('mobile') {
                    margin-bottom: 47px;
                }
            p {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 45px;
                line-height: 50px;
                display: block;
                padding: 8px 0;
                font-family: 'Oswald', sans-serif;
                @include breakpoint-max('mobile') {
                    font-size: 25px;
                    line-height: 30px;
                }
            }
            span {
                display: inline-block;
                padding: 0 14px;
                animation: marquee 10s linear infinite;
                white-space: nowrap;
                color: $bege3;
            }
            em {
                font-style: normal;
                display: block;
                margin: 0 14px;
            }
        }
        &__wrapper {
            display: flex;
            justify-content: space-between;
            @include breakpoint-max('xs-tablet') {
                flex-direction: column-reverse;
            }
        }
        &__title {
            font-family: 'Oswald', sans-serif;
            writing-mode: vertical-lr;
            text-orientation: mixed;
            transform: rotate(180deg);
            color: $black3;
            text-align: center;
            font-weight: 500;
            font-size: 260px;
            @include breakpoint-max('xs-tablet') {
                writing-mode: initial;
                text-orientation: initial;
                transform: rotate(0);
                font-size: 80px;
                font-size: 48px;
                line-height: 55px;
            }
        }
        &__items {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            list-style: none;
            width: 100%;
            margin-right: 57px;
            @include breakpoint-max('xs-tablet') {
                margin-right: 0;
            }
        }
        &__item {
            display: flex;
            align-items: center;
            padding: 48px 0;
            border-bottom: 1px solid $black;
            @include breakpoint-max('mobile') {
                flex-direction: column;
                align-items: flex-start;
                padding: 28px 0;

            }
        }
        &__count {
            font-size: 100px;
            line-height: 105px;
            font-family: 'Promenade', serif;
            margin-right: 45px;
            @include breakpoint-max('mobile') {
                margin-bottom: 24px;
                font-size: 40px;
                line-height: 50px;

            }
        }
        &__text {
            font-size: 4.5rem;
            line-height: 5rem;
            font-family: 'Promenade', serif;
            display: inline-block;
            max-width: 439px;
            @include breakpoint-max('mobile') {
                font-size: 30px;
                line-height: 40px;

            }
        }
    }

</style>