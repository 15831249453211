<template>
    <div class="premium">
        <!-- <AdminToPremiumView :users="users"/>

        {{ users }} -->

        <ul class="check__admin-users">
            <AdminUser 
                v-for="el in users" 
                :imgSrc="el.photo1" 
                :phone="el.phone"
                :id="el._id"
            />
        </ul>

        <p>Funkcja mozliwa do skonczenia po api do platnosci</p>
    </div>
</template>

<script>
import axios from 'axios';
import AdminToPremiumView from '@/components/AdminToPremiumView.vue';
import AdminUser from '@/components/atoms/AdminUser.vue';

export default {
    name: 'admin users',
    data(){
        return{
            users: [],
        }
    },
    components: {
        AdminToPremiumView,
        AdminUser
    },
    methods: {
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            }
        },
        getUsers(page){
            const authToken = this.getCookie('authToken');
            const config = {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            };

            axios
                .get(`${this.url}api/questionnaire/topremium/page/${page}`, config)
                .then((resp) => {
                    this.users = resp.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },
    created(){
        this.getUsers(this.$route.params.page);
    },
    watch: {
        '$route.params.page': function(page) {
            this.getUsers(page);
        }
    }
}
</script>
  