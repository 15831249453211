<template>
    <div
        v-if="isVisible"
        class="single-math"
        :class="{
            'single-math--is-open': isOpen,
            'single-math--temporary-matches-send':
                temporaryMatchesActionCommited,
            'single-math--is-pending': pending,
            'single-math--has-response': response
        }"
    >
        <div class="single-math__user-top">
            <div v-if="pending" class="single-math--loader">
                <Loader />
            </div>

            <div v-if="response_message" class="single-math--response">
                {{ response_message }}
            </div>

            <div v-if="!isOpen" class="single-math__header">
                <div class="single-math__header-left">
                    <div class="single-math__header-img">
                        <figure>
                            <img
                                class="object-cover"
                                loading="lazy"
                                v-if="props.user.user?.photo1"
                                :src="props.user.user?.photo1"
                                alt=""
                            />
                            <img
                                class="object-cover"
                                loading="lazy"
                                v-else
                                src="../../assets/images/ludzik1.png"
                                alt="Photo"
                            />
                        </figure>
                    </div>
                    <div class="single-math__header-title">
                        {{ user.name }}, {{ setAge(user.age) }}
                        <br />
                        {{ user.city }}
                    </div>
                </div>

                <div class="single-math__header-buttons">
                    <button
                        @click="approveTemporaryMatch(userID)"
                        class="single-math--approve"
                    >
                        <svg
                            width="44"
                            height="34"
                            viewBox="0 0 44 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                y="3.4"
                                width="38.76"
                                height="30.6"
                                rx="15.3"
                                fill="#D984C8"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.52 15.1059C10.2226 13.7007 11.6278 11.9442 14.0869 12.6468C16.1947 13.3494 17.5999 15.4572 18.6538 18.2676C27.085 8.78248 34.1109 2.10779 42.5421 0C43.596 0 43.9473 0 43.2447 0.702598C34.1109 7.02598 25.3285 16.5111 18.3025 28.8065C17.9512 29.1578 17.5999 29.1578 17.2486 28.8065C15.8434 25.2935 14.7895 21.7805 13.033 18.2676C12.3304 16.5111 11.2765 15.1059 9.52 15.1059Z"
                                fill="black"
                            />
                        </svg>
                    </button>
                    <button
                        @click="rejectTemporaryMatch(userID)"
                        class="single-math--decline"
                    >
                        <svg
                            width="39"
                            height="31"
                            viewBox="0 0 39 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                y="0.4"
                                width="38.76"
                                height="30.6"
                                rx="15.3"
                                fill="#D984C8"
                            />
                            <path
                                d="M27.9117 4.64645L27.5581 4.29289L27.2046 4.64645L19.5 12.3511L11.7954 4.64645L11.4419 4.29289L11.0883 4.64645L8.64645 7.08832L8.29289 7.44187L8.64645 7.79543L16.351 15.5L8.64645 23.2046L8.29289 23.5581L8.64644 23.9117L11.0883 26.3536L11.4419 26.7071L11.7954 26.3536L19.5 18.649L27.2046 26.3536L27.5581 26.7071L27.9117 26.3535L30.3536 23.9117L30.7071 23.5581L30.3536 23.2046L22.649 15.5L30.3536 7.79543L30.7071 7.44187L30.3536 7.08832L27.9117 4.64645Z"
                                fill="#D81D16"
                            />
                            <path
                                d="M27.9117 4.64645L27.5581 4.29289L27.2046 4.64645L19.5 12.3511L11.7954 4.64645L11.4419 4.29289L11.0883 4.64645L8.64645 7.08832L8.29289 7.44187L8.64645 7.79543L16.351 15.5L8.64645 23.2046L8.29289 23.5581L8.64644 23.9117L11.0883 26.3536L11.4419 26.7071L11.7954 26.3536L19.5 18.649L27.2046 26.3536L27.5581 26.7071L27.9117 26.3535L30.3536 23.9117L30.7071 23.5581L30.3536 23.2046L22.649 15.5L30.3536 7.79543L30.7071 7.44187L30.3536 7.08832L27.9117 4.64645Z"
                                fill="black"
                                fill-opacity="0.2"
                            />
                            <path
                                d="M27.9117 4.64645L27.5581 4.29289L27.2046 4.64645L19.5 12.3511L11.7954 4.64645L11.4419 4.29289L11.0883 4.64645L8.64645 7.08832L8.29289 7.44187L8.64645 7.79543L16.351 15.5L8.64645 23.2046L8.29289 23.5581L8.64644 23.9117L11.0883 26.3536L11.4419 26.7071L11.7954 26.3536L19.5 18.649L27.2046 26.3536L27.5581 26.7071L27.9117 26.3535L30.3536 23.9117L30.7071 23.5581L30.3536 23.2046L22.649 15.5L30.3536 7.79543L30.7071 7.44187L30.3536 7.08832L27.9117 4.64645Z"
                                stroke="#AD1712"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div v-if="isOpen" class="single-math__user-top-wrapper">
                <!-- header -->
                <div class="single-math__user-header">
                    <div class="single-math__user-top-gallery gallery">
                        <div class="gallery__thumbnails">
                            <figure>
                                <img
                                    v-if="props.user.user?.photo1"
                                    :src="props.user.user?.photo1"
                                    alt=""
                                />
                                <img
                                    v-else
                                    src="../../assets/images/ludzik1.png"
                                    alt="Photo"
                                />
                            </figure>
                        </div>
                    </div>
                    <div class="single-math__user-basic-data w-full">
                        <h3>
                            {{ user.name }}, {{ setAge(user.age) }}
                            <br />
                            {{ user.city }}
                        </h3>
                        <ul>
                            <li>
                                <span>Imię:</span>
                                {{ user.name }}
                            </li>
                            <li>
                                <span>Płeć:</span>
                                {{ user.sex }}
                            </li>
                            <li
                                v-if="
                                    user.wantedGender &&
                                    user.wantedGender.length
                                "
                                class="flex"
                            >
                                <span>Szuka:</span>
                                <div
                                    class="flex"
                                    v-if="
                                        user.wantedGender &&
                                        user.wantedGender.length
                                    "
                                    v-for="(gender, index) in user.wantedGender"
                                    :key="index"
                                >
                                    <div
                                        v-if="
                                            !excludedGendersValue.includes(
                                                gender
                                            )
                                        "
                                    >
                                        &nbsp;{{ gender }}
                                    </div>
                                </div>
                            </li>
                            <li v-if="user.age">
                                <span>Wiek:</span>
                                {{ setAge(user.age) }}
                            </li>
                            <li v-if="user.ageDifference">
                                <span>Różnica wieku:</span>
                                {{ user.ageDifference }}
                            </li>
                            <li v-if="user.growth">
                                <span>Wzrost:</span>
                                {{ user.growth }}
                            </li>
                            <li v-if="user.city">
                                <span>Miasto:</span>
                                {{ user.city }}
                            </li>
                            <li
                                v-if="
                                    user.cityPossible &&
                                    user.cityPossible.length
                                "
                            >
                                <span>Wybrane miasta:</span>
                                <p
                                    class="inline"
                                    v-if="user.cityPossible"
                                    v-for="(city, index) in user.cityPossible"
                                    :key="index"
                                >
                                    {{
                                        city.index ===
                                        user.cityPossible.length - 1
                                            ? city
                                            : `${city}, `
                                    }}
                                </p>
                            </li>
                            <li v-if="user.movePossibility">
                                <span>Opcja przeprowadzki:</span>
                                {{ user.movePossibility }}
                            </li>
                        </ul>
                    </div>

                    <div class="single-math__user-description w-full">
                        <div class="single-math__content-buttons">
                            <button
                                @click="approveTemporaryMatch(userID)"
                                class="single-math--approve"
                            >
                                <svg
                                    width="64"
                                    height="50"
                                    viewBox="0 0 64 50"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        y="5.09312"
                                        width="56.882"
                                        height="44.9069"
                                        rx="22.4534"
                                        fill="#D984C8"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M13.971 22.272C15.0021 20.2098 17.0643 17.6321 20.6732 18.6632C23.7664 19.6943 25.8286 22.7875 27.3753 26.9119C39.7484 12.9921 50.0593 3.19675 62.4325 0.10347C63.9791 0.10347 64.4947 0.10347 63.4636 1.13456C50.0593 10.4144 37.1707 24.3342 26.8597 42.3783C26.3442 42.8939 25.8286 42.8939 25.3131 42.3783C23.2509 37.2229 21.7042 32.0674 19.1265 26.9119C18.0954 24.3342 16.5488 22.272 13.971 22.272Z"
                                        fill="black"
                                    />
                                </svg>
                            </button>
                            <button
                                @click="rejectTemporaryMatch(userID)"
                                class="single-math--decline"
                            >
                                <svg
                                    width="57"
                                    height="45"
                                    viewBox="0 0 57 45"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="57"
                                        height="45"
                                        rx="22.5"
                                        fill="#D984C8"
                                    />
                                    <path
                                        d="M40.8802 6.41115L40.5267 6.0576L40.1731 6.41115L28.6765 17.9078L17.1798 6.41115L16.8263 6.0576L16.4727 6.41115L12.8817 10.0021L12.5282 10.3557L12.8817 10.7092L24.3784 22.2059L12.8817 33.7025L12.5282 34.056L12.8817 34.4096L16.4727 38.0006L16.8263 38.3542L17.1798 38.0006L28.6765 26.504L40.1731 38.0006L40.5267 38.3542L40.8802 38.0006L44.4712 34.4096L44.8248 34.056L44.4712 33.7025L32.9745 22.2059L44.4712 10.7092L44.8248 10.3557L44.4712 10.0021L40.8802 6.41115Z"
                                        fill="#D81D16"
                                    />
                                    <path
                                        d="M40.8802 6.41115L40.5267 6.0576L40.1731 6.41115L28.6765 17.9078L17.1798 6.41115L16.8263 6.0576L16.4727 6.41115L12.8817 10.0021L12.5282 10.3557L12.8817 10.7092L24.3784 22.2059L12.8817 33.7025L12.5282 34.056L12.8817 34.4096L16.4727 38.0006L16.8263 38.3542L17.1798 38.0006L28.6765 26.504L40.1731 38.0006L40.5267 38.3542L40.8802 38.0006L44.4712 34.4096L44.8248 34.056L44.4712 33.7025L32.9745 22.2059L44.4712 10.7092L44.8248 10.3557L44.4712 10.0021L40.8802 6.41115Z"
                                        fill="black"
                                        fill-opacity="0.2"
                                    />
                                    <path
                                        d="M40.8802 6.41115L40.5267 6.0576L40.1731 6.41115L28.6765 17.9078L17.1798 6.41115L16.8263 6.0576L16.4727 6.41115L12.8817 10.0021L12.5282 10.3557L12.8817 10.7092L24.3784 22.2059L12.8817 33.7025L12.5282 34.056L12.8817 34.4096L16.4727 38.0006L16.8263 38.3542L17.1798 38.0006L28.6765 26.504L40.1731 38.0006L40.5267 38.3542L40.8802 38.0006L44.4712 34.4096L44.8248 34.056L44.4712 33.7025L32.9745 22.2059L44.4712 10.7092L44.8248 10.3557L44.4712 10.0021L40.8802 6.41115Z"
                                        stroke="#AD1712"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div
                            v-if="user.profileDescription"
                            :class="{ expanded: expanded }"
                            class="text-content"
                        >
                            <span class="uppercase"><b>Opis:</b></span>
                            {{ user.profileDescription }}
                        </div>
                    </div>
                </div>
                <!-- header END -->
                <!-- personal -->
                <div class="single-math__user-personal-info">
                    <ul>
                        <li v-if="user.religion">
                            <span>Wiara:</span>
                            {{ user.religion }}
                        </li>
                        <li v-if="user.religionMatter">
                            <span>Praktykuje:</span>
                            {{ user.religionPractice }}
                        </li>
                        <li v-if="user.childrenHas">
                            <span>Ma dzieci:</span>
                            {{ user.childrenHas }}
                        </li>
                        <li v-if="user.childrenWanted">
                            <span>Chce dzieci:</span>
                            {{ user.childrenWanted }}
                        </li>
                        <li v-if="user.job">
                            <span>Zawód:</span>
                            {{ user.job }}
                        </li>
                        <li v-if="user.petsHas">
                            <span>Ma zwierzęta:</span>
                            {{ user.petsHas }}
                        </li>
                        <li v-if="user.income">
                            <span>Zarobki:</span>
                            {{ user.income }}
                        </li>
                        <li v-if="user.materialStatus === 'po rozwodzie'">
                            <span>Rozwód:</span>
                            {{
                                user.materialStatus === 'po rozwodzie'
                                    ? 'Po rozwodzie'
                                    : ''
                            }}
                        </li>
                        <li v-if="user.childrenPartnerHas">
                            <span>Czy akceptuje dzieci:</span>
                            {{ user.childrenPartnerHas }}
                        </li>
                        <li v-if="user.additionalPartnerInfo">
                            <span>Szczególne znaczenie:</span>
                            <ul>
                                <li v-for="item in user.additionalPartnerInfo">
                                    {{ item }}
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span>Na co zwracasz szczególną uwagę:</span>
                            {{ user.specialAppearance }}
                        </li>
                        <li v-if="user.divorcedMatter">
                            <span>Akceptuje rozwód:</span>
                            {{ user.divorcedMatter }}
                        </li>
                        <li v-if="user.petsAttitude">
                            <span>Lubi zwierzęta:</span>
                            {{ user.petsAttitude }}
                        </li>
                    </ul>
                </div>
                <!-- personal END -->
                <div class="single-math__user-additional-info">
                    <div
                        v-if="user.additionalInfo && user.additionalInfo.length"
                    >
                        <b class="mb-15">Dodatkowe Informacje o Tobie:</b>
                        <p
                            v-if="
                                user.additionalInfo &&
                                user.additionalInfo.length
                            "
                            v-for="(info, index) in user.additionalInfo"
                            :key="index"
                        >
                            {{ info }}
                        </p>
                    </div>
                    <div v-if="user.appearanceNoAcceptation">
                        <b class="mb-15">Czego nie akceptujesz w partnerze:</b>
                        <p>{{ user.appearanceNoAcceptation }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div
            @click="() => (isOpen = !isOpen)"
            class="single-math__toggle-button selected-bg-transparent"
        >
            {{ isOpen ? 'zwiń' : 'rozwiń' }}
        </div>
    </div>
</template>

<script setup>
    import axios from 'axios'
    import { onMounted, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import Loader from '@/components/atoms/Loader.vue'
    const API_URL = process.env.VUE_APP_API
    const route = useRoute()

    const props = defineProps({
        userID: { type: String },
        user: { type: Object },
        mainUserName: { type: String },
        mainUserEmail: { type: String },
        mainUserPhone: { type: String },
        mainuserQuestionaire: { type: String }
    })

    const isOpen = ref(false)
    const isVisible = ref(true)
    const pending = ref(false)
    const expanded = ref(false)
    const temporaryMatchesActionCommited = ref(false)
    const photo = ref(null)
    const response_message = ref(null)
    const setAge = el => {
        const date = new Date()
        const birthDate = new Date(el)

        let age = date.getFullYear() - birthDate.getFullYear()

        const month = date.getMonth()
        const day = date.getDate()
        const birthMonth = birthDate.getMonth()
        const birthDay = birthDate.getDate()

        if (month < birthMonth || (month === birthMonth && day < birthDay)) {
            age--
        }

        return age
    }

    const excludedGendersValue = ['0', '1', '2', 0, 1, 2]

    const getCookie = name => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) {
            return parts.pop().split(';').shift()
        }
    }

    const getPhotos = async () => {
        try {
            const authToken = getCookie('authToken')
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            }

            const response = await axios.get(
                `${API_URL}api/questionnaire/singleuser/${props.userID}`,
                config
            )
            photo.value = response?.data?.user?.photo1
        } catch (e) {
            console.log(e)
        }
    }

    const sendNotification = async (title, message, toUserId, secondUserId) => {
        try {
            await axios.post(`${API_URL}api/announcement/send`, {
                title,
                message,
                all: false,
                toUserId,
                secondUserId
            })
            return true
        } catch (err) {
            console.error(err)
            return false
        }
    }

    // Prawdopodobnie cały moduł wyszukiwarki  ===  deprecated wkrótce
    const approveTemporaryMatch = async secondUserID => {
        const userID = route.params.id
        pending.value = true
        response_message.value = null

        /*
         * TODO: sprawdzić czy przekazywanie telefonu (mainUserPhone, secondUserPhone) jest konieczne w tym miejscu
         * sprawdzić odwołania do api/temporarymatch (POST)
         *
         */

        try {
            const response = await axios.post(`${API_URL}api/temporarymatch`, {
                userId: userID,
                secondUserId: secondUserID,
                mainUserName: props.mainUserName,
                secondUserName: props.user.name,
                mainUserPhone: props.mainUserPhone,
                secondUserPhone: props.user.phone,
                generatedByScheduler: false
            })

            if (response.status === 200) {
                response_message.value = response.data.message
            }

            if (response.status === 201) {
                temporaryMatchesActionCommited.value = true

                await Promise.all([
                    sendNotification(
                        `${props.mainUserName}! Nowa propozycja znajomości!`,
                        `Sprawdź zakładkę „Dopasowanie", mamy dla Ciebie propozycję znajomości.<br/><br/>Liczymy, że Ci się spodoba.<br/>Basia i Zespół Biura Randkowego`,
                        userID,
                        secondUserID
                    ),

                    sendNotification(
                        `${props.user.name}, Nowa propozycja znajomości!`,
                        `Sprawdź zakładkę „Dopasowanie", mamy dla Ciebie propozycję znajomości.<br/><br/>Liczymy, że Ci się spodoba.<br/>Basia i Zespół Biura Randkowego`,
                        secondUserID,
                        userID
                    )
                ])
            } else if (response.status === 201) {
                console.log(
                    'Temporary match created (node env development, bez notyfikacji)'
                )
            } else {
                temporaryMatchesActionCommited.value = false
            }

            response_message.value = response.data.message
            pending.value = false

            await window.setTimeout(() => {
                window.location.reload()
            }, 6000)
        } catch (err) {
            response_message.value = err.message
            pending.value = false
        }
    }

    const rejectTemporaryMatch = async secondUserID => {
        const userID = route.params.id
        pending.value = true

        try {
            const addUserToBlacklist = await axios.post(
                `${API_URL}api/users/add-to-blacklist`,
                {
                    userId: userID,
                    blackListedUserId: secondUserID
                }
            )

            // update blackList on second user
            const addUserToBlacklistOnSecondProfile = await axios.post(
                `${API_URL}api/users/add-to-blacklist`,
                {
                    userId: secondUserID,
                    blackListedUserId: userID
                }
            )

            pending.value = false
            isVisible.value = false
        } catch (e) {
            console.log(e)
            pending.value = false
        }
    }

    onMounted(() => {
        window.setTimeout(() => {
            // getPhotos()
        }, 400)
    })
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .profile {
        &__img {
            &--border {
                border: 6px solid $pink;
            }
            &--unactive {
                cursor: auto;
            }
        }
        &__flex-images {
            display: flex;
            gap: 10px;
            width: 100%;
            margin-top: 20px;
            justify-content: center;
            @include breakpoint-max('xs-tablet') {
                margin-bottom: 15px;
            }
            div {
                width: 86px;
                cursor: pointer !important;
                img {
                    border-width: 1px !important;
                }
            }
        }
    }

    .single-math {
        font-size: 16px;
        border: 2px solid rgb(34, 34, 34);
        padding: 12px;
        position: relative;
        height: 64px;
        overflow: visible;
        margin-bottom: 25px;
        opacity: 1;

        &--loader {
            transform: translate(-50%, -50%) scale(0.4);
        }

        &--loader,
        &--response {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transform-origin: center center;
        }

        &--is-open {
            height: auto !important;
        }

        &--is-pending {
            opacity: 0.6;
        }

        &--has-response {
            border: 1px solid $pink;
            background-color: $pink;

            .single-math__header {
                opacity: 0;
            }

            .single-math--response {
                color: #fff;
            }

            .single-math__toggle-button {
                display: none;
            }
        }

        &--temporary-matches-send {
            opacity: 0;
            height: 0px !important;
            transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            padding: 0;
            margin: 0;
        }

        &__toggle-button {
            position: absolute;
            background-color: #d88ec9;
            padding: 10px 20px;
            border-radius: 35px;
            font-weight: bold;
            left: 50%;
            bottom: -20px;
            transform: translate(-50%, 0);
            transition: all;
            cursor: pointer;

            &:hover {
                transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                background-color: #db98cd;
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            &-img {
                width: 40px;
                height: 40px;
                background-size: cover;

                figure,
                img {
                    width: 40px;
                    height: 40px;
                }
            }

            &-left {
                display: flex;
                align-items: center;
                gap: 12px;
                font-weight: bold;
            }
            &-buttons {
                display: flex;
                align-items: center;
                gap: 10px;
                button {
                    cursor: pointer;
                }
            }
        }

        &__content-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 12px;
            padding: 15px 20px 20px 0px;
        }

        &__user-top-wrapper {
            display: flex;
            flex-direction: column;
            gap: 36px;
        }
        &__save-images {
            text-align: center;
            button {
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                padding: 15px 0;
                width: 200px;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                }
            }
        }
        &__user-header {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;

            @include breakpoint-min('large') {
                flex-direction: row;
                justify-content: flex-start;
            }

            ul {
                list-style-type: none;

                li {
                    margin-bottom: 6px;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }

        &__user-basic-data {
            @include breakpoint-min('tablet') {
                padding: 0px 30px;
            }

            min-width: clamp(400px, 440px, 500px);
            h3 {
                font-size: 2.6rem;
                padding: 15px 0px 20px 0px;
            }
        }

        &__user-additional-info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            justify-content: space-between;

            @include breakpoint-min('tablet') {
                flex-direction: row;
                justify-content: space-between;
            }

            div {
                flex: 1;
            }
        }

        &__user-description {
            button {
                font-weight: bold;
                cursor: pointer;
                transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

                &:hover {
                    opacity: 80%;
                    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
                }
            }

            .text-content {
                height: 222px;
                overflow: hidden;
            }

            .expanded {
                height: auto;
            }
        }

        &__action-buttons {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 30px;
            padding: 15px 20px 30px 0px;
        }

        &__user-personal-info {
            ul {
                display: flex;
                flex-wrap: wrap;
                list-style-type: none;
                gap: 20px;
                span {
                    font-weight: bold;
                }
            }
        }

        &__infoPopup {
            text-align: center;
            color: $pink;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
        }
        &__pop-close {
            position: absolute;
            right: 10px;
            top: 10px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: $white;
        }
        &__edit-images-popup {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70vw;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            z-index: 99;
            .save-profile__photos-flex {
                padding: 20px;
            }
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
        }
        .no-image {
            margin-top: 30px;
        }
        &__user-top-person-name {
            padding: 8px 36px 8px 59px;
            background-color: $pink;
            color: $white;
            font-size: 3rem;
            line-height: 4.5rem;
            font-weight: 700;
            text-transform: capitalize;
            font-family: 'Open Sans', sans-serif;
            box-shadow: 0px 3px 6px #00000029;
            margin-bottom: 41px;
            @include breakpoint-max('xs-tablet') {
                padding: 6px 20px;
            }
            @include breakpoint-max('mobile') {
                padding: 4px 10px;
                font-size: 2rem;
            }
        }
        &__user-top-person-data {
            padding-left: 51px;
            div {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 26px;
                    @include breakpoint-max('mobile') {
                        margin-right: 15px;
                        max-width: 15px;
                    }
                }
                p {
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-family: 'Open Sans', sans-serif;
                }
                @include breakpoint-max('mobile') {
                    margin-bottom: 0px;
                }
                @include breakpoint-max('xs-tablet') {
                    width: fit-content;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-items: center;
                row-gap: 23px;
            }
        }
        &__user-top-person-data-height {
            p {
                text-transform: lowercase !important;
            }
        }
        &__user-top-person-data-children {
            p {
                text-transform: none !important;
            }
        }
        &__edit-images {
            text-align: center;
            background-color: $pink;
            padding: 10px;
            color: $white;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .gallery {
        @include breakpoint-min('tablet') {
            border: 2px solid transparent;
        }
        &__thumbnails {
            figure {
                display: block;
                img {
                    display: block;
                    height: auto;
                    width: 280px;
                    height: 280px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    object-position: center;
                    border-radius: 0 !important;
                    border: 1px solid $black;
                    @include breakpoint-max('xs-tablet') {
                        width: 100%;
                        height: auto;
                    }
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                }
                @include breakpoint-max('xs-tablet') {
                    margin-bottom: 26px;
                }
            }
        }
    }
    .lightbox {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            max-width: 80vw; /* 80% szerokości widoku */
            max-height: 80vh; /* 80% wysokości widoku */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            max-width: 95vw;
            max-height: 95vh;
            object-fit: contain !important;
        }
    }
    .lightbox__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .lightbox__nav {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
    .lightbox__nav--left {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }
    .lightbox__nav--right {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }
    .lightbox__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
</style>
