<template>
    <div class="personal-basic-info" v-on:keyup.esc="handleEscape">
        <div class="personal-basic-info__user-top">
            <!-- Lightbox -->
            <div
                v-if="lightboxVisible && hasGallery"
                class="lightbox"
                @click.self="lightboxVisible = false"
            >
                <figure>
                    <img :src="currentPhoto" alt="Powiększone zdjęcie" />
                </figure>
                <div class="lightbox__controls">
                    <button
                        class="lightbox__close"
                        @click="lightboxVisible = false"
                    >
                        X
                    </button>
                    <button
                        class="lightbox__nav lightbox__nav--left"
                        @click="navigatePhoto(-1)"
                    >
                        &#8592;
                    </button>
                    <button
                        class="lightbox__nav lightbox__nav--right"
                        @click="navigatePhoto(1)"
                    >
                        &#8594;
                    </button>
                </div>
            </div>
            <!-- END Lightbox -->
            <div class="personal-basic-info__user-top-wrapper">
                <div class="personal-basic-info__user-top-gallery gallery">
                    <div class="gallery__thumbnails">
                        <figure
                            v-if="user.photo1"
                            :class="{ 'cursor-pointer': hasGallery }"
                            @click="openLightbox(0)"
                        >
                            <img :src="user.photo1" alt="Photo" />
                        </figure>
                        <figure
                            v-else
                            :class="{ 'cursor-pointer': hasGallery }"
                        >
                            <p>Brak zdjęcia</p>
                            <img
                                src="../../assets//images/ludzik1.png"
                                alt="Photo"
                            />
                        </figure>
                    </div>
                    <div
                        class="profile__flex-images"
                        v-if="removeImages != true"
                    >
                        <div>
                            <img
                                v-if="user.photo2"
                                :src="user.photo2"
                                class="profile__img profile__img--unactive"
                            />
                            <img
                                v-else
                                src="../../assets//images/ludzik1.png"
                                class="profile__img profile__img--unactive"
                            />
                        </div>
                        <div>
                            <img
                                v-if="user.photo3"
                                :src="user.photo3"
                                class="profile__img profile__img--unactive"
                            />
                            <img
                                v-else
                                src="../../assets//images/ludzik1.png"
                                class="profile__img profile__img--unactive"
                            />
                        </div>
                        <div>
                            <img
                                v-if="user.photo4"
                                :src="user.photo4"
                                class="profile__img profile__img--unactive"
                            />
                            <img
                                v-else
                                src="../../assets//images/ludzik1.png"
                                class="profile__img profile__img--unactive"
                            />
                        </div>
                    </div>
                </div>
                <div class="personal-basic-info__user-top-person">
                    <h1 class="personal-basic-info__user-top-person-name">
                        {{ questionnaire.name }},
                        {{ setAge(questionnaire.age) }}
                    </h1>
                    <div class="personal-basic-info__user-top-person-data">
                        <div
                            v-if="questionnaire.city"
                            class="personal-basic-info__user-top-person-data-city"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="15.004"
                                height="20.372"
                                viewBox="0 0 15.004 20.372"
                            >
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect
                                            id="Rectangle_226"
                                            data-name="Rectangle 226"
                                            width="15.004"
                                            height="20.372"
                                            transform="translate(-1 -1)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                    </clipPath>
                                </defs>
                                <g
                                    id="Group_341"
                                    data-name="Group 341"
                                    transform="translate(1 1)"
                                >
                                    <g
                                        id="Group_340"
                                        data-name="Group 340"
                                        transform="translate(0 0)"
                                        clip-path="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_325"
                                            data-name="Path 325"
                                            d="M6.5,18.372l-.237-.281A42.963,42.963,0,0,1,.62,9.224,6.351,6.351,0,0,1,0,6.463a6.5,6.5,0,0,1,13,0,6.375,6.375,0,0,1-.634,2.771,43.1,43.1,0,0,1-5.631,8.858ZM6.5.619A5.87,5.87,0,0,0,.619,6.463a5.749,5.749,0,0,0,.564,2.5A41.552,41.552,0,0,0,6.5,17.4a41.685,41.685,0,0,0,5.3-8.427,5.77,5.77,0,0,0,.578-2.513A5.87,5.87,0,0,0,6.5.619"
                                            transform="translate(0 0)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_326"
                                            data-name="Path 326"
                                            d="M13.406,16.448a3.406,3.406,0,1,1,3.406-3.406,3.41,3.41,0,0,1-3.406,3.406m0-6.193a2.787,2.787,0,1,0,2.787,2.787,2.79,2.79,0,0,0-2.787-2.787"
                                            transform="translate(-6.904 -6.653)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <p>{{ questionnaire.city }}</p>
                        </div>
                        <div
                            v-if="questionnaire.sex"
                            class="personal-basic-info__user-top-person-data-gender"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19.705"
                                height="20.062"
                                viewBox="0 0 19.705 20.062"
                            >
                                <g
                                    id="Group_349"
                                    data-name="Group 349"
                                    transform="translate(-110.047 -753.468)"
                                >
                                    <g
                                        id="Group_24"
                                        data-name="Group 24"
                                        transform="translate(110.047 756.945)"
                                    >
                                        <g
                                            id="Ellipse_5"
                                            data-name="Ellipse 5"
                                            transform="translate(0 0)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-width="1"
                                        >
                                            <ellipse
                                                cx="5.407"
                                                cy="5.407"
                                                rx="5.407"
                                                ry="5.407"
                                                stroke="none"
                                            />
                                            <ellipse
                                                cx="5.407"
                                                cy="5.407"
                                                rx="4.907"
                                                ry="4.907"
                                                fill="none"
                                            />
                                        </g>
                                        <line
                                            id="Line_23"
                                            data-name="Line 23"
                                            y1="5.677"
                                            transform="translate(5.542 10.408)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                        <line
                                            id="Line_24"
                                            data-name="Line 24"
                                            x2="4.055"
                                            transform="translate(3.379 13.382)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                    </g>
                                    <g
                                        id="Group_26"
                                        data-name="Group 26"
                                        transform="translate(115.711 753.969)"
                                    >
                                        <g
                                            id="Ellipse_5-2"
                                            data-name="Ellipse 5"
                                            transform="translate(0.001 2.368)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-width="1"
                                        >
                                            <ellipse
                                                cx="5.407"
                                                cy="5.407"
                                                rx="5.407"
                                                ry="5.407"
                                                stroke="none"
                                            />
                                            <ellipse
                                                cx="5.407"
                                                cy="5.407"
                                                rx="4.907"
                                                ry="4.907"
                                                fill="none"
                                            />
                                        </g>
                                        <line
                                            id="Line_23-2"
                                            data-name="Line 23"
                                            x1="4.055"
                                            y2="4.055"
                                            transform="translate(9.058 0.307)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                        <path
                                            id="Path_50"
                                            data-name="Path 50"
                                            d="M822.272,6398.362h3.463v3.1"
                                            transform="translate(-812.194 -6398.363)"
                                            fill="none"
                                            stroke="#d13724"
                                            stroke-linecap="round"
                                            stroke-width="1"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <p style="position: relative; left: -3px">
                                {{ setSex(questionnaire.sex) }}
                            </p>
                        </div>
                        <div
                            v-if="questionnaire.growth"
                            class="personal-basic-info__user-top-person-data-height"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="21.097"
                                height="26.128"
                                viewBox="0 0 21.097 26.128"
                            >
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect
                                            id="Rectangle_227"
                                            data-name="Rectangle 227"
                                            width="5.532"
                                            height="28.277"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                    </clipPath>
                                </defs>
                                <g
                                    id="Group_343"
                                    data-name="Group 343"
                                    transform="matrix(0.809, 0.588, -0.588, 0.809, 16.621, 0)"
                                >
                                    <g
                                        id="Group_342"
                                        data-name="Group 342"
                                        clip-path="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_327"
                                            data-name="Path 327"
                                            d="M27.063,180.972H25.651a.217.217,0,1,1,0-.435h1.412a.217.217,0,1,1,0,.435"
                                            transform="translate(-21.748 -154.375)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_328"
                                            data-name="Path 328"
                                            d="M20.355,165.352H17.806a.217.217,0,0,1,0-.435h2.549a.217.217,0,0,1,0,.435"
                                            transform="translate(-15.04 -141.019)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_329"
                                            data-name="Path 329"
                                            d="M27.063,149.731H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,0,1,0,.435"
                                            transform="translate(-21.748 -127.661)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_330"
                                            data-name="Path 330"
                                            d="M20.355,134.111H17.806a.217.217,0,0,1,0-.435h2.549a.217.217,0,0,1,0,.435"
                                            transform="translate(-15.04 -114.305)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_331"
                                            data-name="Path 331"
                                            d="M5.532,28.277H0V0H5.532Zm-5.1-.435H5.1V.435H.435Z"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_332"
                                            data-name="Path 332"
                                            d="M27.063,118.49H25.651a.217.217,0,1,1,0-.435h1.412a.217.217,0,1,1,0,.435"
                                            transform="translate(-21.748 -100.947)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_333"
                                            data-name="Path 333"
                                            d="M20.355,102.869H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435"
                                            transform="translate(-15.04 -87.59)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_334"
                                            data-name="Path 334"
                                            d="M27.063,87.249H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,0,1,0,.435"
                                            transform="translate(-21.748 -74.234)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_335"
                                            data-name="Path 335"
                                            d="M20.355,71.628H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435"
                                            transform="translate(-15.04 -60.876)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_338"
                                            data-name="Path 338"
                                            d="M20.355,71.628H17.806a.217.217,0,1,1,0-.435h2.549a.217.217,0,1,1,0,.435"
                                            transform="translate(-14.764 -66.022)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_336"
                                            data-name="Path 336"
                                            d="M27.063,56.008H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,1,1,0,.435"
                                            transform="translate(-21.748 -47.52)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                        <path
                                            id="Path_337"
                                            data-name="Path 337"
                                            d="M27.063,56.008H25.651a.217.217,0,0,1,0-.435h1.412a.217.217,0,1,1,0,.435"
                                            transform="translate(-21.472 -52.665)"
                                            fill="#d13724"
                                            stroke="#d13724"
                                            stroke-width="0.5"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <p>
                                {{ setGrowth(questionnaire.growth) }}
                            </p>
                        </div>
                        <div
                            class="personal-basic-info__user-top-person-data-children"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="22"
                                height="21"
                                viewBox="0 0 22 21"
                            >
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect
                                            id="Rectangle_229"
                                            data-name="Rectangle 229"
                                            width="22"
                                            height="21"
                                            transform="translate(0 -0.067)"
                                            fill="#d13724"
                                        />
                                    </clipPath>
                                </defs>
                                <g
                                    id="Group_347"
                                    data-name="Group 347"
                                    transform="translate(0 0.067)"
                                >
                                    <g
                                        id="Group_346"
                                        data-name="Group 346"
                                        clip-path="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_359"
                                            data-name="Path 359"
                                            d="M10.8,123.469a8.433,8.433,0,0,1-8.1-6.077H2.7a2.7,2.7,0,0,1,0-5.4h.462a.338.338,0,0,1,0,.675H2.7a2.026,2.026,0,1,0,0,4.051h.264a.338.338,0,0,1,.327.253,7.758,7.758,0,0,0,15.027,0,.338.338,0,0,1,.327-.253h.262a2.026,2.026,0,0,0,.006-4.051l-.448.018a.338.338,0,1,1-.027-.675l.455-.018h.013a2.7,2.7,0,0,1,0,5.4h0a8.434,8.434,0,0,1-8.1,6.077"
                                            transform="translate(0 -102.536)"
                                            fill="#d13724"
                                        />
                                        <path
                                            id="Path_360"
                                            data-name="Path 360"
                                            d="M161.652,60.758a.338.338,0,0,1-.335-.3,7.817,7.817,0,0,0-5.119-6.353.338.338,0,0,1,.225-.637,8.5,8.5,0,0,1,5.564,6.906.338.338,0,0,1-.293.377l-.042,0"
                                            transform="translate(-142.808 -48.942)"
                                            fill="#d13724"
                                        />
                                        <path
                                            id="Path_361"
                                            data-name="Path 361"
                                            d="M88.675,133.35a.675.675,0,1,1,.675-.675.676.676,0,0,1-.675.675m0-.675h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
                                            transform="translate(-80.572 -120.858)"
                                            fill="#d13724"
                                        />
                                        <path
                                            id="Path_362"
                                            data-name="Path 362"
                                            d="M152.675,133.35a.675.675,0,1,1,.675-.675.676.676,0,0,1-.675.675m0-.675h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
                                            transform="translate(-139.17 -120.858)"
                                            fill="#d13724"
                                        />
                                        <path
                                            id="Path_363"
                                            data-name="Path 363"
                                            d="M102.436,189.28a4.46,4.46,0,0,1-2.286-.662.338.338,0,0,1,.374-.562,3.441,3.441,0,0,0,3.678,0,.338.338,0,0,1,.375.562,3.8,3.8,0,0,1-2.14.662"
                                            transform="translate(-91.559 -172.131)"
                                            fill="#d13724"
                                        />
                                        <path
                                            id="Path_364"
                                            data-name="Path 364"
                                            d="M29.081,11.817l-.042,0a.338.338,0,0,1-.293-.377A8.408,8.408,0,0,1,36.115,4.12a2.728,2.728,0,0,0,1.269-.488A2.03,2.03,0,0,0,38.44,1.965,1.289,1.289,0,0,0,37.157.675h0a.928.928,0,0,0-.671.308.921.921,0,0,0-.276.785.7.7,0,0,0,.383.5.338.338,0,0,1-.294.608,1.381,1.381,0,0,1-.752-.977,1.576,1.576,0,0,1,.44-1.368A1.592,1.592,0,0,1,37.157,0h0a1.96,1.96,0,0,1,1.958,1.953A2.672,2.672,0,0,1,37.777,4.18a3.406,3.406,0,0,1-1.583.61,7.734,7.734,0,0,0-6.779,6.731.338.338,0,0,1-.335.3"
                                            transform="translate(-26.317)"
                                            fill="#d13724"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <p>
                                {{
                                    questionnaire.childrenHas === 'Tak' ||
                                    questionnaire.childrenHas == 1 ||
                                    questionnaire.childrenHas == '1'
                                        ? 'Mam dzieci'
                                        : 'Nie mam dzieci'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { useMatchStore } from '@/stores/match'
    const store = useMatchStore()
    import { ref, computed, onMounted } from 'vue'

    const props = defineProps({
        data: Object,
        removeImages: Boolean,
        hasGallery: {
            Type: Boolean,
            default: true
        }
    })

    const currentPhotoIndex = ref(0)
    const lightboxVisible = ref(false)
    const photos = ref([])

    const user = computed(() => props.data.user)
    const currentPhoto = computed(() => photos.value[currentPhotoIndex.value])
    const questionnaire = computed(() =>
        props.data.questionnaires ? props.data.questionnaires[0] : null
    )

    const navigatePhoto = direction => {
        currentPhotoIndex.value += direction
        if (currentPhotoIndex.value < 0) {
            currentPhotoIndex.value = photos.value.length - 1
        } else if (currentPhotoIndex.value >= photos.value.length) {
            currentPhotoIndex.value = 0
        }
    }

    const openLightbox = index => {
        lightboxVisible.value = true
        currentPhotoIndex.value = index
    }

    const handleEscape = () => {
        console.log('handle escape')
        lightboxVisible.value = false
    }

    const setAge = el => {
        const date = new Date()
        const birthDate = new Date(el)

        let age = date.getFullYear() - birthDate.getFullYear()

        const month = date.getMonth()
        const day = date.getDate()
        const birthMonth = birthDate.getMonth()
        const birthDay = birthDate.getDate()

        if (month < birthMonth || (month === birthMonth && day < birthDay)) {
            age--
        }

        return age
    }

    const setSex = value => {
        switch (value) {
            case '0':
                return 'Kobieta'
            case '1':
                return 'Mężczyzna'
            case '2':
                return 'Osoba Niebinarna'
            default:
                return value
        }
    }

    const setGrowth = value => {
        let metric
        if (value) {
            value.length >= 3 && !value.includes('.')
                ? (metric = 'cm')
                : (metric = 'm')
            return `${value} ${metric}`
        }
    }

    onMounted(() => {
        photos.value = [
            props.data.user.photo1,
            props.data.user.photo2,
            props.data.user.photo3,
            props.data.user.photo4
        ].filter(Boolean)
    })
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .profile {
        &__img {
            &--border {
                border: 6px solid $pink;
            }
            &--unactive {
                cursor: auto;
            }
        }
        &__flex-images {
            display: flex;
            gap: 10px;
            width: 100%;
            margin-top: 20px;
            justify-content: center;
            @include breakpoint-max('xs-tablet') {
                margin-bottom: 15px;
            }
            div {
                width: 86px;
                cursor: pointer !important;
                img {
                    border-width: 1px !important;
                }
            }
        }
    }

    .personal-basic-info {
        &__user-top-wrapper {
            display: flex;
            @include breakpoint-max('xs-tablet') {
                flex-direction: column;
            }
        }
        &__save-images {
            text-align: center;
            button {
                border: none;
                outline: none;
                border: 1px solid $black;
                background-color: #e1dbd5;
                padding: 15px 0;
                width: 200px;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                }
            }
        }
        &__infoPopup {
            text-align: center;
            color: $pink;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
        }
        &__pop-close {
            position: absolute;
            right: 10px;
            top: 10px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: $white;
        }
        &__edit-images-popup {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70vw;
            min-height: 20vh;
            background-color: #f6f4f0;
            border: 1px solid #000;
            z-index: 99;
            .save-profile__photos-flex {
                padding: 20px;
            }
            h3 {
                text-align: center;
                background-color: #d13724;
                color: $white;
                padding: 10px 80px;
            }
        }
        .no-image {
            margin-top: 30px;
        }
        &__user-top-person-name {
            padding: 8px 36px 8px 59px;
            background-color: $pink;
            color: $white;
            font-size: 3rem;
            line-height: 4.5rem;
            font-weight: 700;
            text-transform: capitalize;
            font-family: 'Open Sans', sans-serif;
            box-shadow: 0px 3px 6px #00000029;
            margin-bottom: 41px;
            @include breakpoint-max('xs-tablet') {
                padding: 6px 20px;
            }
            @include breakpoint-max('mobile') {
                padding: 4px 10px;
                font-size: 2rem;
            }
        }
        &__user-top-person-data {
            padding-left: 51px;
            div {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 26px;
                    @include breakpoint-max('mobile') {
                        margin-right: 15px;
                        max-width: 15px;
                    }
                }
                p {
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-family: 'Open Sans', sans-serif;
                }
                @include breakpoint-max('mobile') {
                    margin-bottom: 0px;
                }
                @include breakpoint-max('xs-tablet') {
                    width: fit-content;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-items: center;
                row-gap: 23px;
            }
        }
        &__user-top-person-data-height {
            p {
                text-transform: lowercase !important;
            }
        }
        &__user-top-person-data-children {
            p {
                text-transform: none !important;
            }
        }
        &__edit-images {
            text-align: center;
            background-color: $pink;
            padding: 10px;
            color: $white;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .gallery {
        &__thumbnails {
            figure {
                display: block;
                img {
                    // cursor: pointer;
                    display: block;
                    height: auto;
                    width: 280px;
                    height: 280px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    object-position: center;
                    border-radius: 0 !important;
                    border: 1px solid $black;
                    @include breakpoint-max('xs-tablet') {
                        width: 100%;
                        height: auto;
                    }
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                }
                @include breakpoint-max('xs-tablet') {
                    margin-bottom: 26px;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 30px;
            }
        }
    }
    .lightbox {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            max-width: 80vw; /* 80% szerokości widoku */
            max-height: 80vh; /* 80% wysokości widoku */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            max-width: 95vw;
            max-height: 95vh;
            object-fit: contain !important;
        }
    }
    .lightbox__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .lightbox__nav {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
    .lightbox__nav--left {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }
    .lightbox__nav--right {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }
    .lightbox__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
</style>
