<template>
    <div class="admin-to-verify admin-to-verify">
        <div class="admin-to-verify__overlay" v-if="popup"></div>
        <div class="admin-to-verify__scale-modal" v-if="scaleModal">
            <!-- {{ questionnaire }} -->
            <button class="btn btn__default mt-10" @click="scaleModal = false">
                Zamknij
            </button>
            <form class="admin-to-verify__scale-grid">
                <div>
                    <fieldset class="red">
                        <legend>SW</legend>

                        <div class="admin-to-verify__scale-flex">
                            <div>
                                <input
                                    type="checkbox"
                                    id="sw1"
                                    name="sw"
                                    value="1"
                                    v-model="personalSw"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="sw2"
                                    name="sw"
                                    value="2"
                                    v-model="personalSw"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="sw3"
                                    name="sw"
                                    value="3"
                                    v-model="personalSw"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="sw4"
                                    name="sw"
                                    value="4"
                                    v-model="personalSw"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="sw5"
                                    name="sw"
                                    value="5"
                                    v-model="personalSw"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>SW</legend>

                        <div class="admin-to-verify__scale-flex">
                            <div>
                                <input
                                    type="checkbox"
                                    id="sw2"
                                    name="sw2"
                                    value="1"
                                    v-model="matchSw"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="sw2"
                                    name="sw2"
                                    value="2"
                                    v-model="matchSw"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="sw2"
                                    name="sw2"
                                    value="3"
                                    v-model="matchSw"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="sw2"
                                    name="sw2"
                                    value="4"
                                    v-model="matchSw"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="sw2"
                                    name="sw2"
                                    value="5"
                                    v-model="matchSw"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="red">
                        <legend>SPŻ</legend>

                        <div class="admin-to-verify__scale-flex">
                            <div>
                                <input
                                    type="checkbox"
                                    id="spz"
                                    name="spz"
                                    value="1"
                                    v-model="personalSpz"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spz"
                                    name="spz"
                                    value="2"
                                    v-model="personalSpz"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spz"
                                    name="spz"
                                    value="3"
                                    v-model="personalSpz"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spz"
                                    name="spz"
                                    value="4"
                                    v-model="personalSpz"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spz"
                                    name="spz"
                                    value="5"
                                    v-model="personalSpz"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>SPŻ</legend>

                        <div class="admin-to-verify__scale-flex">
                            <div>
                                <input
                                    type="checkbox"
                                    id="spz2"
                                    name="spz2"
                                    value="1"
                                    v-model="matchSpz"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spz2"
                                    name="spz2"
                                    value="2"
                                    v-model="matchSpz"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spz2"
                                    name="spz2"
                                    value="3"
                                    v-model="matchSpz"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spz2"
                                    name="spz2"
                                    value="4"
                                    v-model="matchSpz"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spz2"
                                    name="spz2"
                                    value="5"
                                    v-model="matchSpz"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="red">
                        <legend>SPP</legend>

                        <div class="admin-to-verify__scale-flex">
                            <div>
                                <input
                                    type="checkbox"
                                    id="spp"
                                    name="spp"
                                    value="1"
                                    v-model="personalSpp"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spp"
                                    name="spp"
                                    value="2"
                                    v-model="personalSpp"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spp"
                                    name="spp"
                                    value="3"
                                    v-model="personalSpp"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spp"
                                    name="spp"
                                    value="4"
                                    v-model="personalSpp"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spp"
                                    name="spp"
                                    value="5"
                                    v-model="personalSpp"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>SPP</legend>

                        <div class="admin-to-verify__scale-flex">
                            <div>
                                <input
                                    type="checkbox"
                                    id="spp2"
                                    name="spp2"
                                    value="1"
                                    v-model="matchSpp"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spp2"
                                    name="spp2"
                                    value="2"
                                    v-model="matchSpp"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spp2"
                                    name="spp2"
                                    value="3"
                                    v-model="matchSpp"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spp2"
                                    name="spp2"
                                    value="4"
                                    v-model="matchSpp"
                                />
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="spp2"
                                    name="spp2"
                                    value="5"
                                    v-model="matchSpp"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="red">
                        <legend>Cechy</legend>

                        <div>
                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance"
                                    value="N"
                                    name="appearance"
                                    v-model="personalAppearance"
                                />
                                <label for="appearance">N</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance"
                                    value="O"
                                    name="appearance"
                                    v-model="personalAppearance"
                                />
                                <label for="appearance">O</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance"
                                    value="L"
                                    name="appearance"
                                    v-model="personalAppearance"
                                />
                                <label for="appearance">Ł</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance"
                                    value="OR"
                                    name="appearance"
                                    v-model="personalAppearance"
                                />
                                <label for="appearance">OR</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance"
                                    value="Brak"
                                    name="appearance"
                                    v-model="personalAppearance"
                                />
                                <label for="appearance">Brak</label>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>Cechy</legend>

                        <div>
                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance2"
                                    value="N"
                                    v-model="matchAppearance"
                                />
                                <label for="appearance2">N</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance21"
                                    value="O"
                                    v-model="matchAppearance"
                                />
                                <label for="appearance21">O</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance22"
                                    value="L"
                                    v-model="matchAppearance"
                                />
                                <label for="appearance22">Ł</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance23"
                                    value="OR"
                                    v-model="matchAppearance"
                                />
                                <label for="appearance23">OR</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="appearance24"
                                    value="Brak"
                                    v-model="matchAppearance"
                                />
                                <label for="appearance24">Brak</label>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="red">
                        <legend>Dzieci</legend>

                        <div>
                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    value="CH"
                                    id="childrens"
                                    name="childrens"
                                    v-model="personalChildren"
                                />
                                <label for="childrens">CH</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    value="NCH"
                                    id="childrens"
                                    name="childrens"
                                    v-model="personalChildren"
                                />
                                <label for="childrens">NCH</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    value="NW"
                                    id="childrens"
                                    name="childrens"
                                    v-model="personalChildren"
                                />
                                <label for="childrens">NW</label>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>Dzieci</legend>

                        <div>
                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    value="CH"
                                    id="childrens2"
                                    v-model="matchChildren"
                                />
                                <label for="childrens2">CH</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    value="NCH"
                                    id="childrens21"
                                    v-model="matchChildren"
                                />
                                <label for="childrens21">NCH</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    value="NW"
                                    id="childrens22"
                                    v-model="matchChildren"
                                />
                                <label for="childrens22">NW</label>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div>
                    <fieldset>
                        <legend>Wiara</legend>

                        <div>
                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="faith"
                                    value="1"
                                    v-model="matchFaith"
                                />
                                <label for="faith">WP</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="faith2"
                                    value="2"
                                    v-model="matchFaith"
                                />
                                <label for="faith2">WN</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="faith3"
                                    value="3"
                                    v-model="matchFaith"
                                />
                                <label for="faith3">NN</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="faith4"
                                    value="4"
                                    v-model="matchFaith"
                                />
                                <label for="faith4">NP</label>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>Zarobki</legend>

                        <div>
                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="earnings"
                                    value="1"
                                    v-model="matchEarnings"
                                />
                                <label for="earnings">05</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="earnings2"
                                    value="2"
                                    v-model="matchEarnings"
                                />
                                <label for="earnings2">51</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="earnings3"
                                    value="3"
                                    v-model="matchEarnings"
                                />
                                <label for="earnings3">12</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="earnings4"
                                    value="4"
                                    v-model="matchEarnings"
                                />
                                <label for="earnings4">25</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="earnings5"
                                    value="5"
                                    v-model="matchEarnings"
                                />
                                <label for="earnings5">>5</label>
                            </div>

                            <div class="admin-to-verify__scale-center">
                                <input
                                    type="checkbox"
                                    id="earnings6"
                                    value="6"
                                    v-model="matchEarnings"
                                />
                                <label for="earnings6">bd</label>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>Wzrost (np. 1,87)</legend>

                        <div>
                            <input
                                type="text"
                                placeholder="od"
                                v-model="matchHeightFrom"
                            />
                            <input
                                type="text"
                                placeholder="do"
                                v-model="matchHeightTo"
                            />
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>Wiek</legend>

                        <div>
                            <input
                                type="number"
                                placeholder="od"
                                v-model="matchAgeFrom"
                            />
                            <input
                                type="number"
                                placeholder="do"
                                v-model="matchAgeTo"
                            />
                        </div>
                    </fieldset>
                </div>
            </form>
            <p v-if="error">{{ error }}</p>
            <button @click="submitForm" class="btn btn__default mt-10">
                Zapisz
            </button>
        </div>
        <div class="admin-users-view__wrapper">
            <div class="admin-to-verify__left">
                <div class="admin-to-verify__left-top">
                    <div class="admin-to-verify__left-top-l">
                        <router-link
                            class="admin-to-verify__left-prev"
                            v-if="linkPrev"
                            :to="linkPrev"
                        >
                            Powrót
                        </router-link>
                        <router-link
                            class="admin-to-verify__left-prev"
                            v-else
                            to="/admin/basic/Warszawa/1"
                        >
                            Powrót
                        </router-link>
                        <!-- to delete -->
                        <button
                            class="btn btn__default mt-10"
                            @click="sendMailWithInfo()"
                        >
                            Wyślij maila z komunikatem
                        </button>
                        <button
                            class="btn btn__default mt-10"
                            @click="scaleModal = true"
                        >
                            Uzupełnij skale
                        </button>
                        <p style="margin-top: 10px" v-if="showInfo">
                            {{ showInfo }}
                        </p>

                        <div
                            class="flex items-center justify-center gap-10 mt-10"
                        >
                            <p>Ilość wysłanych maili : {{ user.emailCount }}</p>
                        </div>
                        <div v-if="temporaryMatches">
                            <p>Liczba matchy: {{ temporaryMatches.count }}</p>
                            <p>
                                Ostatni match:
                                {{ formattedDate(temporaryMatches.latestDate) }}
                            </p>
                        </div>
                        <div
                            v-if="user.emailCount > 0"
                            class="flex items-center justify-center gap-10 mt-10"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-clock-hour-10"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path
                                    d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"
                                />
                                <path d="M12 12l-3 -2" />
                                <path d="M12 7v5" />
                            </svg>
                            <p>
                                Ostatnio wysłano :
                                {{ formattedDate(user.lastEmailSend) }}
                            </p>
                        </div>
                    </div>
                    <div class="admin-to-verify__right-user-heading">
                        <h2>Dopasowania:</h2>
                        <div class="admin-to-verify__right-user-heading-boxes">
                            <router-link
                                :to="'/admin/basic/verify/' + user._id"
                            >
                                <figure>
                                    <div
                                        class="admin-to-verify__right-user-heading-boxes-first"
                                    ></div>
                                </figure>
                                <p>Do weryfikacji</p>
                            </router-link>
                            <router-link
                                :to="'/admin/basic/accepted/' + user._id"
                            >
                                <figure>
                                    <img
                                        src="../assets/images/ludzik1.png"
                                        alt="Photo"
                                    />
                                </figure>
                                <p>Zaakceptowane</p>
                            </router-link>
                            <router-link
                                :to="'/admin/basic/cancelled/' + user._id"
                            >
                                <figure>
                                    <img
                                        src="../assets/images/ludzik1.png"
                                        alt="Photo"
                                    />
                                </figure>
                                <p>Rezygnacja</p>
                            </router-link>
                            <!-- <router-link :to="'/admin/plus/rejected/' + user._id">
                                <figure>
                                    <img  src="../assets/images/ludzik1.png" alt="Photo">
                                </figure>
                                <p>Odrzucone</p>
                            </router-link> -->
                        </div>
                    </div>
                </div>
                <div class="admin-to-verify__loader-wrapper" v-if="loading">
                    <Loader />
                </div>
                <div v-if="!loading">
                    <div class="admin-to-verify__wrapper">
                        <div class="admin-to-verify__user">
                            <PersonalBasicsInfoCleaner
                                v-if="photosUpdated"
                                :wantedGender="questionnaire.wantedGender"
                                :cityPossible="questionnaire.cityPossible"
                                :movePossibility="questionnaire.movePossibility"
                                :city="questionnaire.city"
                                :images="userPhotos"
                                :name="questionnaire.name"
                                :age="questionnaire.age"
                                :job="questionnaire.job"
                                :ageDifference="questionnaire.ageDifference"
                                :sex="questionnaire.sex"
                                :growth="questionnaire.growth"
                                :growthComment="questionnaire.growthComment"
                                :children="questionnaire.childrenHas"
                                :childrenWanted="questionnaire.childrenWanted"
                                :religion="questionnaire.religion"
                                :religionMatter="questionnaire.religionMatter"
                                :religionPractice="
                                    questionnaire.religionPractice
                                "
                                :religionPracticeMatter="
                                    questionnaire.religionPracticeMatter
                                "
                                :petsAttitude="questionnaire.petsAttitude"
                                :petsHas="questionnaire.petsHas"
                                :divorcedMatter="questionnaire.divorcedMatter"
                                :additionalInfo="questionnaire.additionalInfo"
                                :appearanceNoAcceptation="
                                    questionnaire.appearanceNoAcceptation
                                "
                                :profileDescription="
                                    questionnaire.profileDescription
                                "
                                :materialStatus="questionnaire.materialStatus"
                                :income="questionnaire.income"
                                :additionalPartnerInfo="
                                    questionnaire.additionalPartnerInfo
                                "
                                :specialAppearance="
                                    questionnaire.specialAppearance
                                "
                                :growthMatter="questionnaire.growthMatter"
                            />
                        </div>
                    </div>
                    <div>
                        <div class="admin-to-verify__right-user">
                            <div
                                v-if="
                                    !hasUserCurrentlyTemporaryMatch &&
                                    !hasUserCurrentlyMatch
                                "
                                class="admin-to-verify__right-user-matches"
                            >
                                <MatchesUsers
                                    :matches="matches"
                                    :userQuestionaire="questionnaire"
                                />
                            </div>
                            <div
                                v-else
                                class="admin-to-verify__matches-not-allowed"
                            >
                                <p>
                                    Użytkownik posiada aktualnie Matcha z innym
                                    userem lub jest w trakcie dopasowania
                                    (temporary match) z innym userem.
                                    Dopasowywanie zostało wyłączone
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import SingleMatchView from '@/components/atoms/SingleMatchView.vue'
    import PersonalBasicsInfoCleaner from '../components/atoms/PersonalBasicsInfoCleaner.vue'
    import QuestionnaireFirstPart from '../components/atoms/QuestionnaireFirstPart.vue'
    import QuestionnaireSecondPart from '../components/atoms/QuestionnaireSecondPart.vue'
    import QuestionnaireThirdPart from '../components/atoms/QuestionnaireThirdPart.vue'
    import QuestionnaireFourthPart from '../components/atoms/QuestionnaireFourthPart.vue'
    import QuestionnaireFifthPart from '../components/atoms/QuestionnaireFifthPart.vue'
    import QuestionnaireSixthPart from '../components/atoms/QuestionnaireSixthPart.vue'
    import QuestionnaireSeventhPart from '../components/atoms/QuestionnaireSeventhPart.vue'
    import SingleMatchToggleView from '../components/atoms/SingleMatchToggleView.vue'
    import MatchesUsers from '../components/admin/MatchesUsers.vue'
    import Loader from '@/components/atoms/Loader.vue'

    export default {
        name: 'single user',
        data() {
            return {
                scaleModal: false,
                linkPrev: '',
                user: [],
                matches: [],
                questionnaire: [],
                temporarymatch: null,
                temporaryMatches: null,
                match: null,
                flagTrimmed: 0,
                verifications: [],
                popup: false,
                //
                partOne: [],
                partOneSocials: [],
                partTwo: [],
                partThree: [],
                partFourth: [],
                partFifth: [],
                partSixth: [],
                partSeventh: [],
                matchedUsers: [],
                photosUpdated: false,
                loading: true,
                //
                flagMap: {
                    0: { color: 'red', text: 'Czerwona' },
                    1: { color: 'yellow', text: 'Żółta' },
                    2: { color: 'blue', text: 'Niebieska' },
                    3: { color: 'green', text: 'Zielona' },
                    default: { color: 'red', text: 'Czerwona' }
                },
                data: {
                    image: require('@/assets/images/hero-girl.png')
                },
                currentPhotoIndex: 0,
                lightboxVisible: false,
                userPhotos: [],
                showInfo: '',
                //
                personalSw: [],
                personalSpz: [],
                personalSpp: [],
                personalAppearance: [],
                personalChildren: [],
                matchSw: [],
                matchSpz: [],
                matchSpp: [],
                matchAppearance: [],
                matchFaith: [],
                matchChildren: [],
                matchEarnings: [],
                matchHeightFrom: null,
                matchHeightTo: null,
                matchAgeFrom: null,
                matchAgeTo: null,
                error: ''
            }
        },
        components: {
            SingleMatchView,
            PersonalBasicsInfoCleaner,
            QuestionnaireFirstPart,
            QuestionnaireSecondPart,
            QuestionnaireThirdPart,
            QuestionnaireFourthPart,
            QuestionnaireFifthPart,
            QuestionnaireSixthPart,
            QuestionnaireSeventhPart,
            SingleMatchToggleView,
            MatchesUsers,
            Loader
        },
        mounted() {
            document.addEventListener('keydown', this.handleKeydown)
            document.addEventListener('keydown', this.handleKeydownExit)
            this.linkPrev = localStorage.getItem('adminBasicPrev')
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.handleKeydown)
            document.removeEventListener('keydown', this.handleKeydownExit)
        },
        methods: {
            submitForm() {
                // Sprawdzanie, czy wszystkie wartości nie są puste
                if (
                    !this.personalSw ||
                    !this.personalSpz ||
                    !this.personalSpp ||
                    this.personalAppearance.length === 0 ||
                    this.personalChildren.length === 0 ||
                    !this.matchSw ||
                    !this.matchSpz ||
                    !this.matchSpp ||
                    this.matchAppearance.length === 0 ||
                    this.matchFaith.length === 0 ||
                    this.matchChildren.length === 0 ||
                    this.matchEarnings.length === 0 ||
                    !this.matchHeightFrom ||
                    !this.matchHeightTo ||
                    !this.matchAgeFrom ||
                    !this.matchAgeTo
                ) {
                    this.error = 'Proszę uzupełnić wszystkie pola.'
                    return
                }

                const formData = {
                    userId: this.$route.params.id,
                    personalSw: this.personalSw,
                    personalSpz: this.personalSpz,
                    personalSpp: this.personalSpp,
                    personalAppearance: this.personalAppearance,
                    personalChildren: this.personalChildren,
                    matchSw: this.matchSw,
                    matchSpz: this.matchSpz,
                    matchSpp: this.matchSpp,
                    matchAppearance: this.matchAppearance,
                    matchFaith: this.matchFaith,
                    matchChildren: this.matchChildren,
                    matchEarnings: this.matchEarnings,
                    matchHeightFrom: this.matchHeightFrom,
                    matchHeightTo: this.matchHeightTo,
                    matchAgeFrom: this.matchAgeFrom,
                    matchAgeTo: this.matchAgeTo
                }

                axios
                    .post(
                        `${this.url}api/users/questionnaire-scale-edit`,
                        formData
                    )
                    .then(response => {
                        alert('Zapisano pomyślnie.')
                        this.scaleModal = false
                    })
                    .catch(error => {
                        console.error(error)
                        this.error = 'An error occurred while saving data.'
                    })
            },
            formattedDate(val) {
                const dateString = val
                const date = new Date(val)

                // Step 2: Extract the year, month, and day
                const year = date.getFullYear()
                const month = String(date.getMonth() + 1).padStart(2, '0') // getMonth() returns 0-11, so add 1
                const day = String(date.getDate()).padStart(2, '0') // getDate() returns the day of the month

                // Step 3: Format into YYYY-MM-DD
                const formattedDate = `${year}-${month}-${day}`
                return formattedDate
            },
            async sendMailWithInfo() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                try {
                    await axios
                        .put(
                            `${this.url}api/users/send-email-about-showing-in-higher-profile/${this.user._id}`,
                            config
                        )
                        .then(res => {
                            if (res.statusText == 'OK') {
                                this.showInfo = 'Wysłano'
                            }
                            console.log(res)
                        })
                        .catch(error => {
                            console.log(error)
                        })
                } catch (error) {
                    console.log(error)
                }
                this.allowQuestionnaire = false
            },
            setGrowth(el) {
                let metric
                if (el) {
                    el.length >= 3 && !el.includes('.')
                        ? (metric = 'cm')
                        : (metric = 'm')
                    return `${el} ${metric}`
                }
            },
            setSex(el) {
                switch (el) {
                    case 0:
                        return 'Kobieta'
                    case 1:
                        return 'Mężczyzna'
                    case 2:
                        return 'Niebinarna'
                    default:
                }
            },
            checkVerifications() {
                if (this.verifications && this.verifications.length > 0) {
                    const lastVerification =
                        this.verifications[this.verifications.length - 1]
                    return (
                        lastVerification && lastVerification.resolved === true
                    )
                }
                return true
            },
            addVerify() {
                axios
                    .post(`${this.url}api/verify/`, {
                        userid: this.$route.params.id
                    })
                    .then(() => {
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            decline() {
                axios
                    .post(`${this.url}api/verify/decline`, {
                        userid: this.$route.params.id,
                        reason: this.reason
                    })
                    .then(() => {
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            formatDateWithAge(isoDate) {
                try {
                    let birthDate = new Date(isoDate)
                    if (isNaN(birthDate.getTime())) {
                        // Data nie jest prawidłową datą
                        return 'Nieprawidłowa data'
                    }

                    let currentDate = new Date()
                    let currentYear = currentDate.getFullYear()
                    let age = currentYear - birthDate.getFullYear()

                    if (
                        currentDate.getMonth() < birthDate.getMonth() ||
                        (currentDate.getMonth() === birthDate.getMonth() &&
                            currentDate.getDate() < birthDate.getDate())
                    ) {
                        age--
                    }

                    return `${
                        birthDate.toISOString().split('T')[0]
                    } (${age} lat)`
                } catch (e) {
                    // Błąd podczas przetwarzania daty
                    return 'Nieprawidłowa data'
                }
            },
            openLightbox(index) {
                this.currentPhotoIndex = index
                this.lightboxVisible = true
            },
            navigatePhoto(direction) {
                this.currentPhotoIndex += direction
                if (this.currentPhotoIndex < 0) {
                    this.currentPhotoIndex = this.userPhotos.length - 1
                } else if (this.currentPhotoIndex >= this.userPhotos.length) {
                    this.currentPhotoIndex = 0
                }
            },
            trimUserFlag() {
                if (this.user.flag) {
                    this.flagTrimmed = this.user.flag.trim()
                }
            },
            getClass(userId) {
                for (let i = 0; i < this.temporarymatch.length; i++) {
                    if (this.temporarymatch[i].secondUserId === userId) {
                        return 'admin-to-verify__btn--connected'
                    }
                }
                return null
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getTemporaryMatches() {
                const authToken = this.getCookie('authToken')

                // Ustawienie nagłówka Authorization
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }

                axios
                    .get(
                        `${this.url}api/temporarymatch/count/${this.$route.params.id}`,
                        config
                    )
                    .then(resp => {
                        this.temporaryMatches = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getMatchedQuestionnaires() {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }

                axios
                    .get(
                        `${this.url}api/questionnaire/matched-questionnaires/${this.$route.params.id}`,
                        config
                    )
                    .then(resp => {
                        this.matches = resp.data.matchedQuestionnaires
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getUsers() {
                const authToken = this.getCookie('authToken')

                // Ustawienie nagłówka Authorization
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }

                axios
                    .get(
                        `${this.url}api/questionnaire/singleuser/${this.$route.params.id}`,
                        config
                    )
                    .then(resp => {
                        this.user = resp.data.user
                        // this.matches = resp.data.matchedQuestionnaires
                        this.questionnaire = resp.data.questionnaire
                        this.userPhotos = [
                            this.user.photo1,
                            this.user.photo2,
                            this.user.photo3,
                            this.user.photo4
                        ].filter(Boolean)
                        this.photoUpdated = true
                        if (this.userPhotos.length > 0) {
                            this.currentPhoto = this.userPhotos[0]
                        }
                        this.photosUpdated = true
                        this.setValuesPartOne(
                            resp.data.questionnaire,
                            resp.data.user
                        )

                        const propertiesToCheck = [
                            'personalSw',
                            'personalSpz',
                            'personalSpp',
                            'personalAppearance',
                            'personalChildren',
                            'matchSw',
                            'matchSpz',
                            'matchSpp',
                            'matchAppearance',
                            'matchFaith',
                            'matchChildren',
                            'matchEarnings',
                            'matchHeightFrom',
                            'matchHeightTo',
                            'matchAgeFrom',
                            'matchAgeTo'
                        ]

                        propertiesToCheck.forEach(property => {
                            if (
                                resp.data.questionnaire.hasOwnProperty(
                                    property
                                ) &&
                                resp.data.questionnaire[property] !==
                                    undefined &&
                                resp.data.questionnaire[property] !== null
                            ) {
                                if (
                                    Array.isArray(
                                        resp.data.questionnaire[property]
                                    ) &&
                                    resp.data.questionnaire[property].length ===
                                        0
                                ) {
                                    return
                                }
                                this[property] =
                                    resp.data.questionnaire[property]
                            }
                        })

                        this.setValuesPartTwo(resp.data.questionnaire)
                        this.setValuesPartThree(resp.data.questionnaire)
                        this.setValuesPartFourth(resp.data.questionnaire)
                        this.setValuesPartFifth(resp.data.questionnaire)
                        this.setValuesPartSixth(resp.data.questionnaire)
                        this.setValuesPartSeventh(resp.data.questionnaire)
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            setValuesPartOne(questionnaire, user) {
                this.partOne.push({ text: 'Imię', value: questionnaire.name })
                this.partOne.push({
                    text: 'Nazwisko',
                    value: questionnaire.surname
                })
                this.partOne.push({ text: 'E-mail', value: user.email })
                this.partOne.push({
                    text: 'Telefon',
                    value: questionnaire.phone
                })
                this.partOne.push({
                    text: 'Miasto zamieszkania',
                    value: questionnaire.city
                })
                this.partOne.push({
                    text: 'Miasto randkowania',
                    value: questionnaire.cityPossible
                })

                if (questionnaire.facebook) {
                    this.partOneSocials.push({
                        text: 'Facebook',
                        value: questionnaire.facebook
                    })
                }
                if (questionnaire.instagram) {
                    this.partOneSocials.push({
                        text: 'Instagram',
                        value: questionnaire.instagram
                    })
                }
                if (questionnaire.linkedin) {
                    this.partOneSocials.push({
                        text: 'LinkedIn',
                        value: questionnaire.linkedin
                    })
                }
                if (questionnaire.website) {
                    this.partOneSocials.push({
                        text: 'Strona www',
                        value: questionnaire.website
                    })
                }
            },
            setValuesPartTwo(questionnaire) {
                this.partTwo.push({
                    text: 'Nawiązanie nowych znajomości:',
                    value: questionnaire.openToNewPeople,
                    answers: ['Przychodzi mi łatwo', 'Nie przychodzi mi łatwo']
                })
                this.partTwo.push({
                    text: 'Czy jesteś osobą otwartą?',
                    value: questionnaire.openToNewThings,
                    answers: ['Tak', 'Raczej tak', 'Nie']
                })
                this.partTwo.push({
                    text: 'Czy jesteś osobą, która potrzebuje dużo wrażeń?',
                    value: questionnaire.lotOfImpressions,
                    answers: [
                        'Zdecydowanie tak',
                        'Raczej tak',
                        'Raczej nie',
                        'Zdecydowanie nie'
                    ]
                })
                this.partTwo.push({
                    text: 'Jestem osobą:',
                    value: questionnaire.characterTraits
                })
                this.partTwo.push({
                    text: 'Mówienie o emocjach i uczuciach:',
                    value: questionnaire.feelingsTalking,
                    answers: [
                        'Jest dla mnie wyzwaniem',
                        'Przychodzi mi dość trudno',
                        'Przychodzi mi dość łatwo',
                        'Nie jest dla mnie wyzwaniem'
                    ]
                })
                this.partTwo.push({
                    text: 'Jak lubisz spędzać wolny czas?',
                    value: questionnaire.freeTime,
                    answers: [
                        'Zdecydowanie aktywnie',
                        'Cenię balans, łączę aktywność i odpoczynek',
                        'Relaksując się, mało aktywnie'
                    ]
                })
                this.partTwo.push({
                    text: 'Czy masz zwierzę lub zwierzęta domowe?',
                    value: questionnaire.petsHas,
                    answers: [
                        'Tak',
                        'Nie, ale chcę mieć',
                        'Nie i nie chcę mieć'
                    ]
                })
                this.partTwo.push({
                    text: 'Jaki jest Twój stosunek do zwierząt?',
                    value: questionnaire.petsAttitude,
                    answers: [
                        'Lubię zwierzaki',
                        'Nie przepadam',
                        'Zdecydowanie nie przepadam'
                    ]
                })
                this.partTwo.push({
                    text: 'Czy jest coś, co szczególnie Cię pasjonuje?',
                    value: questionnaire.hobby
                })
            },
            setValuesPartThree(questionnaire) {
                this.partThree.push({
                    text: 'Ile masz lat?',
                    value: this.setAge(questionnaire.age)
                })
                this.partThree.push({
                    text: 'Jaka różnica wieku w relacji jest dla Ciebie akceptowalna?',
                    value: questionnaire.ageDifference,
                    answers: [
                        'do 5 lat',
                        'do 10 lat',
                        'do 15 lat',
                        'powyżej 15 lat'
                    ]
                })
                this.partThree.push({
                    text: 'Dodatkowa uwaga dotycząca wieku:',
                    value: questionnaire.ageAdditionalComment
                })
                this.partThree.push({
                    text: 'Jaki zawód wykonujesz?',
                    value: questionnaire.job
                })
                this.partThree.push({
                    text: 'Czy Twoja praca jest pod jakimś względem bardzo wymagająca?',
                    value: questionnaire.demandingJob,
                    answers: ['Tak', 'Nie']
                })
                this.partThree.push({
                    text: 'Co najbardziej lubisz w swojej pracy?',
                    value: questionnaire.jobPros
                })
                this.partThree.push({
                    text: 'Czy zawód, jaki wykonuje druga osoba jest dla Ciebie specjalnie ważny?',
                    value: questionnaire.jobImportance,
                    answers: ['Tak', 'Nie']
                })
                this.partThree.push({
                    text: 'Twój roczny dochód zawiera się w przedziale:',
                    value: questionnaire.income,
                    answers: [
                        '0-50 tysięcy zł brutto',
                        '50-100.000 zł brutto',
                        '100-200.000 zł brutto',
                        '200-500.000 zł brutto',
                        'powyżej 500.000 zł brutto',
                        'nie chcę udzielać odpowiedzi'
                    ]
                })
                this.partThree.push({
                    text: 'Wysokość zarobków drugiej osoby:',
                    value: questionnaire.incomeImportance,
                    answers: [
                        'Ma dla mnie bardzo duże znaczenie',
                        'Jest dla mnie istotna',
                        'Nie ma większego znaczenia',
                        'Nie ma w ogóle znaczenia'
                    ]
                })
                this.partThree.push({
                    text: 'Jakie jest Twoje wykształcenie:',
                    value: questionnaire.education,
                    answers: [
                        'podstawowe',
                        'gimnazjalne',
                        'zawodowe',
                        'średnie',
                        'licencjat/inżynier',
                        'magister',
                        'wyższy stopień naukowy'
                    ]
                })
                this.partThree.push({
                    text: 'Jak ważne jest dla Ciebie wykształcenie potencjalnego partnera/partnerki?',
                    value: questionnaire.educationImportance,
                    answers: [
                        'Najważniejsze',
                        'Ważne',
                        'Nie ma dla mnie większego znaczenia'
                    ]
                })
                this.partThree.push({
                    text: 'Dodatkowa uwaga dotycząca wykształcenia:',
                    value: questionnaire.educationAdditionalComment
                })
            },
            setValuesPartFourth(questionnaire) {
                this.partFourth.push({
                    text: 'Twój stan cywilny:',
                    value: questionnaire.materialStatus,
                    answers: ['panna/kawaler', 'po rozwodzie', 'wdowiec/wdowa']
                })
                this.partFourth.push({
                    text: 'Czy zależy Ci na tym, żeby w przyszłości wziąć ślub?',
                    value: questionnaire.weddingWanted,
                    answers: [
                        'zdecydowanie tak',
                        'raczej tak',
                        'raczej nie',
                        'zdecydowanie nie'
                    ]
                })
                this.partFourth.push({
                    text: 'Jestem osobą:',
                    value: [
                        questionnaire.religion,
                        questionnaire.religionPractice
                    ],
                    answers: [
                        ['Wierzącą', 'Niewierzącą'],
                        ['Praktykującą', 'Niepraktykującą']
                    ]
                })
                this.partFourth.push({
                    text: 'Zależy mi, żeby potencjalny partner/partnerka:',
                    value: [
                        questionnaire.religionMatter,
                        questionnaire.religionPracticeMatter
                    ],
                    answers: [
                        ['Była osobą wierzącą', 'Była osobą niewierzącą'],
                        [
                            'Była osobą praktykującą',
                            'Była osobą niepraktykującą'
                        ]
                    ]
                })
                this.partFourth.push({
                    text: 'Czy bierzesz pod uwagę związek z osobą po rozwodzie?',
                    value: questionnaire.divorcedMatter,
                    answers: ['Tak', 'Absolutnie nie']
                })
                this.partFourth.push({
                    text: 'Czy masz dzieci?',
                    value: questionnaire.childrenHas,
                    answers: ['Tak', 'Nie']
                })
                this.partFourth.push({
                    text: 'Czy chcesz dzieci?',
                    value: questionnaire.childrenWanted,
                    answers: ['Chcę', 'Nie chcę']
                })
                this.partFourth.push({
                    text: 'Moje poglądy w kwestiach społecznych i politycznych:',
                    value: questionnaire.politicalViews,
                    answers: [
                        'są bardzo konserwatywne',
                        'są konserwatywne',
                        'są liberalne',
                        'są bardzo liberalne'
                    ]
                })
                this.partFourth.push({
                    text: 'Czy bierzesz pod uwagę możliwość przeprowadzki do innego miasta?',
                    value: questionnaire.movePossibility,
                    answers: ['Tak', 'Nie', 'Rozważam taką możliwość']
                })
            },
            setValuesPartFifth(questionnaire) {
                this.partFifth.push({
                    text: 'Wzrost:',
                    value: questionnaire.growth
                })
                this.partFifth.push({
                    text: 'Jak ważny jest dla Ciebie wzrost osoby, z którą możesz się umówić?',
                    value: questionnaire.growthMatter,
                    answers: [
                        'Musi być niższa',
                        'Musi być wyższa',
                        'Nie ma dla mnie znaczenia'
                    ]
                })
                this.partFifth.push({
                    text: 'Dodatkowa uwaga dotycząca wzrostu:',
                    value: questionnaire.growthComment
                })
                this.partFifth.push({
                    text: 'Czego absolutnie nie jesteś w stanie zaakceptować w wyglądzie drugiej osoby?',
                    value: questionnaire.appearanceNoAcceptation
                })
                this.partFifth.push({
                    text: 'Na co zwracasz szczególną uwagę w wyglądzie drugiej osoby?',
                    value: questionnaire.specialAppearance
                })
            },
            setValuesPartSixth(questionnaire) {
                this.partSixth.push({
                    text: 'Dodatkowe informacje o Tobie, które powinnam znać:',
                    value: questionnaire.additionalInfo
                })
                this.partSixth.push({
                    text: 'Co, poza uwzględnionymi wcześniej kwestiami, ma dla Ciebie szczególne znaczenie przy doborze partnera lub partnerki:',
                    value: questionnaire.additionalPartnerInfo
                })
            },
            setValuesPartSeventh(questionnaire) {
                this.partSeventh.push({
                    text: 'Uwielbiam ',
                    value: questionnaire.completeSentences
                })
                this.partSeventh.push({
                    text: 'Nie przepadam ',
                    value: questionnaire.completeSentences
                })
                this.partSeventh.push({
                    text: 'Tracę poczucie czasu, gry ',
                    value: questionnaire.completeSentences
                })
            },
            getUser(id) {
                axios
                    .get(`${this.url}api/verify/${id}`)
                    .then(resp => {
                        this.verifications = resp.data.verifications
                        this.getTemporaryMatches()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            setCurrentPhoto(photo) {
                this.currentPhoto = photo
            },
            setAge(el) {
                const dzisiaj = new Date()
                const dataUrodzeniaDate = new Date(el)

                let wiek =
                    dzisiaj.getFullYear() - dataUrodzeniaDate.getFullYear()

                const miesiacDzisiaj = dzisiaj.getMonth()
                const dzienDzisiaj = dzisiaj.getDate()
                const miesiacUrodzenia = dataUrodzeniaDate.getMonth()
                const dzienUrodzenia = dataUrodzeniaDate.getDate()

                if (
                    miesiacDzisiaj < miesiacUrodzenia ||
                    (miesiacDzisiaj === miesiacUrodzenia &&
                        dzienDzisiaj < dzienUrodzenia)
                ) {
                    wiek--
                }

                return wiek
            },
            getMatch() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')

                // Ustawienie nagłówka Authorization
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }

                axios
                    .get(
                        `${this.url}api/match/${this.$route.params.id}`,
                        config
                    )
                    .then(resp => {
                        this.match = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getTemporaryMatch() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')

                // Ustawienie nagłówka Authorization
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }

                axios
                    .get(
                        `${this.url}api/temporarymatch/${this.$route.params.id}`,
                        config
                    )
                    .then(resp => {
                        this.getUsers()
                        this.temporarymatch = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            addTemporaryMatch(id) {
                axios
                    .post(`${this.url}api/temporarymatch`, {
                        userId: this.user._id,
                        secondUserId: id
                    })
                    .then(() => {
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
                // console.log(this.user._id, id);
            },

            // matched person format data methods
            politicalViewsFormattedMatch(item) {
                switch (item.politicalViews) {
                    case 0:
                        return 'są bardzo konserwatywne'
                    case 1:
                        return 'są konserwatywne'
                    case 2:
                        return 'są liberalne'
                    case 3:
                        return 'są bardzo liberalne'
                    default:
                        return 'nieznane'
                }
            },
            weddingWantedFormattedMatch(item) {
                switch (item.weddingWanted) {
                    case 0:
                        return 'zdecydowanie tak'
                    case 1:
                        return 'raczej tak'
                    case 2:
                        return 'raczej nie'
                    case 3:
                        return 'zdecydowanie nie'
                    default:
                        return 'nieznana'
                }
            },
            incomeImportanceFormattedMatch(item) {
                switch (item.incomeImportance) {
                    case 0:
                        return 'Ma dla mnie bardzo duże znaczenie'
                    case 1:
                        return 'Jest dla mnie istotna'
                    case 2:
                        return 'Nie ma większego znaczenia'
                    case 3:
                        return 'Nie ma w ogóle znaczenia'
                    default:
                        return 'nieznana'
                }
            },
            incomeFormattedMatch(item) {
                switch (item.income) {
                    case 0:
                        return '0-50 tysięcy zł brutto'
                    case 1:
                        return '50-100.000 zł brutto'
                    case 2:
                        return '100-200.000 zł brutto'
                    case 3:
                        return '200-500.000 zł brutto'
                    case 4:
                        return 'powyżej 500.000 zł brutto'
                    case 5:
                        return 'nie chcę udzielać odpowiedzi'
                    default:
                        return 'nieznana'
                }
            },
            petsAttitudeFormattedMatch(item) {
                switch (item.petsAttitude) {
                    case 0:
                        return 'Lubię zwierzaki'
                    case 1:
                        return 'Nie przepadam'
                    case 2:
                        return 'Zdecydowanie nie przepadam'
                    default:
                        return 'nieznana'
                }
            },
            petsHasFormattedMatch(item) {
                switch (item.petsHas) {
                    case 0:
                        return 'Tak'
                    case 1:
                        return 'Nie, ale chcę mieć'
                    case 2:
                        return 'Nie i nie chcę mieć'
                    default:
                        return 'nieznana'
                }
            },
            freeTimeFormattedMatch(item) {
                switch (item.freeTime) {
                    case 0:
                        return 'Zdecydowanie aktywnie'
                    case 1:
                        return 'Cenię balans, łączę aktywność i odpoczynek'
                    case 2:
                        return 'Relaksując się, mało aktywnie'
                    default:
                        return 'nieznana'
                }
            },
            feelingsTalkingFormattedMatch(item) {
                switch (item.feelingsTalking) {
                    case 0:
                        return 'Jest dla mnie wyzwaniem'
                    case 1:
                        return 'Przychodzi mi dość trudno'
                    case 2:
                        return 'Przychodzi mi dość łatwo'
                    case 3:
                        return 'Nie jest dla mnie wyzwaniem'
                    default:
                        return 'nieznana'
                }
            },
            lotOfImpressionsFormattedMatch(item) {
                switch (item.lotOfImpressions) {
                    case 0:
                        return 'Zdecydowanie tak'
                    case 1:
                        return 'Raczej tak'
                    case 2:
                        return 'Raczej nie'
                    case 3:
                        return 'Zdecydowanie nie'
                    default:
                        return 'nieznana'
                }
            },
            openToNewThingsFormattedMatch(item) {
                switch (item.openToNewThings) {
                    case 0:
                        return 'Tak'
                    case 1:
                        return 'Raczej tak'
                    case 2:
                        return 'Nie'
                    default:
                        return 'nieznana'
                }
            },
            openToNewPeopleFormattedMatch(item) {
                switch (item.openToNewPeople) {
                    case 0:
                        return 'Przychodzi mi łatwo'
                    case 1:
                        return 'Nie przychodzi mi łatwo'
                    default:
                        return 'nieznana'
                }
            },
            cityPossibleFormattedMatch(item) {
                switch (item.cityPossible) {
                    case '0':
                        return 'Poznań'
                    case '1':
                        return 'Warszawa'
                    case 0:
                        return 'Poznań'
                    case 1:
                        return 'Warszawa'
                    default:
                        return 'nieznana'
                }
            },
            growthMatterFormattedMatch(item) {
                switch (item.growthMatter) {
                    case 0:
                        return 'Musi być niższa'
                    case 1:
                        return 'Musi być wyższa'
                    case 2:
                        return 'Nie ma dla mnie znaczenia'
                    default:
                        return 'nieznana'
                }
            },
            addPremiumOption() {
                axios
                    .put(
                        `${this.url}api/verify/addpremium/${this.$route.params.id}`
                    )
                    .then(() => {
                        location.reload()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            handleKeydown(e) {
                if (!this.lightboxVisible) return
                if (e.key === 'ArrowLeft') {
                    this.navigatePhoto(-1)
                } else if (e.key === 'ArrowRight') {
                    this.navigatePhoto(1)
                }
            },
            handleKeydownExit(e) {
                if (!this.lightboxVisible) return
                if (e.key == 'Escape') {
                    this.lightboxVisible = false
                }
            }
        },
        created() {
            // this.getUsers();
            this.getUser(this.$route.params.id)
            this.getTemporaryMatch()
            this.getMatch()
            this.trimUserFlag()
            this.getMatchedQuestionnaires()
        },
        watch: {
            '$route.params.id': function (newId, oldId) {
                this.getTemporaryMatch()
            }
        },
        computed: {
            currentPhoto() {
                return this.userPhotos[this.currentPhotoIndex]
            },
            politicalViewsFormatted() {
                switch (this.questionnaire.sex) {
                    case 0:
                        return 'są bardzo konserwatywne'
                    case 1:
                        return 'są konserwatywne'
                    case 2:
                        return 'są liberalne'
                    case 3:
                        return 'są bardzo liberalne'
                    default:
                        return 'nieznane'
                }
            },
            matchMediaweddingWantedFormatted() {
                switch (this.questionnaire.weddingWanted) {
                    case 0:
                        return 'zdecydowanie tak'
                    case 1:
                        return 'raczej tak'
                    case 2:
                        return 'raczej nie'
                    case 3:
                        return 'zdecydowanie nie'
                    default:
                        return 'nieznana'
                }
            },
            incomeImportanceFormatted() {
                switch (this.questionnaire.incomeImportance) {
                    case 0:
                        return 'Ma dla mnie bardzo duże znaczenie'
                    case 1:
                        return 'Jest dla mnie istotna'
                    case 2:
                        return 'Nie ma większego znaczenia'
                    case 3:
                        return 'Nie ma w ogóle znaczenia'
                    default:
                        return 'nieznana'
                }
            },
            incomeFormatted() {
                switch (this.questionnaire.income) {
                    case 0:
                        return '0-50 tysięcy zł brutto'
                    case 1:
                        return '50-100.000 zł brutto'
                    case 2:
                        return '100-200.000 zł brutto'
                    case 3:
                        return '200-500.000 zł brutto'
                    case 4:
                        return 'powyżej 500.000 zł brutto'
                    case 5:
                        return 'nie chcę udzielać odpowiedzi'
                    default:
                        return 'nieznana'
                }
            },
            petsAttitudeFormatted() {
                switch (this.questionnaire.petsAttitude) {
                    case 0:
                        return 'Lubię zwierzaki'
                    case 1:
                        return 'Nie przepadam'
                    case 2:
                        return 'Zdecydowanie nie przepadam'
                    default:
                        return 'nieznana'
                }
            },
            petsHasFormatted() {
                switch (this.questionnaire.petsHas) {
                    case 0:
                        return 'Tak'
                    case 1:
                        return 'Nie, ale chcę mieć'
                    case 2:
                        return 'Nie i nie chcę mieć'
                    default:
                        return 'nieznana'
                }
            },
            freeTimeFormatted() {
                switch (this.questionnaire.freeTime) {
                    case 0:
                        return 'Zdecydowanie aktywnie'
                    case 1:
                        return 'Cenię balans, łączę aktywność i odpoczynek'
                    case 2:
                        return 'Relaksując się, mało aktywnie'
                    default:
                        return 'nieznana'
                }
            },
            feelingsTalkingFormatted() {
                switch (this.questionnaire.feelingsTalking) {
                    case 0:
                        return 'Jest dla mnie wyzwaniem'
                    case 1:
                        return 'Przychodzi mi dość trudno'
                    case 2:
                        return 'Przychodzi mi dość łatwo'
                    case 3:
                        return 'Nie jest dla mnie wyzwaniem'
                    default:
                        return 'nieznana'
                }
            },
            lotOfImpressionsFormatted() {
                switch (this.questionnaire.lotOfImpressions) {
                    case 0:
                        return 'Zdecydowanie tak'
                    case 1:
                        return 'Raczej tak'
                    case 2:
                        return 'Raczej nie'
                    case 3:
                        return 'Zdecydowanie nie'
                    default:
                        return 'nieznana'
                }
            },
            openToNewThingsFormatted() {
                switch (this.questionnaire.openToNewThings) {
                    case 0:
                        return 'Tak'
                    case 1:
                        return 'Raczej tak'
                    case 2:
                        return 'Nie'
                    default:
                        return 'nieznana'
                }
            },
            openToNewPeopleFormatted() {
                switch (this.questionnaire.openToNewPeople) {
                    case 0:
                        return 'Przychodzi mi łatwo'
                    case 1:
                        return 'Nie przychodzi mi łatwo'
                    default:
                        return 'nieznana'
                }
            },
            cityPossibleFormatted() {
                switch (this.questionnaire.cityPossible) {
                    case '0':
                        return 'Poznań'
                    case '1':
                        return 'Warszawa'
                    case 0:
                        return 'Poznań'
                    case 1:
                        return 'Warszawa'
                    default:
                        return 'nieznana'
                }
            },
            growthMatterFormatted() {
                switch (this.questionnaire.growthMatter) {
                    case 0:
                        return 'Musi być niższa'
                    case 1:
                        return 'Musi być wyższa'
                    case 2:
                        return 'Nie ma dla mnie znaczenia'
                    default:
                        return 'nieznana'
                }
            },
            hasUserCurrentlyTemporaryMatch() {
                return this.user.hasAlreadyTemporaryMatch === undefined ||
                    this.user.hasAlreadyTemporaryMatch === false
                    ? false
                    : true
            },
            hasUserCurrentlyMatch() {
                return this.user.hasAlreadyMatch === undefined ||
                    this.user.hasAlreadyMatch === false
                    ? false
                    : true
            },
            formatDate(dateString) {
                const date = new Date(dateString)

                const year = date.getFullYear()
                const month = date.toLocaleString('default', { month: 'long' }) // e.g., "May"
                const day = date.getDate()

                const hours = date.getHours()
                const minutes = String(date.getMinutes()).padStart(2, '0') // Ensure two-digit format
                const seconds = String(date.getSeconds()).padStart(2, '0') // Ensure two-digit format

                return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`
            }
            //matched
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/Nunito.css';
    @import '@vueform/multiselect/themes/default.css';
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .seventh {
        .admin-to-verify__user-parts-second-text {
            padding-top: 21px;
            margin-top: 3px;
        }
        .admin-to-verify__user-parts-line {
            margin-bottom: 0;
        }
        .admin-to-verify__user-parts-second-item-line {
            display: block !important;
        }
    }
    .send-questionnaire-button {
        padding-bottom: 184px;
    }
    .send-questionnaire {
        display: block;
        outline: none;
        border: 1px solid $black;
        margin: 0 auto;
        width: 200px;
        text-align: center;
        padding: 15px 0;
        background-color: #e1dbd5;
        font-size: 1.6rem;
        line-height: 3rem;
        color: $black;
        font-weight: bold;
        cursor: pointer;
    }
    .admin-to-verify {
        &__scale-modal {
            width: 100%;
            max-width: 320px;
            height: 100vh;
            overflow-y: scroll;
            background-color: #fff;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999999;
            padding: 20px;
            fieldset {
                padding: 20px;
                margin-top: 20px;
                &.red {
                    border-color: #d13724;
                }
            }
        }
        &__scale-grid {
            display: grid;
            gap: 40px;
            grid-template-columns: 1fr;
        }
        &__scale-flex {
            display: flex;
            input[type='radio'] {
                width: 25px;
                height: 25px;
                margin-right: 5px;
            }
        }
        &__scale-center {
            display: flex;
            align-items: center;
            margin-top: 4px;
        }
        &__loader-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__wrapper {
            display: flex;
            column-gap: 20px;
            @include breakpoint-max('m-large') {
                flex-direction: column;
                row-gap: 50px;
            }
        }
        &__message {
            list-style-type: none;
            background-color: $gray;
            padding: 10px;
            border-radius: 8px;
        }
        &__overlay {
            width: 100%;
            height: 100vh;
            background-color: #111111;
            opacity: 70%;
            position: fixed;
            top: 0;
            left: 0;
        }
        &__popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 500px;
            width: 100%;
            padding: 20px;
            background-color: $gray;
            border-radius: 15px;
            &-wrapper {
                display: flex;
                flex-direction: column;
                textarea {
                    resize: none;
                    padding: 20px;
                    border: 1px solid darken($gray, 10%);
                    border-radius: 10px;
                    font-size: 1.6rem;
                }
                .admin-to-verify__btn {
                    margin-top: 8px;
                }
            }
        }
        &__check {
            border: 3px dashed #f2545b;
            border-radius: 15px;
            margin: 20px 0;
            margin-top: 0;
            padding: 20px;
            h3 {
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 10px;
                font-size: 1.4rem;
                line-height: 1.6rem;
                color: rgba(17, 17, 17, 0.5);
                font-weight: 600;
                padding: 15px 20px 0px 0;
                border-radius: 10px;
            }
        }
        &__verification {
            color: #f2545b;
            background: lighten(#f2545b, 10%);
            padding: 15px 20px !important;
            margin-top: 10px;
        }

        &__columns {
            display: flex;
            margin-left: 90px;
        }
        &__photos {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
        &__left,
        &__right {
            flex: 1;
            figure {
                width: fit-content;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    border-radius: 10px;
                }
            }
            h3 {
                font-size: 1.8rem;
                line-height: 2.5rem;
                color: $black1;
                font-weight: 700;
            }
        }
        &__left {
            @include breakpoint-max('xs-tablet') {
                padding: 0 30px;
            }
        }
        &__left-top {
            display: flex;
            margin-bottom: 40px;
            a {
                flex: 1;
            }
            @include breakpoint-max('s-tablet') {
                flex-direction: column;
                row-gap: 20px;
            }
        }
        &__left-top-l {
            flex: 1;
        }
        &__left-prev {
            display: block;
            width: fit-content;
            padding: 17px 25px;
            padding-top: 20px;
            background-color: $pink;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            font-size: 1.6rem;
            line-height: 3rem;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
        }
        &__gallery-display figure,
        &__gallery-thumbnails figure {
            margin: 10px;
            cursor: pointer;
        }
        &__gallery-thumbnails img {
            height: auto;
        }
        &__user {
            min-width: 100%;
            margin-bottom: 50px;
            background-color: $gray;
            border-radius: 15px;
            flex: 1;
        }
        &__right-user {
            flex: 1;
            @include breakpoint-max('v-large') {
                flex: 0.52;
            }
        }
        &__user-top,
        &__users-top {
            display: flex;
            margin-bottom: 35px;
        }
        &__user-top-person {
            margin-top: 30px;
        }
        .no-image {
            margin-top: 30px;
        }
        &__user-top-person-name {
            padding: 8px 36px 8px 59px;
            background-color: $pink;
            color: $white;
            font-size: 3rem;
            line-height: 4.5rem;
            font-weight: 700;
            text-transform: capitalize;
            font-family: 'Open Sans', sans-serif;
            box-shadow: 0px 3px 6px #00000029;
            margin-bottom: 41px;
            @include breakpoint-max('xs-tablet') {
                padding: 6px 20px;
            }
            @include breakpoint-max('mobile') {
                padding: 4px 10px;
                font-size: 2rem;
                margin-bottom: 10px;
            }
        }
        &__user-top-person-data {
            padding-left: 51px;
            div {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 26px;
                    @include breakpoint-max('mobile') {
                        margin-right: 15px;
                        max-width: 15px;
                    }
                }
                p {
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black;
                    font-weight: 700;
                    text-transform: capitalize;
                    font-family: 'Open Sans', sans-serif;
                }
                @include breakpoint-max('mobile') {
                    margin-bottom: 0px;
                }
            }
            @include breakpoint-max('xs-tablet') {
                padding-left: 20px;
            }
            @include breakpoint-max('mobile') {
                padding-left: 10px;
            }
        }
        &__user-top-person-data-height {
            p {
                text-transform: lowercase !important;
            }
        }
        &__user-parts-first {
            max-width: 1200px;
            margin-bottom: 44px;
            p {
                font-size: 1.6rem;
                line-height: 2rem;
                color: $black;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                margin-bottom: 17px;
                span {
                    font-weight: 400;
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }
        &__user-parts-first-social {
            display: block;
            margin-bottom: 7px;
            color: $black;
            text-decoration: none;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.6rem;
            line-height: 3rem;
            font-weight: 400;
            transition: 0.3s;
            width: fit-content;
            &:hover {
                color: $pink;
            }
        }
        &__user-parts-second {
            max-width: 1200px;
            margin-bottom: 43px;
        }
        &__user-parts-first-title,
        &__user-parts-second-title {
            font-size: 3.5rem;
            line-height: 5rem;
            color: $black;
            padding-bottom: 17px;
            width: 100%;
            max-width: 506px;
            font-family: 'Promenade';
            font-weight: 400;
        }
        &__user-parts-line {
            margin-bottom: 17px;
            height: 3px;
            background-color: $black;
            width: 100%;
            @include breakpoint-max('xl-tablet') {
                width: 65%;
            }
        }
        &__user-parts {
            margin-top: 35px;
            width: 100%;
            max-width: 505px;
            padding-right: 30px;
            @include breakpoint-max('xs-tablet') {
                padding-right: 0px;
            }
        }
        &__user-parts-second-title {
            margin-bottom: 3px;
        }
        &__user-parts-second-item {
            max-width: 1200px;
            width: 100%;
            &:last-child {
                .admin-to-verify__user-parts-second-item-line {
                    display: none;
                }
            }
        }
        &__user-parts-second-item-box {
            padding-top: 3px;
            display: flex;
            align-items: flex-start;
            column-gap: 41px;
            @include breakpoint-max('m-large') {
                column-gap: 15px;
            }
            @include breakpoint-max('xl-tablet') {
                flex-direction: column;
            }
        }
        &__user-parts-second-item-box-left {
            flex: 68;
            display: flex;
            column-gap: 41px;
            @include breakpoint-max('m-large') {
                column-gap: 15px;
            }
            @include breakpoint-max('xl-tablet') {
                width: 100%;
            }
        }
        &__user-parts-second-item-line {
            margin-bottom: 17px;
            width: 100%;
            height: 1px;
            background-color: $black;
            @include breakpoint-max('xl-tablet') {
                margin-top: 20px;
                width: 65%;
            }
        }
        &__user-parts-second-text {
            flex: 50;
            padding-bottom: 21px;
            margin-bottom: 3px;
            h3 {
                margin-bottom: 3px;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                color: $black;
            }
            p {
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                color: $black;
            }
            em {
                font-style: normal;
                color: $pink;
                font-weight: 700;
            }
            @include breakpoint-max('large') {
                flex: 35;
            }
            @include breakpoint-max('xl-tablet') {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
        &__user-parts-second-fix {
            flex: 13;
            display: flex;
            justify-content: center;
            column-gap: 22px;
            label {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                align-items: center;
            }
            span {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $black;
                font-weight: 400;
            }
            // Input type radio
            .content {
                position: relative;
                display: grid;
                place-items: center;
                width: 27px;
                height: 27px;
            }
            .checkBox {
                display: block;
                cursor: pointer;
                width: 27px;
                height: 27px;
                border: 4px solid transparent;
                border-radius: 50%;
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 0px 1.5px $black;
            }

            .checkBox div {
                width: 60px;
                height: 60px;
                background-color: $pink;
                top: -52px;
                left: -52px;
                position: absolute;
                transform: rotateZ(45deg);
                z-index: 100;
            }
            .correct {
                .checkBox div {
                    background-color: $black;
                }
            }

            .checkBox input[type='radio']:checked + div {
                left: -10px;
                top: -10px;
            }

            .checkBox input[type='radio'] {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                visibility: hidden;
            }

            .transition {
                transition: 300ms ease;
            }
            //
            @include breakpoint-max('m-large') {
                flex: 15;
            }
            @include breakpoint-max('mobile') {
                column-gap: 10px;
            }
        }
        &__user-parts-second-fix-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 17px;
        }
        .to-fix {
            min-width: 42px;
        }
        &__user-parts-second-input {
            flex: 47;
            div {
                display: flex;
                flex-direction: column;
                row-gap: 17px;
                @include breakpoint-max('xl-tablet') {
                    margin-top: 10px;
                }
            }
            span {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $black;
                font-weight: 400;
            }
            input {
                border: 1px solid #2a2a29;
                background: transparent;
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
                color: $black;
                outline: none;
                padding: 4px 13px;
            }
            @include breakpoint-max('m-large') {
                flex: 35;
            }
            @include breakpoint-max('xl-tablet') {
                width: 100%;
            }
        }
        &__users {
            margin-bottom: 50px;
            background-color: $gray;
            padding: 31px 35px;
            border-radius: 15px;
            max-width: 650px;
        }
        &__user-info,
        &__users-info {
            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $black1;
                font-weight: 600;
                margin-bottom: 10px;
            }
            span {
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: rgba(17, 17, 17, 0.5);
                font-weight: 600;
                text-align: right;
                margin-left: 10px;
            }
        }
        &__user-mail,
        &__user-phone {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            p {
                margin-bottom: 0;
                margin-left: 13px;
            }
        }
        &__btn {
            padding: 14px 21px;
            cursor: pointer;
            border-radius: 10px;
            color: $white;
            background-color: #0fb70d;
            &--margin {
                margin-left: 8px;
            }
            &--red {
                background-color: #f2545b;
            }
        }

        &__matches-not-allowed {
            font-size: 2rem;
            background: $pink;
            display: inline-block;
            padding: 6px 12px;
            color: #fff;
        }

        .admin-users-view {
            &__header {
                max-width: 1597px;
                height: 104px;
                background-color: $white;
                padding-left: 65px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &__header-logo {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 14px;
                }
                p {
                    font-family: 'NunitoExtraBold';
                    font-weight: 800;
                    font-size: 2rem;
                    line-height: 3.7rem;
                    color: $black;
                }
            }
            &__header-notification {
                cursor: pointer;
                width: 53px;
                height: 53px;
                background: $gray;
                border-radius: 15px;
                display: grid;
                place-items: center;
                position: relative;
                transition: 0.3s;
                &:hover {
                    svg {
                        animation: myAnim 2s ease 0s 1 normal forwards;
                    }
                }
            }
            &__header-notification-circle {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 12px;
                height: 12px;
                background-color: #f2545b;
                border-radius: 50%;
            }
            &__wrapper {
                display: flex;
                padding-bottom: 86px;
                padding-right: 30px;
            }
            &__sidebar {
                max-width: 303px;
                width: 100%;
                height: calc(100vh - 104px);
                background: $gray;
                border-radius: 0 15px 15px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding-top: 54px;
                padding-bottom: 150px;
            }
            &__sidebar-pages {
                position: relative;
                display: flex;
                flex-direction: column;
                list-style: none;
                li {
                    margin-bottom: 13px;
                    a {
                        text-decoration: none;
                        font-family: 'NunitoMedium';
                        font-weight: 500;
                        font-size: 1.8rem;
                        line-height: 2.5rem;
                        color: $black1;
                        height: 38px;
                        display: flex;
                        align-items: center;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    svg {
                        margin-right: 13px;
                    }
                }
            }
            &__sidebar-nav {
                position: absolute;
                left: -19px;
                top: 0px;
                width: 4px;
                height: 38px;
                background-color: #f2545b;
                box-shadow: -3px 3px 6px rgba(242, 84, 91, 0.35);
                border-radius: 500px;
                transition: 0.3s;
            }
            &__sidebar-logout {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &__sidebar-line {
                margin-bottom: 34px !important;
                max-width: 239px;
                margin: 0 auto;
                width: 100%;
                height: 1px;
                background-color: $bege;
            }
            &__sidebar-logout-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;
                p {
                    font-family: 'NunitoMedium';
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2.5rem;
                    color: $black1;
                }
                &:hover {
                    .arrow {
                        left: 12px;
                    }
                }
            }
            &__sidebar-logout-svg {
                position: relative;
                width: 17px;
                height: 19px;
                margin-right: 15px;
                .left {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
                .arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 6px;
                    transition: 0.3s;
                }
            }
            &__content {
                width: 100%;
                height: calc(100vh - 104px);
                // background-image: url(http://localhost:8080/img/light-bcg-big.93d5f768.png);
                input {
                    padding-left: 93px;
                    width: 270px;
                    padding: 15px;
                }
            }
            &__content-users {
                padding-top: 49px;
                padding-left: 93px;
                padding-top: 13px;
                max-width: 1294px;
                width: 100%;
            }
            &__content-input {
                padding-left: 93px;
                input {
                    background: $gray;
                    border-radius: 10px;
                    outline: none;
                    border: none;
                    font-family: 'NunitoBold';
                    font-weight: 700;
                    font-size: 1.6rem;
                    color: #111111;
                }
            }
            &__content-input-wrapper {
                position: relative;
                width: fit-content;
                svg {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 24px;
                }
            }

            @keyframes myAnim {
                0%,
                100% {
                    transform: translateX(0%);
                    transform-origin: 50% 50%;
                }

                15% {
                    transform: translateX(-5px) rotate(12deg);
                }

                30% {
                    transform: translateX(5px) rotate(-12deg);
                }

                45% {
                    transform: translateX(0px) rotate(3.6deg);
                }

                60% {
                    transform: translateX(0px) rotate(-2.4deg);
                }

                75% {
                    transform: translateX(0px) rotate(1.2deg);
                }
            }
        }
        &__right-user {
        }
        &__right-user-heading {
            flex: 1;
            display: flex;
            align-items: center;
            column-gap: 63px;
            h2 {
                font-size: 3.5rem;
                line-height: 5rem;
                color: $black;
                font-weight: 400;
                font-family: 'Promenade';
            }
            @include breakpoint-max('mobile') {
                flex-direction: column;
                row-gap: 20px;
                align-items: flex-start;
            }
        }
        &__right-user-heading-boxes {
            display: flex;
            column-gap: 13px;
            a {
                text-decoration: none;
                figure {
                    display: block;
                    margin-bottom: 5px;
                    img {
                        display: block;
                        width: 59px;
                        height: 59px;
                        object-fit: cover;
                    }
                }
                p {
                    font-size: 0.8rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                    width: 100%;
                    text-align: center;
                    font-family: 'Open Sans', sans-serif;
                }
            }
        }
        &__right-user-heading-boxes-first {
            width: 59px;
            height: 59px;
            border-radius: 10px;
            background-color: $pink;
        }
    }
    .gallery {
        &__thumbnails {
            figure {
                display: block;
                img {
                    cursor: pointer;
                    display: block;
                    height: auto;
                    width: 280px;
                    height: 280px;
                    object-fit: cover;
                    aspect-ratio: 1;
                    object-position: center;
                    border-radius: 0 !important;
                    border: 1px solid $black;
                    @include breakpoint-max('xs-tablet') {
                        width: 240px;
                        height: 240px;
                    }
                    @include breakpoint-max('mobile') {
                        width: 160px;
                        height: 160px;
                    }
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $black;
                }
            }
        }
    }
    .lightbox {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            max-width: 80vw; /* 80% szerokości widoku */
            max-height: 80vh; /* 80% wysokości widoku */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            max-width: 95vw;
            max-height: 95vh;
            object-fit: contain !important;
        }
    }
    .lightbox__controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .lightbox__nav {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }
    .lightbox__nav--left {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }
    .lightbox__nav--right {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }
    .lightbox__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
    }

    .btn__default {
        display: block;
        width: -moz-fit-content;
        width: fit-content;
        padding: 17px 25px;
        padding-top: 20px;
        background-color: #d13724;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
        border-radius: 10px;
        font-size: 1.6rem;
        line-height: 3rem;
        color: #ffffff;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
            opacity: 0.9;
        }
    }
</style>
