<template>
    <section class="faq">
        <div class="scroll-div" id="faq"></div>
        <div class="container-landing">
            <h2 class="faq__title">FAQ</h2>
            <div class="faq__columns">
                <div class="faq__columns-left">
                    <h3 class="faq__columns-left-title">REJESTRACJA</h3>
                    <ul class="faq__columns-left-list">
                        <SingleFaq
                            v-for="(question, index) in firstColumnQuestions"
                            :key="index"
                            :question="question"
                            :inIndex="index"
                            :showMore="showMore"
                        />
                    </ul>
                </div>
                <div class="faq__columns-right">
                    <h3 class="faq__columns-right-title">FORMULARZ</h3>
                    <ul class="faq__columns-right-list">
                        <SingleFaq
                            v-for="(question, index) in secondColumnQuestions"
                            :key="'second_' + index"
                            :question="question"
                            :inIndex="index"
                            :showMore="showMore"
                        />
                    </ul>
                </div>
            </div>
            <div class="faq__more">
                <router-link @click="opened = false" to="/pytania"><p>więcej</p></router-link>
            </div>
        </div>
    </section>
</template>
<script>
import SingleFaq from './atoms/SingleFaq.vue';
import SimpleButton from '@/components/landing-03/atoms/SimpleButton.vue';

    export default {
        data() {
            return {
                firstColumnQuestions: [
                    {title: 'Dlaczego muszę się zapisać na listę?', text: 'To pierwszy etap, przez który musisz przejść, żeby zapisać się do biura. Bez niego nie otrzymasz informacji, że możesz wypełnić formularz i rozpocząć swój proces poszukiwania dopasowanych osób.'},
                    {title: 'Czy muszę dodawać zdjęcia?', text: 'Tak. Wypełniając formularz na kolejnym etapie możesz zdecydować się na dodanie innych zdjęć. Zdjęcia pozwalają nam potwierdzać, że to wciąż ta sama osoba. Prosimy o dołączenie zdjęcia portretowego i całej sylwetki. Nie zostaną one nikomu udostępnione.'},
                ],
                secondColumnQuestions: [
                    {title: 'Jak długo będę na liście oczekujących?', text: 'Jest bardzo wiele chętnych osób. Ze względu na ogromne zainteresowanie, nie jesteśmy w stanie podać dokładnego czasu.'},
                    {title: 'Czy biuro zacznie działalność od razu we wszystkich miastach?', text: 'Nie. O kolejności, w której będziemy rozpoczynać działalność zadecyduje liczba osób zapisanych w danych miastach.'},
                ]
            }
        },
        components: {
            SingleFaq,
            SimpleButton
        },
    }
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/global.scss";


    .faq {
        background-color: $bege3;
        padding-bottom: 84px;
        border-bottom: 1px solid $black;
        &__title {
            font-family: 'Oswald', sans-serif;
            font-size: 30rem;
            line-height: 45.5rem;
            color: $begeLight3;
            text-transform: uppercase;
            position: relative;
            margin-bottom: 53px;
            &::after {
                content: '';
                position: absolute;
                bottom: 25px;
                left: 0px;
                width: 100%;
                height: 1px;
                background-color: $black;
                @include breakpoint-max("mobile") {
                    content: none;
                }
            }
            @include breakpoint-max("xs-tablet") {
                margin-bottom: 15px;
            }
            @include breakpoint-max("mobile") {
                font-size: 48px;
                line-height: 50px;
                padding: 60px 0px;
                margin-bottom: 0;
            }
        }
        &__columns {
            display: flex;
            column-gap: 93px;
            @include breakpoint-max('s-tablet') {
                column-gap: 30px;
            }
            @include breakpoint-max("mobile") {
                flex-direction: column;
            }
        }
        &__columns-left,
        &__columns-right {
            flex: 1;
        }
        &__columns-left-title,
        &__columns-right-title {
            font-family: 'Helvetica', sans-serif;
            font-size: 3.2rem;
            line-height: 3.9rem;
            color: $black;
            margin-bottom: 62px;
            @include breakpoint-max("xs-tablet") {
                margin-bottom: 30px;
            }
            @include breakpoint-max("mobile") {
                display: none;
            }
        }
        &__columns-right-title {
            opacity: 0;
        }
        &__columns-left-list,
        &__columns-right-list {
            list-style: none;
            li {
                border-top: 1px solid $black;
                padding: 20px 0 26px;
            }
        }
        &__more {
            padding-top: 9px;
            a {
                text-decoration: none;
                display: block;
                width: fit-content;
            }
            p {
                padding: 11px 31px;
                border: 1px solid $black;
                font-family: 'Open Sans', sans-serif;
                font-size: 2.4rem;
                line-height: 3rem;
                font-weight: 700;
                color: $black;
                text-transform: uppercase;
                width: fit-content;
                cursor: pointer;

            }
        }
        &__columns-content-text {
            p {
                font-size: 14px;
                line-height: 20px;

            }
        }
    }

</style>